<template>
  <div>
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <p class="modal_block__title">Change dates range</p>
    <div class="modal_block__form">
        <div class="modal_block__error_message">
          <p v-if="isMoreThanYear">Please select the period NOT more than a year</p>
          <p v-if="isEarlierThan2021">Please select the date NOT earlier than 2021</p>
          <p v-if="isLaterThanToday">Please select the date NOT latest than Today</p>
        </div>
      <DateTimeInput :value="dates" @input="changeValue" />
    </div>

    <Button
      id="modal_edit__btn"
      title="Confirm"
      size="tight"
      color="primaryFull"
      class="modal_edit__btn"
      :disabled="isMoreThanYear || isEarlierThan2021 || isLaterThanToday"
      @click="changeDates"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import { startOfTomorrow, startOfYesterday } from 'date-fns';
import DateTimeInput from '@/components/DateTimeInput/DateTimeInput';

let today = startOfTomorrow();
let yesterday = startOfYesterday();

const toLocalISOString = (date) => {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toISOString();
};

export default {
  name: 'Modal',

  components: {
    DateTimeInput,
    Button: Button,
  },

  props: {
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      frDate: toLocalISOString(yesterday),
      toDate: toLocalISOString(today),
      isMoreThanYear: false,
      isEarlierThan2021: false,
      isLaterThanToday: false,
      dates: [yesterday, today],
    };
  },

  methods: {
    changeValue(e) {
      const secInYear = 1000 * 60 * 60 * 24 * 365;
      const earlier = new Date(2021, 0, 1);
      var currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + 1)

      if (e && e instanceof Array) {
        this.frDate = toLocalISOString(e[0]);
        this.toDate = toLocalISOString(e[1]);
        this.isMoreThanYear = ((e[1]-e[0])/secInYear) > 1;
        this.isEarlierThan2021 = new Date(e[0])<earlier;
        this.isLaterThanToday = new Date(e[1]) > currentDate;
      } else {
        this.frDate = null;
        this.toDate = null;
      }
    },
    changeDates() {
      this.click([this.frDate, this.toDate]);
    },

  },
};
</script>

<style src="./modalDates.scss" lang="scss" scoped></style>
