<template>
  <div
    v-infinite-scroll="getCryptoMorePayments"
    infinite-scroll-disabled="$loading"
    infinite-scroll-distance="20"
    infinite-scroll-throttle-delay="600"
    class="results__wrapper"
  >
    <modals-container />
    <p class="count-p">Payments count: {{cryptoPaymentsCount || 0}}</p>

    <div
      v-if="$loading"
      class="payments__results"
    >
      <Spinner class="payments__spinner" />
    </div>

    <div
      v-else
      class="payments__results"
    >
      <div class="row__label">
        <span
          v-for="key in filters"
          :id="key"
          :key="key"
          :class="[key.toLowerCase()]"
          class="results__column"
        >{{ key }}</span>
      </div>
      <span
        v-if="!cryptoPayments.length"
        class="payments__noresults"
      >No payments</span>

      <div v-else>
        <BulmaAccordion
          dropdown
          :icon="'custom'"
          :slide="{
            duration: '.4s',
            timerFunc: 'ease'
          }"
        >
          <div
            v-for="payment in cryptoPayments"
            :key="payment.id"
          >
            <BulmaAccordionItem>
              <div
                :id="payment.id"
                slot="title"
                class="row__wrapper"
                :class="{
                  grey: payment.stsId > 90}"
              >
                <span class="results__column id value">{{ payment.id }}</span>
                <span class="results__column time">{{ payment.createdAt | formatTime }}</span>
<!--                <span class="results__column type">{{ payment.typeName }}-->
<!--                  <p v-if="!!payment.frPrvId && !! payment.toPrvId">({{payment.frPrvId}} - {{payment.toPrvId}})</p></span>-->
                <span class="results__column desc">{{ payment.description }}</span>
                <span class="results__column value from-amount">{{ payment.frAmtReal }} {{ payment.frAsset.code }}</span>
<!--                <span class="results__column value from-balance">-->
<!--                  {{ payment.frBalanceBefore }} {{ payment.frAsset.code }}-->
<!--                  <span class="arrow">↓</span>-->
<!--                  {{ payment.frBalanceAfter }} {{ payment.frAsset.code }}-->
<!--                </span>-->
                <span class="results__column value to-amount">{{ payment.toAmtReal }} {{ payment.toAsset.code }}</span>
<!--                <span class="results__column value to-balance">-->
<!--                  {{ payment.toBalanceBefore }} {{ payment.toAsset.code }}-->
<!--                  <span class="arrow">↓</span>-->
<!--                  {{ payment.toBalanceAfter }} {{ payment.toAsset.code }}-->
<!--                </span>-->
                <span
                  class="results__column status value"
                  style="white-space: nowrap"
                  :class="{
                  green: payment.stsId === 90,
                  yellow: payment.stsId < 90}"
                >{{(payment.stsName) ? payment.stsName : "" }}  <br/> ({{payment.stsId}})</span>
              </div>
              <div
                slot="content"
                class="showDet"
              >
                <div class="generalDet">
                  <div class="detBlock detBlock__first">
                    <p>
                      <span class="detBlock__label">Created At: </span>
                      <span>{{payment.createdAt | formatTime}}</span>
                    </p>
                    <p>
                      <span class="detBlock__label">Completed At: </span>
                      <span>{{payment.completedAt | formatTime}}</span>
                    </p>
                  </div>
                  <div class="detBlock">
                    <p v-if="payment.error">
                      <span class="detBlock__label">Error message: </span>
                      <span>{{ payment.error }}</span>
                    </p>
                    <p v-if="payment.cbPayId">
                      <span class="detBlock__label">CB Pay Id: </span>
                      <span>{{ payment.cbPayId }}</span>
                    </p>
                  </div>
                </div>
                <div class="detBlock__btns">
                  <Button
                    class="btn_right"
                    title="More details"
                    color="orange"
                    size="tight"
                    @click="show(payment)"
                  />
                </div>
              </div>
            </BulmaAccordionItem>
          </div>
        </BulmaAccordion>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import { BulmaAccordion, BulmaAccordionItem } from 'vue-bulma-accordion';
import JsonDetailsModal from '@/components/JsonDetailsModal/JsonDetailsModal';
import Button from '@/components/Button/Button';

const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'SearchResultsCryptoPayments',

  components: {
    Button,
    Spinner,
    BulmaAccordion,
    BulmaAccordionItem,
  },

  data() {
    return {
      showDetails: [],
      filters: [
        'ID',
        'Time',
        // 'Type',
        'Desc',
        'From-Amount',
        // 'From-Balance',
        'To-Amount',
        // 'To-Balance',
        'Status',
      ],
    };
  },

  computed: {
    ...mapGetters([
      'user',
      'cryptoPayments',
      'sortedDirection',
      'cryptoPaymentsCount'
    ]),
  },

  methods: {
    ...mapActions([
      'getUser',
      'getCryptoPayments',
      'getCryptoMorePayments',
    ]),

    show(payment) {
      this.$modal.show(
        JsonDetailsModal,
        {
          payment: payment,
          isCrypto: true
        },
        { scrollable: true, width: '700', height: 'auto' },
      );
    },
  },
};
</script>

<style src="./searchResultsCryptoPayments.scss" lang="scss" scoped></style>
