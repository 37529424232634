<template>
  <div>
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <div class="modal_block__header">
      <p class="modal_block__title">
        {{ title }}
      </p>
      <p v-if="subtitle" class="modal_block__subtitle">
        {{ subtitle }}
      </p>
    </div>
    <div v-if="showReason()" class="modal_block__form">
      <span class="modal_block__label">Reason for {{ titleBtn.toLowerCase() }}</span>
      <textarea v-model="message" class="modal_block__input" autofocus />
    </div>
    <div v-if="flagTitle" class="modal_block__row">
      <input id="checkbox" v-model="flag" class="modal_block__checkbox" type="checkbox" />
      <span class="modal_block__label2">{{ flagTitle }}</span>
    </div>
    <Button
      :title="titleBtn"
      :disabled="showReason() && (!message || message.length < 5)"
      size="tight"
      color="orangeFull"
      class="modal_block__btn"
      @click="flagTitle ? click(message, flag) : click(message)"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';

export default {
  name: 'Modal',

  components: { Button },

  props: {
    title: {
      type: String,
      required: true,
    },
    titleBtn: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    subtitle: {
      type: String,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    checkbox: {
      type: String,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    flagTitle: {
      type: String,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    withReason: {
      type: Boolean,
      required: false,
      default: true,
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      message: '',
      flag: false,
    };
  },

  methods: {
    showReason() {
      return this.withReason === true;
    },
  },
};
</script>

<style src="./modalReason.scss" lang="scss" scoped></style>
