<template>
  <div class="search">
    <input
      v-model.trim="localQuery"
      autofocus
      class="search__input"
      type="text"
      :placeholder="placeholder"
      @keydown.enter="$emit('queryChange', localQuery)"
    />

    <Button
      title="Search"
      color="primaryFull"
      size="fit"
      :disabled="!localQuery"
      @click="$emit('queryChange', localQuery)"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Button from '@/components/Button/Button';

const { mapGetters, mapActions } = createNamespacedHelpers('users');

export default {
  name: 'Search',

  components: {
    Button,
  },

  props: {
    placeholder: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      localQuery: '',
    };
  },
};
</script>

<style src="./search.scss" lang="scss" scoped></style>
