<template>
  <div class="login__wrapper">
    <form id="login" class="login__form" @submit.prevent="login">
      <p class="login__title">
        Get started with
        <br />Paysend Backoffice
      </p>

      <div class="login__textfield">
        <label class="textfield__label" for="email">Username (example - J.Doe)</label>
        <input id="email" v-model="email" required class="textfield__input" type="text" />
      </div>

      <div class="login__textfield">
        <label class="textfield__label" for="password">Password</label>
        <input id="password" v-model="password" required class="textfield__input" type="password" />
      </div>

      <Button
        class="login__btn"
        :disabled="!email || !password"
        title="Log in"
        type="submit"
        size="fit"
        color="primaryFull"
      />
    </form>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import config from '@/config';

export default {
  components: { Button },

  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    login() {
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
      });
    },
  },

  // mounted() {
  //   if (config.LOGIN_USER.USERNAME && config.LOGIN_USER.PASSWORD){
  //     this.email = config.LOGIN_USER.USERNAME;
  //     this.password = config.LOGIN_USER.PASSWORD;
  //     this.login();
  //   }
  //
  // }
};
</script>

<style src="./login.scss" lang="scss" scoped></style>
