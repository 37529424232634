<template>
  <div>
    <div class="search">
      <!--    <input-->
      <!--      v-model.trim="localQuery"-->
      <!--      autofocus-->
      <!--      class="search__input"-->
      <!--      type="text"-->
      <!--      :placeholder="placeholder"-->
      <!--      @keydown.enter="$emit('queryChange', query)"-->
      <!--    >-->
      <imask-input
        v-model="payId"
        autofocus
        :mask="Number"
        :unmask="'typed'"
        class="search__input id"
        type="text"
        placeholder="ID"
        @keydown.enter="$emit('queryChange', currentFilter())"
      />

      <input
        v-model.trim="svExternalId"
        class="search__input"
        type="text"
        placeholder="SmartVistas RRN"
        @keydown.enter="$emit('queryChange', currentFilter())"
      />
      <input
        v-model.trim="gtExternalId"
        class="search__input"
        type="text"
        placeholder="External(GT) payment id"
        @keydown.enter="$emit('queryChange', currentFilter())"
      />
      <input
        v-model.trim="acc"
        class="search__input"
        type="text"
        placeholder="Account/card number"
        @keydown.enter="$emit('queryChange', currentFilter())"
      />
      <!--      <datetime-->
      <!--        v-model="frDate"-->
      <!--        type="datetime"-->
      <!--        :format="dateFormat"-->
      <!--        input-class="picker"-->
      <!--      />-->
      <!--      <datetime-->
      <!--        v-model="toDate"-->
      <!--        type="datetime"-->
      <!--        :format="dateFormat"-->
      <!--        input-class="picker"-->
      <!--      />-->
    </div>
    <div class="search">
      <DateTimeInput :value="dates" @input="changeValue" />

      <Button
        title="Download report"
        color="primary"
        size="fit"
        class="button-send left"
        :disabled="loadReport || !paymentsCount"
        @click="downloadReport"
      />
    </div>
    <div class="search">
      <v-select
        v-model="payType"
        :placeholder="onlyTopup ? 'TopUp type' : 'Payment type'"
        class="style-chooser"
        :options="types"
      ></v-select>

      <v-select
        v-model="sts"
        placeholder="Payment status"
        class="style-chooser"
        :options="statuses"
      ></v-select>

      <v-select
        v-model="frCur"
        placeholder="From currency"
        class="style-chooser"
        :options="currencies"
      ></v-select>

      <v-select
        v-model="toCur"
        placeholder="To currency"
        class="style-chooser"
        :options="currencies"
      ></v-select>
      <Button
        title="Search"
        color="primaryFull"
        size="fit"
        class="button-send"
        @click="$emit('queryChange', currentFilter())"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Button from '@/components/Button/Button';
import { IMaskComponent } from 'vue-imask';
import Http from '@/services/http';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { startOfYesterday, startOfTomorrow } from 'date-fns';
import DateTimeInput from '@/components/DateTimeInput/DateTimeInput';
import ModalConfirm from '@/components/ModalConfirm/ModalConfirm';

const { mapGetters, mapActions } = createNamespacedHelpers('user');

let today = startOfTomorrow();
let yesterday = startOfYesterday();

export default {
  name: 'SearchPayments',

  components: {
    DateTimeInput,
    Button,
    'imask-input': IMaskComponent,
    'v-select': vSelect,
  },

  props: {
    topup: {
      type: Boolean,
      required: false,
    },
    user: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      onlyTopup: this.topup,
      userId: this.user,
      dateFormat: 'yyyy-MM-dd HH:mm:ss',
      query: '',
      payId: '',
      frDate: yesterday.toISOString(),
      toDate: today.toISOString(),
      dates: [yesterday, today],
      frCur: '',
      toCur: '',
      sts: null,
      payType: '',
      types: [],
      statuses: [],
      currencies: [],
      loadReport: false,
      maxCountLinesReport: 5000,
      gtExternalId: '',
      svExternalId: '',
      acc: '',
    };
  },

  computed: {
    ...mapGetters(['sortedKey', 'sortedDirection', 'paymentsCount']),
  },

  created() {
    this.getTypes();
    this.getStatuses();
    this.getCurrencies();

    this.$emit('queryChange', this.currentFilter());
  },

  methods: {
    getTypes() {
      Http.getPaymentTypes(this.onlyTopup).then((data) => {
        this.types = data.data.types;
      });
    },

    getStatuses() {
      Http.getPaymentStatuses().then((data) => {
        this.statuses = data.data.statuses;
      });
    },

    getCurrencies() {
      Http.getCurrencies().then((data) => {
        this.currencies = data.data.currencies;
      });
    },

    setPayType(value) {
      this.payType = value ? value.id : null;
    },

    changeValue(e) {
      if (e && e instanceof Array) {
        this.frDate = e[0].toISOString();
        this.toDate = e[1].toISOString();
      } else {
        this.frDate = null;
        this.toDate = null;
      }
    },

    currentFilter() {
      return {
        query: this.query,
        topups: this.onlyTopup,
        userId: this.userId,
        payId: this.payId > 0 ? this.payId : null,
        payType: this.payType ? this.payType.id : null,
        stsId: this.sts ? this.sts.id : null,
        frDate: this.frDate,
        toDate: this.toDate,
        frCurId: this.frCur ? this.frCur.id : null,
        toCurId: this.toCur ? this.toCur.id : null,
        gtExternalId: this.gtExternalId,
        svExternalId: this.svExternalId,
        acc: this.acc,
      };
    },

    downloadReport() {
      this.$modal.show(
        ModalConfirm,
        {
          title: 'Download report',
          description: `You want to download a report with ${Math.min(
            this.maxCountLinesReport,
            this.paymentsCount,
          )} lines`,
          click: () => {
            this.$modal.hide('modalConfirm');
            this.downloadFile();
          },
        },
        {
          name: 'modalConfirm',
          width: '500',
          height: '300',
        },
      );
    },

    downloadFile() {
      this.loadReport = true;
      Http.downloadReportPayments({
        filter: this.currentFilter(),
        first: this.maxCountLinesReport,
        sortedDirection: this.sortedDirection,
        sortedKey: this.sortedKey,
      })
        .then((rows) => {
          let csvContent = 'data:text/csv;charset=utf-8,' + rows;
          let encodedUri = encodeURI(csvContent);
          let link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'payments_report_' + new Date().toISOString() + '.csv');
          document.body.appendChild(link); // Required for FF
          link.click();
          link.remove();
        })
        .finally(() => {
          this.loadReport = false;
        });
    },
  },
};
</script>

<style src="./searchPayments.scss" lang="scss" scoped></style>

<style lang="scss">
.style-chooser {
  flex: 2;
  margin-right: 30px;
}
.style-chooser .vs__search::placeholder {
  line-height: 50px;
  color: #999;
}
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid rgba(215, 222, 224, 0.64);
}
.style-chooser .vs__selected-options {
  min-width: 100px;
  height: 50px;
}
.style-chooser .vs__selected {
  height: 50px;
}
</style>
