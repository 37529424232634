import Service from '@/services/graphql';
import Http from '@/services/http';
import Vue from 'vue';

const state = {
  user: null,
  userId: null,

  payments: [],
  cards: [],
  onfido: null,
  kycResolvedDocument: null,
  payment: null,
  sortedKey: 'Time',
  sortedDirection: 'desc',

  nextCursor: null,
  query: null,
  filter: {},
  hasNextPage: false,
  paymentsCount: null,

  complianceHistory: [],
  growStatuses: [],
  growSubscriptionInfo: null,
  growStatements: [],
  growRewards: [],
  plusSubscriptionInfo: null,
  plusUserPaymentExtra: null,
  plusUserPaymentExtraLoading: false,
  plusSubscriptionPayments: [],
  cryptoPockets: [],
  cryptoPaymentCount: null,
  cryptoPayments: [],
  cryptoFilter: {},
  cryptoHasNextPage: false,
  cryptoNextCursor: null,

  offices: [
    {id: 'PAYSEND_PLC', label: 'Paysend PLC'},
    {id: 'SERBIAN_HUB', label: 'Serbian hub'},
    {id: 'MOSCOW_OFFICE', label: 'Moscow office'},
  ],
};

const getters = {
  payments: (state) => state.payments,
  payment: (state) => state.payment,
  user: (state) => state.user,
  cards: (state) => state.cards,
  onfido: (state) => state.onfido,
  kycResolvedDocument: (state) => state.kycResolvedDocument,
  nextCursor: (state) => state.nextCursor,
  userId: (state) => state.userId,
  query: (state) => state.query,
  filter: (state) => state.filter,
  hasNextPage: (state) => state.hasNextPage,
  paymentsCount: (state) => state.paymentsCount,
  sortedKey: (state) => state.sortedKey,
  sortedDirection: (state) => state.sortedDirection,
  complianceHistory: (state) => state.complianceHistory,
  growStatuses: (state) => state.growStatuses,
  growSubscriptionInfo: state => state.growSubscriptionInfo,
  growStatements: (state) => state.growStatements,
  growRewards: (state) => state.growRewards,
  plusSubscriptionInfo: (state) => state.plusSubscriptionInfo,
  plusUserPaymentExtra: (state) => state.plusUserPaymentExtra,
  plusUserPaymentExtraLoading: (state) => state.plusUserPaymentExtraLoading,
  plusSubscriptionPayments: (state) => state.plusSubscriptionPayments,
  cryptoPockets: (state) => state.cryptoPockets,
  cryptoPayments: (state) => state.cryptoPayments,
  cryptoPaymentsCount: (state) => state.cryptoPaymentCount,
  cryptoFilter: (state) => state.cryptoFilter,
  cryptoHasNextPage: (state) => state.cryptoHasNextPage,
  cryptoNextCursor: (state) => state.cryptoNextCursor,
  offices: (state) => state.offices,
};

const actions = {
  sortedKeyChanged({ commit, state, dispatch }, sortedKey) {
    if (_.includes(['ID', 'Time', 'Status', 'Type'], sortedKey)) {
      if (sortedKey === state.sortedKey) {
        commit('SORTED_DIRECTION_CHANGED');
      } else {
        commit('SORTED_DIRECTION_RESETED');
      }

      commit('SORTED_KEY_CHANGED', sortedKey);
      dispatch('getPayments', {
        filter: state.filter,
        sortedKey,
        nextPage: false,
      });
    }
  },

  async getUser({ commit, dispatch }, id) {
    commit('LOADING_START', null, { root: true });

    let {
      data: { user },
    } = await Http.getUser({ id });

    commit('USER_LOADED', user);
    // dispatch('getPayments', { userId: user.id, query: '' });
  },

  async getCryptoPockets({ commit, dispatch }, userId) {
    let user = await Http.getCryptoUser({userId});
    commit('CRYPTO_USER_LOADED', user);
  },

  async getComplianceHistory({ commit, dispatch }, { userId, sortKey, sortDirection }) {
    commit('LOADING_START', null, { root: true });

    let {
      data: { complianceHistory },
    } = await Http.getComplianceHistory({ userId, sortKey, sortDirection });

    commit('COMPLIANCE_HISTORY_LOADED', complianceHistory);
  },

  getMorePayments({ dispatch, state }) {
    if (state.hasNextPage) {
      dispatch('getPayments', {
        filter: state.filter,
        after: state.nextCursor,
        sortedKey: state.sortedKey,
        nextPage: true,
      });
    }
  },

  getCryptoMorePayments({ dispatch, state }) {
    if(state.cryptoHasNextPage) {
      dispatch('getCryptoPayments', {
        filter: state.cryptoFilter,
        after: state.cryptoNextCursor,
        nextPage: true,
      });
    }
  },

  async blockUser({ commit }, { userId, reason, bdid }) {
    // console.log('block user', userId, reason, bdid);

    commit('LOADING_START', null, { root: true });

    let {
      data: { blockUser: user },
    } = await Http.blockUser({
      id: userId,
      reason: reason,
      bdid: bdid,
    });

    // console.log('blockUser', user);

    commit('LOADING_STOP', null, { root: true });

    commit('USER_BLOCKED', { user });
  },

  async removeUser({ commit }, { userId, reason, immediately }) {
    commit('LOADING_START', null, { root: true });
    //console.log(immediately)
    let {
      data: { removeUser: user },
    } = await Http.removeUser({
      id: userId,
      reason: reason,
      immediately: immediately,
    });

    commit('LOADING_STOP', null, { root: true });
    commit('USER_BLOCKED', { user });
  },

  async reopenUser({ commit }, { userId }) {
    commit('LOADING_START', null, { root: true });
    //console.log(immediately)
    let {
      data: { reopenUser: user },
    } = await Http.reopenUser({
      id: userId,
    });

    commit('LOADING_STOP', null, { root: true });
    commit('USER_REOPENED', { user });
  },

  async editUserInfo({ commit }, { userId, info }) {
    commit('LOADING_START', null, { root: true });

    let {
      data: { editUserInfo: user },
    } = await Http.editUserInfo({
      id: userId,
      info: info,
    });

    commit('LOADING_STOP', null, { root: true });

    commit('UPDATE_USER_INFO', { user });
  },

  async completePayment({ commit }, id) {
    commit('LOADING_START', null, { root: true });

    let {
      data: { completePayment: payment },
    } = await Http.completePayment({ id }).finally(() =>
      commit('LOADING_STOP', null, { root: true }),
    );

    commit('PAYMENT_COMPLETED', payment);
  },

  async refundPayment({ commit }, { id, reason, amount, sortCode, accountNumber, recipient, reference }) {
    commit('LOADING_START', null, { root: true });

    let {
      data: { refundPayment: payment },
    } = await Http.refundPayment({ id, reason, amount, sortCode, accountNumber, recipient, reference }).finally(() =>
      commit('LOADING_STOP', null, { root: true }),
    );

    // console.log('refundPayment', payment);

    commit('PAYMENT_REFUNDED', payment);
  },

  async refundPaymentToCard(
    { commit },
    { userId, amount, frCurId, toCurId, pan, comment, expDate },
  ) {
    commit('LOADING_START', null, { root: true });
    let {
      data: { refundPayment: payment },
    } = await Http.refundPaymentToCard({
      userId,
      amount,
      frCurId,
      toCurId,
      pan,
      comment,
      expDate,
    }).finally(() => commit('LOADING_STOP', null, { root: true }));
  },

  async makeExchange({ commit }, { amount, frCurCode, toCurCode, comment, userId }) {
    commit('LOADING_START', null, { root: true });
    let {
      data: { exchangePayment: payment },
    } = await Http.exchangePayment({
      amount,
      frCurCode,
      toCurCode,
      comment,
      userId,
    }).finally(() => commit('LOADING_STOP', null, { root: true }));
  },

  async unblockUser({ commit }, { userId }) {
    // console.log('unblock user', userId);

    commit('LOADING_START', null, { root: true });

    let {
      data: { unblockUser: user },
    } = await Http.unblockUser({
      id: userId,
    });

    // console.log('unblockUser', user);

    commit('LOADING_STOP', null, { root: true });

    commit('USER_UNBLOCKED', { user });
  },

  async changeDueDiligenceLevel(
    { commit },
    {
      userId,
      dueDiligenceLevel,
      needToChangeKycLevel,
      dateUntilDocument,
      documentNumber,
      personalNumber,
      documentType,
      documentIssueCountryId,
      description,
      editKycAdditionalDoc,
    },
  ) {
    commit('LOADING_START', null, { root: true });

    let {
      data: { changeDueDiligenceLevel: user, errors },
    } = await Http.changeDueDiligenceLevel({
      id: userId,
      dueDiligenceLevel: /(?:Level\s(\d))/.exec(dueDiligenceLevel)[1], // Level 1 || Level 2, so we need to clean this up
      needToChangeKycLevel: needToChangeKycLevel,
      dateUntilDocument: dateUntilDocument,
      documentNumber: documentNumber,
      personalNumber: personalNumber,
      documentType: documentType,
      documentIssueCountryId: documentIssueCountryId,
      description: description,
      editKycAdditionalDoc : editKycAdditionalDoc
    });

    commit('LOADING_STOP', null, { root: true });

    commit('DUE_DILIGENCE_LEVEL_CHANGED', { user });
  },

  async checkTheSameDoc({}, {
    userId,
    dueDiligenceLevel,
    needToChangeKycLevel,
    dateUntilDocument,
    documentNumber,
    personalNumber,
    documentType,
    documentIssueCountryId,
    editKycAdditionalDoc
  }) {
    let {
      data: { check: ddDoc },
    } = await Http.checkTheSameDoc({
      id: userId,
      dueDiligenceLevel: /(?:Level\s(\d))/.exec(dueDiligenceLevel)[1], // Level 1 || Level 2, so we need to clean this up
      needToChangeKycLevel: needToChangeKycLevel,
      dateUntilDocument: dateUntilDocument,
      documentNumber: documentNumber,
      personalNumber: personalNumber,
      documentType: documentType,
      documentIssueCountryId: documentIssueCountryId,
      editKycAdditionalDoc: editKycAdditionalDoc,
    });
    return ddDoc;
  },

  async getPayments({ commit, state }, { filter, after, sortedKey, nextPage = false }) {
    commit('PAYMENTS_LOAD_START');

    if (!nextPage) {
      commit('LOADING_START', null, { root: true });
    }

    let {
      data: {
        paymentsConnection: { pageInfo, edges },
      },
    } = await Http.getPayments({
      filter: filter || {},
      after,
      sortedKey,
      sortedDirection: state.sortedDirection,
    }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('PAYMENTS_LOADED', { edges, pageInfo, filter, nextPage });
  },

  async getCryptoPayments({ commit, state }, { filter, after, nextPage = false }) {
    commit('PAYMENTS_CRYPTO_LOAD_START');

    if (!nextPage) {
      commit('LOADING_START', null, { root: true });
    }

    let {
      data: {
        paymentsConnection: { pageInfo, edges },
      },
    } = await Http.getCryptoUserTransactions({
      filter: filter || {},
      after,
      sortedDirection: 'desc'
    }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });


    commit('PAYMENTS_CRYPTO_LOADED', { edges, pageInfo, filter, nextPage });
  },

  async getCards({ commit, state }, { userId }) {
    commit('LOADING_START', null, { root: true });

    let {
      data: { cards },
    } = await Http.getCards({ userId }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('CARDS_LOADED', { cards });
  },

  async blockCard({ commit, state }, { cardId, reason }) {
    commit('LOADING_START', null, { root: true });
    let {
      data: { result },
    } = await Http.blockCard({ cardId, reason }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('CARDS_CHANGE_STATUS', { cardId, result, status: 'removed' });
  },

  async freezeCard({ commit, state }, { cardId, reason }) {
    commit('LOADING_START', null, { root: true });
    let {
      data: { result },
    } = await Http.freezeCard({ cardId, reason }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('CARDS_CHANGE_STATUS', { cardId, result, status: 'frozen_by_system' });
  },

  async unfreezeCard({ commit, state }, { cardId, reason }) {
    commit('LOADING_START', null, { root: true });
    let {
      data: { result },
    } = await Http.unfreezeCard({ cardId, reason }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('CARDS_CHANGE_STATUS', { cardId, result, status: 'active' });
  },

  async getCardPan({ commit, state }, { cardId }) {
    let {
      data: { pan },
    } = await Http.getCardFullPan({ cardId });

    commit('PAN_LOADED', { cardId, pan });
  },

  async getOnfidoReports({ commit, state }, { userId }) {
    commit('LOADING_START', null, { root: true });
    let fullReport = null;
    // await Http.getOnfidoReports({userId})

    let simpleReport = null;
    // await Http.getOnfidoSimpleReport({userId})

    let report = await Http.getOnfidoChecks({ userId }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('ONFIDO_LOADED', { fullReport, simpleReport, report });
  },

  async getKycResolvedDocument({ commit, state }, { userId }) {
    commit('LOADING_START', null, { root: true });

    let kycResolvedDocument = await Http.getKycResolvedDocument({ userId }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('KYC_RESOLVED_DOCUMENT_LOADED', { kycResolvedDocument });
  },

  async getGrowInfo({ commit, state }, { userId }){
    commit('LOADING_START', null, { root: true });

    let subscriptionInfo = await Http.getGrowSubscriptionInfo({ userId });
    let statuses = await Http.getGrowStatusesHistory({ userId })

    commit('LOADING_STOP', null, { root: true });
    commit('GROW_INFO_LOADED', {statuses, subscriptionInfo});
  },

  async cancelGrow({ commit, state }, { userId, products, reason }){
    commit('LOADING_START', null, { root: true });

    let subscriptionInfo = await Http.cancelGrow({userId, products, reason});
    let statuses = await Http.getGrowStatusesHistory({userId});
    let statements = await Http.getGrowStatements({userId})

    commit('LOADING_STOP', null, { root: true });
    commit('GROW_INFO_LOADED', {statuses, subscriptionInfo, statements, rewards: null});
  },

  async getPlusInfo({ commit, state }, { userId }){
    commit('LOADING_START', null, { root: true });
    let subscriptionInfo = await Http.getPlusInfo({ userId });
    commit('LOADING_STOP', null, { root: true });
    commit('PLUS_INFO_LOADED', { subscriptionInfo });
  },

  async getPlusSubscriptionPayments({ commit, state }, { userId }){
    commit('LOADING_START', null, { root: true });
    let payments = await Http.getPlusSubscriptionPayments({ userId });
    commit('LOADING_STOP', null, { root: true });
    commit('PLUS_PAYMENTS_LOADED', { payments });
  },

  async getPlusSubscriptionPaymentExtra({ commit, state }, { payId }){
    commit('PLUS_PAYMENT_EXTRA_LOADING_START', {});
    let extra = await Http.getPlusSubscriptionPaymentExtra({ payId });
    commit('PLUS_PAYMENT_EXTRA_LOADED', { extra });
  },

  async cancelPlusSubscription({ commit, state }, { userId }){
    commit('LOADING_START', null, { root: true });
    let extra = await Http.cancelPlusSubscription({ userId });
    let subscriptionInfo = await Http.getPlusInfo({ userId });
    commit('LOADING_STOP', null, { root: true });
    commit('PLUS_INFO_LOADED', { subscriptionInfo });
  },

  async getGrowStatements({ commit, state }, { userId }){
    commit('LOADING_START', null, { root: true });
    let statements = await Http.getGrowStatements({userId})

    commit('LOADING_STOP', null, { root: true });
    commit('GROW_STATEMENTS_LOADED', { statements });
  },

  async getGrowRewards({ commit, state }, { userId }){
    commit('LOADING_START', null, { root: true });
    let rewards = await Http.getGrowRewards({ userId })

    commit('LOADING_STOP', null, { root: true });
    commit('GROW_REWARDS_LOADED', { rewards });
  },
};

const mutations = {
  SORTED_DIRECTION_CHANGED(state) {
    if (state.sortedDirection === 'asc') {
      state.sortedDirection = 'desc';
    } else {
      state.sortedDirection = 'asc';
    }
  },

  COMPLIANCE_HISTORY_LOADED(state, complianceHistory) {
    state.complianceHistory = complianceHistory;
  },

  SORTED_DIRECTION_RESETED(state) {
    state.sortedDirection = 'desc';
  },

  SORTED_KEY_CHANGED(state, sortedKey) {
    state.sortedKey = sortedKey;
  },

  USER_LOADED(state, user) {
    state.user = user;
    let limits = JSON.parse(user.userLimits || '[]');
    state.user.userLimits = limits.filter((item) => item.limit_type.indexOf('count') < 0);
  },

  CRYPTO_USER_LOADED(state, user) {
    if (user){
      state.cryptoPockets = user.balances;
    }
  },

  // TODO: remove me when getting payment details will be on the same page
  PAYMENT_LOADED(state, payment) {
    state.payment = payment;
  },

  PAYMENT_COMPLETED(state, payment) {
    let payments = state.payments;

    const index = state.payments.findIndex(function (p) {
      return p.id == payment.id;
    });

    Vue.set(state.payments, index, payment);

    state.payments = payments;
    state.payment = payment;
  },

  PAYMENT_REFUNDED(state, payment) {
    let payments = state.payments;

    const index = state.payments.findIndex(function (p) {
      return p.id == payment.id;
    });

    Vue.set(state.payments, index, payment);

    state.payments = payments;
    state.payment = payment;
  },

  USER_BLOCKED(state, { user }) {
    state.user = user;
    let limits = JSON.parse(user.userLimits || '[]');
    state.user.userLimits = limits.filter((item) => item.limit_type.indexOf('count') < 0);
  },

  USER_REOPENED(state, { user }) {
    state.user = user;
    let limits = JSON.parse(user.userLimits || '[]');
    state.user.userLimits = limits.filter((item) => item.limit_type.indexOf('count') < 0);
  },

  UPDATE_USER_INFO(state, { user }) {
    state.user.email = user.email;
    state.user.phone = user.phone;
    state.user.firstName = user.firstName;
    state.user.lastName = user.lastName;
    state.user.dateOfBirth = user.dateOfBirth;
    state.user.address = user.address;
    state.user.countryOfResidence = user.countryOfResidence;
    state.user.addressLine1 = user.addressLine1;
    state.user.addressLine2 = user.addressLine2;
    state.user.postalCode = user.postalCode;
    state.user.city = user.city;
    state.user.isPaysender = user.isPaysender;
    state.user.countryOfPhone = user.countryOfPhone;
    state.user.office = user.office;
  },

  USER_UNBLOCKED(state, { user }) {
    state.user = user;
    let limits = JSON.parse(user.userLimits || '[]');
    state.user.userLimits = limits.filter((item) => item.limit_type.indexOf('count') < 0);
  },

  DUE_DILIGENCE_LEVEL_CHANGED(state, { user }) {
    state.user = user;
    let limits = JSON.parse(user.userLimits || '[]');
    state.user.userLimits = limits.filter((item) => item.limit_type.indexOf('count') < 0);
  },

  PAYMENTS_LOAD_START(state) {
    state.paymentsCount = null;
  },

  PAYMENTS_LOADED(state, { edges, pageInfo, filter, nextPage }) {
    state.hasNextPage = pageInfo.hasNextPage;
    state.paymentsCount = pageInfo.allCount;
    state.filter = filter;

    if (edges.length) {
      if (nextPage) {
        state.payments = state.payments.concat(
          edges
            .filter((e) => state.payments.map((u) => u.id).indexOf(e.node.id) < 0)
            .map((e) => e.node),
        );
      } else {
        state.payments = edges.map((e) => e.node);
      }

      state.nextCursor = _.last(edges).cursor;
    } else {
      if (!nextPage) {
        state.payments = [];
      }

      state.nextCursor = null;
    }

    state.payments = state.payments.map((item) => {
      if (item.type === 'P2pCreditPayment') {
        item.type = 'Card to card transfer (inbound)';
      }
      if (item.type === 'InternalTransferPayment') {
        item.type = 'P2P internal transfer';
      }
      if (item.type === 'ExternalCardPayment') {
        item.type = 'External card transfer';
      }
      if (item.type === 'P2pDebitPayment') {
        item.type = 'Card to card transfer (outbound)';
      }
      return item;
    });
  },

  PAYMENTS_CRYPTO_LOAD_START(state) {
    state.cryptoPaymentCount = null;
  },

  PAYMENTS_CRYPTO_LOADED(state, { edges, pageInfo, filter, nextPage }) {
    state.cryptoHasNextPage = pageInfo.hasNextPage;
    state.cryptoPaymentCount = pageInfo.allCount;
    state.cryptoFilter = filter;

    if (edges.length) {
      if (nextPage) {
        state.cryptoPayments = state.cryptoPayments.concat(edges.filter(e =>
          state.cryptoPayments.map(u => u.id)
            .indexOf(e.node.id)<0)
          .map(e => e.node));
      } else {
        state.cryptoPayments = edges.map(e => e.node);
      }

      state.cryptoNextCursor = _.last(edges).cursor;
    } else {
      if (!nextPage) {
        state.cryptoPayments = [];
      }

      state.cryptoNextCursor = null;
    }

  },

  CARDS_LOADED(state, { cards }) {
    state.cards = cards;
  },

  ONFIDO_LOADED(state, { fullReport, simpleReport, report }) {
    state.onfido = { fullReport, simpleReport, report };
  },

  CARDS_CHANGE_STATUS(state, { cardId, result, status }) {
    if (result) {
      let index = state.cards.findIndex((item) => item.id === cardId);
      if (index >= 0) {
        state.cards[index].state = status;
      }
    }
  },

  PAN_LOADED(state, { cardId, pan }) {
    let index = state.cards.findIndex((item) => item.id === cardId);
    if (index >= 0) {
      state.cards[index].pan = pan;
      state.cards[index].showPan = true;
    }
  },

  GROW_INFO_LOADED(state, { statuses, subscriptionInfo }) {
    state.growSubscriptionInfo = subscriptionInfo;
    state.growStatuses = statuses.map(item => {
      item.addInfo = JSON.parse(item.addInfo || '{}');
      return item;
    });
  },

  PLUS_INFO_LOADED(state, { subscriptionInfo }) {
    state.plusSubscriptionInfo = subscriptionInfo;
  },

  GROW_STATEMENTS_LOADED(state, { statements }) {
    state.growStatements = statements;
  },

  GROW_REWARDS_LOADED(state, { rewards }) {
    state.growRewards = rewards;
  },

  PLUS_PAYMENTS_LOADED(state, { payments }) {
    state.plusSubscriptionPayments = payments;
  },

  PLUS_PAYMENT_EXTRA_LOADING_START(state, {}){
    state.plusUserPaymentExtraLoading = true;
  },

  PLUS_PAYMENT_EXTRA_LOADED(state, { extra }){
    state.plusUserPaymentExtraLoading = false;
    state.plusUserPaymentExtra = [];
    for (let key in extra){
      state.plusUserPaymentExtra.push({
        label: key,
        value: extra[key]
      })
    }
  },

  KYC_RESOLVED_DOCUMENT_LOADED(state, { kycResolvedDocument }) {
    state.kycResolvedDocument = kycResolvedDocument;
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
