<template>
  <main>
    <div v-if="$loading" class="grow__results">
      <Spinner class="grow__spinner" />
    </div>
    <div v-else class="grow__results">
      <div class="row__label">
        <span
          v-for="key in filters"
          :id="key"
          :key="key"
          class="results__column"
          :class="[
            key.toLowerCase()
          ]"
        >{{ key }}</span>
      </div>
      <span v-if="!plusSubscriptionPayments.length" class="grow__noresults">No payments</span>
      <div v-else>
        <BulmaAccordion
          :icon="'custom'"
          :slide="{
            duration: '.4s',
            timerFunc: 'ease',
          }"
        >
          <div v-for="payment in plusSubscriptionPayments" :key="payment.payId">
            <BulmaAccordionItem @open="openItem(payment.payId)">
              <div
                :id="payment.payId"
                slot="title"
                class="row__wrapper"
                :class="{
                  grey: payment.stsId > 90,
                }"
              >
                <span class="results__column id" >{{ payment.payId}}</span>
                <span class="results__column" >{{ payment.payTs | formatTime}}</span>
                <span class="results__column type type__two-lines">
                  <p>From {{ payment.frPrvName}} ({{payment.frPrvId}})</p>
                  <p>To {{ payment.toPrvName}} ({{payment.toPrvId}})</p>
                </span>
                <span class="results__column description">
                  {{payment.title}}
                  <p class="opacity">{{payment.comment}}</p>
                </span>
                <span class="results__column">{{payment.frAmtReal | formatMoneyNotDivide}} {{payment.frCurIso}}</span>
                <span class="results__column">{{payment.toAmtReal | formatMoneyNotDivide}} {{payment.toCurIso}}</span>
                <span
                  class="results__column status"
                  style="white-space: nowrap"
                  :class="{
                    green: payment.stsId === 90,
                    yellow: payment.stsId < 90,
                  }"
                >{{ payment.stsName ? payment.stsName : '' }} <br />
                  ({{ payment.stsId }})</span>
              </div>
              <div slot="content" class="showDet">
                <div class="generalDet">
                  <UserPlusPaymentExtra
                    v-if="payment.payId === opened"
                    :pay-id="payment.payId"
                  />
                </div>
              </div>
            </BulmaAccordionItem>
          </div>
        </BulmaAccordion>
      </div>
    </div>
  </main>
</template>
<script>
import Spinner from '@/components/Spinner/Spinner';
import {createNamespacedHelpers} from 'vuex';
import {BulmaAccordion, BulmaAccordionItem} from 'vue-bulma-accordion';
import UserPlusPaymentExtra
  from '@/views/User/UserPlus/UserPlusPayments/UserPlusPaymentExtra/UserPlusPaymentExtra';

const { mapGetters, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'UserPlusPayments',

  components: {
    UserPlusPaymentExtra,
    Spinner,
    BulmaAccordion,
    BulmaAccordionItem,
  },

  data() {
    return {
      opened: 0,
      showDetails: [],
      filters: ['ID', 'Time', 'Type', 'Description', 'From', 'To', 'Status'],
    };
  },

  computed: {
    ...mapGetters(['plusSubscriptionPayments']),
  },

  created() {
    this.getPlusSubscriptionPayments({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getPlusSubscriptionPayments']),
    openItem(payId){
      this.opened = payId;
    }
  }
}
</script>

<style src="./userPlusPayments.scss" lang="scss" scoped></style>
