<template>
  <div>
    <button class="anchor" @click="toggleShow">
      {{ btnName }}
    </button>
    <div v-if="showMenu" class="menu">
      <div
        v-for="item in items"
        :key="item"
        class="menu-item"
        :class="{ active: active === item }"
        @click="itemClicked(item)"
      >
        <div>{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    btnName: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    activeItem: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      showMenu: false,
      active: this.activeItem,
    };
  },
  methods: {
    toggleShow: function () {
      this.showMenu = !this.showMenu;
    },
    itemClicked: function (item) {
      this.toggleShow();
      this.active = item;
      this.onClick(item);
    },
  },
};
</script>

<style src="./dropdown.scss" scoped lang="scss"></style>
