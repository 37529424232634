<template>
  <main>
    <div v-if="$loading" class="grow__results">
      <Spinner class="grow__spinner" />
    </div>
    <div v-else class="grow__results">
    <h2>Cashback transactions</h2>
      <span v-if="!growRewards.length" class="grow__noresults">User doesn't have cashback</span>
      <div v-else class="grow__list">
        <div class="grow__label">
          <span class="label__default id">Purchase ID</span>
          <span class="label__default">Date</span>
          <span class="label__default name">Advertiser</span>
          <span class="label__default">Total Amount</span>
          <span class="label__default">Cashback Offer</span>
          <span class="label__default">User Reward</span>
          <span class="label__default">Status</span>
        </div>
        <div v-for="(reward, index) in growRewards" :key="index">
          <div v-if="countRewards > index" class="grow__item">
              <span class="id">
                {{ reward.saleId }}
                <p class="opacity uppercase">{{ reward.source }}</p>
              </span>
            <span>{{ reward.dateTime | formatTime }}</span>
            <span class="name">
                {{ reward.programName }}
                <p class="opacity">{{ reward.productName }}</p>
                <p class="opacity">{{ reward.catName }}</p>
              </span>
            <span>{{ reward.saleAmount }} {{ reward.currency }}</span>
            <span>{{ reward.commissionAmount }} {{ reward.currency }}</span>
            <span>{{ reward.userCashBackAmount }} {{ reward.currency }}</span>
            <span>{{ reward.status }}</span>
          </div>
        </div>
        <br/>
        <p class="text-center">
          <a v-if="countRewards > 5"
             class="link"
             @click="lessRewards"
          >Less</a>
          <a v-if="countRewards < growRewards.length"
             class="link"
             @click="moreRewards"
          >More</a>
        </p>
      </div>
    </div>
  </main>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
import Spinner from '@/components/Spinner/Spinner';

const { mapGetters, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'GrowRewards',

  components: {
    Spinner
  },

  data() {
    return {
      countRewards: 5,
    };
  },

  computed: {
    ...mapGetters(['growRewards']),
  },

  created() {
    this.getGrowRewards({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getGrowRewards']),
    moreRewards(){
      this.countRewards += 5;
    },

    lessRewards(){
      this.countRewards -= 5;
    },
  }
}
</script>

<style src="../userGrow.scss" lang="scss" scoped></style>
