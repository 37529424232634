<template>
  <main>
    <div v-if="$loading" class="grow__results">
      <Spinner class="grow__spinner" />
    </div>
    <div v-else class="grow__results">
      <h2>Product Information</h2>
      <span v-if="!plusSubscriptionInfo.isAvailable"
            class="grow__noresults"
      >Paysend Plus is not available for user</span>
      <div v-else>
        <div v-if="!!plusSubscriptionInfo.info">
          <div class="info__subscription">
            <div class="info__row">
              <span class="info__label">Status</span>
              <span>{{ plusSubscriptionInfo.info.status }}</span>
            </div>
            <div class="info__row">
              <span class="info__label">Payment Method</span>
              <span>{{ plusSubscriptionInfo.info.paymentMethod || '-'}} <span v-if="plusSubscriptionInfo.info.isProlonged">(Autopay)</span></span>
            </div>
            <div class="info__row">
              <span class="info__label">Subscription Plan</span>
              <span v-if="!!plusSubscriptionInfo.info.subscriptionPlan">
                {{ plusSubscriptionInfo.info.subscriptionPlan }} ({{plusSubscriptionInfo.info.subscriptionPrice}})
              </span>
              <span v-else>-</span>
            </div>
            <div class="info__row">
              <span class="info__label">Start of Subscription</span>
              <span>{{ plusSubscriptionInfo.info.startSubscription | formatTime }}</span>
            </div>
            <div class="info__row">
              <span v-if="isSubscribed() && plusSubscriptionInfo.info.isProlonged"
                    class="info__label"
              >Next Payment Date</span>
              <span v-else
                    class="info__label"
              >End of Subscription</span>
              <span>{{ plusSubscriptionInfo.info.nextPaymentDate | formatTime }}</span>
            </div>
            <div v-if="isSubscribed()">
              <div class="info__row">
                <Button
                  title="Unsubscribe"
                  color="orangeFull"
                  size="small"
                  @click="unsubscribe()"
                />
              </div>
            </div>
          </div>
          <GrowProducts :products="plusSubscriptionInfo.products"
                        :user-products="getUserProducts()"
                        :show-cancel-button="false"
          ></GrowProducts>
        </div>

        <span v-else
              class="grow__noresults"
        >User hasn't subscribed Paysend Plus yet</span>
      </div>
    </div>
  </main>
</template>
<script>

import Spinner from '@/components/Spinner/Spinner';
import GrowProducts from '@/views/User/UserGrow/GrowProducts/GrowProducts';
import {createNamespacedHelpers} from 'vuex';
import userPlus from '../UserPlus.vue';
import Button from '@/components/Button/Button';


const { mapGetters, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'UserPlusSubscription',

  components: {
    Spinner, GrowProducts, Button
  },

  computed: {
    ...mapGetters(['plusSubscriptionInfo']),
  },

  created() {
    this.getPlusInfo({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getPlusInfo', 'cancelPlusSubscription']),

    getUserProducts(){
      return  this.isSubscribed() && !!this.plusSubscriptionInfo.info.products ? JSON.parse(this.plusSubscriptionInfo.info.products) : [];
    },

    isSubscribed(){
      return !!this.plusSubscriptionInfo.info && this.plusSubscriptionInfo.info.status === 'SUBSCRIBED';
    },

    unsubscribe(){
      this.cancelPlusSubscription({ userId: this.$route.params.userId })
    }

  }
}
</script>
<style src="./userPlusSubscription.scss" lang="scss" scoped></style>
