const SERVER_URL = process.env.VUE_APP_SERVER_URL;
const SERVER_CRYPTO_URL = process.env.VUE_APP_CRYPTO_API_URL;
const SERVER_CRYPTO_TOKEN = process.env.VUE_APP_CRYPTO_TOKEN;
// const USERNAME = process.env.VUE_APP_USERNAME;
// const PASSWORD = process.env.VUE_APP_PASSWORD;
// LOGIN_USER:{
//   USERNAME: USERNAME,
//     PASSWORD: PASSWORD
// }
export default {
  SERVER_URL,
  HTTP_API_CRYPTO_URL: SERVER_CRYPTO_URL,
  API_CTYPTO_TOKEN: SERVER_CRYPTO_TOKEN,
  HTTP_API_URL: `${SERVER_URL}/backoffice/api`,
  GRAPHQL_URL: `${SERVER_URL}/backoffice/graphql`,
  LOGIN_URL: `${SERVER_URL}/backoffice/login`,
};
