<template>
  <div
    v-if="
      user &&
      ((user.ibans && user.ibans.length > 0) ||
        (user.modulrAccounts && user.modulrAccounts.length > 0))
    "
    class="account-details"
  >
    <p class="account-details__title">Account details</p>
    <div v-if="user.ibans && user.ibans.length > 0">
      <div v-for="iban in user.ibans" :key="iban.id" class="account">
        <div class="account__row">
          <span class="account__label">Account name:</span>
          <span>{{ user.firstName }} {{ user.lastName }}</span>
        </div>
        <div class="account__row">
          <span class="account__label">BIC:</span>
          <span>{{ iban.bic }}</span>
        </div>
        <div class="account__row">
          <span class="account__label">IBAN:</span>
          <span>{{ iban.iban }}</span>
        </div>
      </div>
    </div>
    <div v-if="user.modulrAccounts && user.modulrAccounts.length > 0">
      <div v-for="modulr in user.modulrAccounts" :key="modulr.id" class="account">
        <div class="account__row">
          <span class="account__label">Account name:</span>
          <span>{{ modulr.name }}</span>
        </div>
        <div class="account__row">
          <span class="account__label">Sort code:</span>
          <span>{{ modulr.sortCode.substr(0, 2) }}-{{ modulr.sortCode.substr(2, 2) }}-{{
              modulr.sortCode.substr(4, 2)
            }}</span>
        </div>
        <div class="account__row">
          <span class="account__label">Account number:</span>
          <span>{{ modulr.accountNumber.substr(0, 4) }} {{ modulr.accountNumber.substr(4, 4) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style src="./accountDetails.scss" lang="scss" scoped></style>
