<template>
    <div class="dropdown-select">
        <span class="label">{{ label }}</span>
        <select v-model="computedValue">
            <slot></slot>
        </select>
    </div>
</template>

<script>
export default {
  name: 'DropdownSelect',
  props: {
    label: {
      type: String,
      required: true
    },
    // eslint-disable-next-line
    value: [String, Number]
  },
  emits: ['input'],
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style src="./dropdownselect.scss" lang="scss" scoped>
</style>