<template>
  <router-link class="sidenav__link" :to="{ name: link, query: null }">
    <div class="link__wrapper">
      <img :src="getPic(link)" class="link__img" />
      <p class="link__text">
        {{ link }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'SidebarItem',

  props: {
    link: {
      type: String,
      required: true,
    },
  },

  methods: {
    getPic(pic) {
      let isActive = (this.$route.name || '').split('-').indexOf(pic) === 0 ? '_active' : '';
      return require('@/assets/img/' + pic + isActive + '.svg');
    },
  },
};
</script>

<style src="./sidebarLink.scss" scoped lang="scss"></style>
