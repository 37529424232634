import { render, staticRenderFns } from "./SearchResultsStatements.vue?vue&type=template&id=344b436f&scoped=true&"
import script from "./SearchResultsStatements.vue?vue&type=script&lang=js&"
export * from "./SearchResultsStatements.vue?vue&type=script&lang=js&"
import style0 from "./searchResultsStatements.scss?vue&type=style&index=0&id=344b436f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "344b436f",
  null
  
)

export default component.exports