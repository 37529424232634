import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import Login from '@/views/Login/Login.vue';

import Users from '@/views/Users/Users.vue';
import User from '@/views/User/User.vue';
import BalanceAndPayments from '@/views/User/Payments/BalanceAndPayments.vue';
import FraudAndCompliance from '@/views/User/Fraud/FraudAndCompliance';
import Profile from '@/views/User/Profile/Profile.vue';
import Paysenders from '@/views/Users/Paysenders.vue';
import Payments from '@/views/Payments/Payments.vue';
import Topups from './views/Topups/Topups';

import Balances from '@/views/Balances/Balances.vue';
import Cards from '@/views/User/Cards/Cards.vue';
import Kyc from '@/views/User/KYC/Kyc';
import router from 'vue-router';
import UserGrow from '@/views/User/UserGrow/UserGrow.vue';
import Grow from '@/views/Grow/Grow.vue';
import GrowWhitelists from '@/views/Grow/Whitelists/Whitelists.vue';
import GrowBalances from '@/views/Grow/Balances/Balances.vue';
import GrowStatements from '@/views/Grow/Statements/Statements.vue';
import GrowPayments from '@/views/Grow/Payments/Payments';
import UserPlus from '@/views/User/UserPlus/UserPlus';

const originalPush = router.prototype.push;
router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const myRouter = new Router({
  mode: 'history',

  routes: [
    { path: '/', redirect: '/users' },

    {
      path: '/users',
      name: 'users',
      component: Users,
    },

    {
      path: '/users/:userId',
      component: User,
      props: { namespace: 'users' },
      redirect: 'users-profile',
      children: [
        {
          path: 'users-profile',
          name: 'users-profile',
          component: Profile,
        },

        {
          path: 'balance-and-payments',
          name: 'users-balance-and-payments',
          component: BalanceAndPayments,
        },

        {
          path: 'cards',
          name: 'users-cards',
          component: Cards,
        },

        {
          path: 'kyc',
          name: 'users-kyc',
          component: Kyc,
        },

        {
          path: 'fraud-and-compliance',
          name: 'users-fraud-and-compliance',
          component: FraudAndCompliance,
        },

        {
          path: 'grow',
          name: 'users-grow',
          component: UserGrow,
        },

        {
          path: 'plus',
          name: 'users-plus',
          component: UserPlus,
        },
      ],
    },

    {
      path: '/paysenders',
      name: 'paysenders',
      component: Paysenders,
    },

    {
      path: '/paysenders/:userId',
      component: User,
      props: { namespace: 'paysenders' },
      redirect: 'paysenders-profile',
      children: [
        {
          path: 'paysenders-profile',
          name: 'paysenders-profile',
          component: Profile,
        },

        {
          path: 'balance-and-payments',
          name: 'paysenders-balance-and-payments',
          component: BalanceAndPayments,
        },

        {
          path: 'cards',
          name: 'paysenders-cards',
          component: Cards,
        },

        {
          path: 'kyc',
          name: 'paysenders-kyc',
          component: Kyc,
        },

        {
          path: 'fraud-and-compliance',
          name: 'paysenders-fraud-and-compliance',
          component: FraudAndCompliance,
        },

        {
          path: 'grow',
          name: 'paysenders-grow',
          component: UserGrow,
        },

        {
          path: 'plus',
          name: 'paysenders-plus',
          component: UserPlus,
        },
      ],
    },

    {
      path: '/payments',
      name: 'payments',
      component: Payments,
    },

    {
      path: '/topups',
      name: 'topups',
      component: Topups,
    },

    {
      path: '/balances',
      name: 'balances',
      component: Balances,
    },

    {
      path: '/grow',
      name: 'grow',
      component: Grow,
      redirect: 'grow/whitelists',
      children: [
        {
          path: 'whitelists',
          name: 'grow-whitelists',
          component: GrowWhitelists,
        },
        {
          path: 'balances',
          name: 'grow-balances',
          component: GrowBalances,
        },
        {
          path: 'statements',
          name: 'grow-statements',
          component: GrowStatements,
        },
        {
          path: 'payments',
          name: 'grow-payments',
          component: GrowPayments,
        }
      ]
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
    },
  ],
});

myRouter.beforeEach((to, _, next) => {
  if (store.getters['isLoggedIn'] || to.name === 'login') {
    next();
  } else {
    localStorage.setItem('url_target', window.location.href);
    next({ name: 'login' });
  }
});

export default myRouter;
