import Vue from 'vue';
import { format } from 'date-fns';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

Vue.filter('formatMoney', (value, paymentKind = '', typeValue = '') => {
  if (!value) return '0';

  const amount = (value / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  let sign = '';

  if (paymentKind === 'CREDIT' && typeValue === 'from') {
    sign = '+ ';
  } else if (paymentKind === 'CREDIT' && typeValue === 'to') {
    sign = '+ ';
  } else if (paymentKind === 'DEBIT' && typeValue === 'from') {
    sign = '- ';
  } else if (paymentKind === 'DEBIT' && typeValue === 'to') {
    sign = '- ';
  } else if (paymentKind === 'EXCHANGE' && typeValue === 'from') {
    sign = '- ';
  } else if (paymentKind === 'EXCHANGE' && typeValue === 'to') {
    sign = '+ ';
  }

  return `${sign}${amount}`;
});

Vue.filter('formatMoneyNotDivide', (value, paymentKind = '', typeValue = '') => {
  if (!value) return '0';
  const amount = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `${amount}`;
});

Vue.filter('formatMoneyScale', (value, scale = 2) => {
  if (!value) return '0';
  return `${value / Math.pow(10, scale)}`;
});

Vue.filter('formatTime', (value) => {
  if (!value) return '-';

  return format(value, 'DD.MM.YYYY · HH:mm:ss');
});

Vue.filter('formatDate', (value) => {
  if (!value) return '';

  return format(value, 'DD MMM YYYY');
});

Vue.filter('formatShortDate', (value) => {
  if (!value) return '';

  return format(value, 'DD-MMM\'YY');
});

Vue.filter('formatCheckableDate', (value) => {
  if (!value) return ' unspecified';

  return format(value, 'DD-MMM\'YY');
});

Vue.filter('formatPhone', (value) => {
  if (!value) return '';

  return parsePhoneNumberFromString(value).formatInternational();
});
