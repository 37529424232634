<template>
    <div tabindex="0" class="toggle" :class="{ 'enabled' : enabled }" @click="toggleClick()">
        <div class="toggle__knob" :class="{ 'enabled' : enabled }"></div>
    </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    enabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleClick() {
      this.$emit('change', !this.enabled);
    }
  }
}
</script>

<style src="./toggle.scss" lang="scss" scoped></style>