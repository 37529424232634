<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submit">
      <div>
        <button class="modal_block__closebtn" @click="$emit('close')">
          <img src="~@/assets/img/close.svg"/>
        </button>
        <p
          v-if="kycResolvedDocument != null && kycResolvedDocument.documentNumber != null"
          class="modal_block__title_warning"
        >
          Attention, {{ kycResolvedDocument.source }} document exists
        </p>
        <p class="modal_block__title">
          Update {{ user.firstName }} {{ user.lastName }}'s KYC level <br />({{ level }})
        </p>

        <div class="modal_block__form">
          <div class="flex_item_block">
            <span class="modal_edit__label">Document type</span>
            <ValidationProvider v-slot="{ invalid }" :rules="{ required: true }">
              <v-select
                v-model="mainDoc.documentType"
                class="modal_edit__select style-chooser"
                :class="{ modal_edit__select__error: invalid }"
                :options="docTypes"
                :reduce="(docType) => docType.type"
                label="text"
                autofocus
              ></v-select>
            </ValidationProvider>
          </div>
        </div>
        <div class="flex_block">
          <div class="flex_item_block">
            <span class="modal_edit__label">Document number</span>
            <ValidationProvider v-slot="{ invalid }" rules="document_number_regex">
              <imask-input
                v-model="mainDoc.documentNumber"
                class="modal_edit__input"
                :class="{ modal_edit__error: invalid }"
              />
              <span v-if="invalid" class="modal_edit__error_doc_number">
                Only EN letters and digits without whitespaces!
              </span>
            </ValidationProvider>
          </div>
          <div class="flex_item_block">
            <span class="modal_edit__label">Date of expiration</span>
            <ValidationProvider v-slot="{ invalid }" rules="date_after_now">
              <input
                v-model="mainDoc.dateUntilDocument"
                class="modal_edit__input"
                :class="{ modal_edit__error: invalid }"
                type="date"
              />
              <span v-if="invalid" class="modal_edit__error_doc_number">
                Check the input date
                </span>
            </ValidationProvider>
          </div>
        </div>
        <div class="modal_block__form">
          <div class="flex_item_block">
            <span class="modal_edit__label">Issuing country</span>
            <ValidationProvider v-slot="{ invalid }" :rules="{ required: true }">
              <v-select
                v-model="mainDoc.documentIssueCountryId"
                class="style-chooser modal_edit__select"
                :class="{ modal_edit__select__error: invalid }"
                :options="countries"
                :reduce="(country) => country.id"
                label="title"
                @input="countryChangedMainDoc"
              ></v-select>
            </ValidationProvider>
          </div>
        </div>

        <div class="modal_block__form">
          <div v-if="mainDoc.showPersonalNumber" class="flex_item_block">
            <span class="modal_edit__label">Personal number</span>
            <imask-input v-model="mainDoc.personalNumber" class="modal_edit__input"/>
          </div>
        </div>

        <div class="modal_block__form">
          <span class="modal_block__label">Description</span>
          <ValidationProvider v-slot="{ invalid }" rules="description_regex">
            <textarea v-model="mainDoc.documentDescription" class="modal_block__input" autofocus/>
            <span v-if="invalid" class="modal_block__error_description">Only EN letters, numbers, whitespaces and symbols as @(),.-; are accepted!</span>
          </ValidationProvider>
        </div>

        <div class="modal_block__title">
          <label for="checkbox">Additional document </label>
        </div>

        <div class="modal_block__form">
          <div class="modal_block__form">
            <div class="flex_item_block">
              <span class="modal_edit__label">Document type</span>
              <v-select
                v-model="addDoc.additionalDocumentType"
                class="modal_edit__select style-chooser"
                :options="additionalDocTypes"
                :reduce="(additionalDocTypes) => additionalDocTypes.type"
                label="text"
              ></v-select>
            </div>
          </div>

          <div class="flex_block">
            <div class="flex_item_block">
              <span class="modal_edit__label">Document number</span>
              <ValidationProvider v-slot="{ invalid }" rules="document_number_regex">
                <imask-input
                  v-model="addDoc.additionalDocumentNumber"
                  class="modal_edit__input"
                  :class="{ modal_edit__error: invalid }"
                />
                <span v-if="invalid" class="modal_edit__error_doc_number">
                Only EN letters and digits without whitespaces!
                </span>
              </ValidationProvider>
            </div>
            <div class="flex_item_block">
              <ValidationProvider v-slot="{ invalid }" rules="date_after_now">
                <span class="modal_edit__label">Date of expiration</span>
                <input
                  v-model="addDoc.additionalDateUntilDocument"
                  class="modal_edit__input"
                  :class="{ modal_edit__error: invalid }"
                  type="date"
                />
                <span v-if="invalid" class="modal_edit__error_doc_number">Check the input date</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="modal_block__form">
            <div class="flex_item_block">
              <span class="modal_edit__label">Issuing country</span>
              <v-select
                v-model="addDoc.additionalDocumentIssueCountryId"
                class="style-chooser modal_edit__select"
                :options="countries"
                :reduce="(country) => country.id"
                label="title"
                @input="countryChangedAdditionalDoc"
              ></v-select>
            </div>
          </div>

          <div class="modal_block__form">
            <div v-if="addDoc.showAdditionalPersonalNumber" class="flex_item_block">
              <span class="modal_edit__label">Personal number</span>
              <imask-input v-model="addDoc.additionalPersonalNumber" class="modal_edit__input"/>
            </div>
          </div>

          <div class="modal_block__form">
          <ValidationProvider v-slot="{ invalid }" rules="description_regex">
            <textarea v-model="addDoc.additionalDocumentDescription" class="modal_block__input" autofocus/>
            <span v-if="invalid" class="modal_block__error_description">Only EN letters, numbers, whitespaces and symbols as @(),.-; are accepted!</span>
          </ValidationProvider>
          </div>


          <!--        dropdow menu Need to change the KYC level?-->
          <div class="modal_block__form" :class="{modal_block__last_desc: !isNotKycLvlTwo(user)}">
            <div v-if="isNotKycLvlTwo(user)" class="dropdown_block">
              <span class="modal_block__dropdown">Change the KYC level to 2</span>
              <v-select
                v-model="changeKycLevelFlag"
                default-open placeholder="Please select the option"
                class="modal_edit__select__dropdown style-chooser"
                :options="changeKycLevelList"
                :reduce="(changeKycLevelList) => changeKycLevelList.type"
                label="text"
                autofocus
                @input="kycChange"
              ></v-select>
            </div>
          </div>
        </div>

        <Button
          type="submit"
          id="modal_edit__btn"
          title="Update"
          size="tight"
          color="primaryFull"
          class="modal_edit__btn"
          :disabled="invalid || disableButton || !mainDoc.documentType || !mainDoc.documentNumber
          || mainDoc.documentNumber.length < 4 || !mainDoc.documentIssueCountryId
          || (needToChangeKycLevel === '' && isNotKycLvlTwo(user)) "
          @click="handleClick"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import vSelect from 'vue-select';
import { IMaskComponent } from 'vue-imask';
import { createNamespacedHelpers } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';


const { mapState, mapActions } = createNamespacedHelpers('user');



export default {
  name: 'Modal',
  computed: {
    rules() {
      return rules;
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver,
    Button: Button,
    'v-select': vSelect,
    'imask-input': IMaskComponent,

  },

  props: {
    user: {
      type: Object,
      required: true,
    },
    dueDiligenceLevel: {
      type: String,
      required: true,
    },
    kycResolvedDocument: {
      type: Object,
      required: false,
      default: null
    },
    countries: {
      type: Array,
      required: true,
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      docByLevel: {},
      disableButton: false,
      level: '',
      mainDoc: {
        personalNumber: '',
        documentNumber: '',
        dateUntilDocument: '',
        documentType: '',
        documentIssueCountryId: '',
        documentDescription: '',
      },
      addDoc: {
        additionalPersonalNumber: '',
        additionalDocumentNumber: '',
        additionalDateUntilDocument: '',
        additionalDocumentType: '',
        additionalDocumentIssueCountryId: '',
        additionalDescription: '',
      },
      showPersonalNumber: false,
      showAdditionalPersonalNumber: false,
      docTypes: [
        { type: 'passport', text: 'Passport' },
        { type: 'national_identity_card', text: 'National Identity Card' },
        { type: 'driving_licence', text: 'Driver License' },
        { type: 'residence_permit', text: 'Residence Permit' },
        // { type: 'visa', text: 'Visa' },
      ],
      errorMainDocDateUntil: false,
      errorMainDocIssuedCountry: false,

      // Additional document
      changeKycLevelFlag: '',
      changeKycLevelList: [
        {type: 'changeKycLevel', text: 'Change to 2 level'},
        {type: 'keepKycLevel', text: 'Keep the current KYC level'},
      ],
      needToChangeKycLevel: '',
      additionalDocTypes: [
        // {type: 'passport', text: 'Passport'},
        // {type: 'national_identity_card', text: 'National Identity Card'},
        // {type: 'driving_licence', text: 'Driver License'},
        // {type: 'residence_permit', text: 'Residence Permit'},
        {type: 'visa', text: 'Visa'},
      ],

      countryChangedMainDoc: (value) => {
        const mainDocCountry = this.countries.filter((c) => c.id === value)[0];
        this.mainDoc.showPersonalNumber = mainDocCountry && mainDocCountry['iso2-code'] === 'ES';
      },

      countryChangedAdditionalDoc: (value) => {
        const addDocCountry = this.countries.filter((c) => c.id === value)[0];
        this.addDoc.showAdditionalPersonalNumber = addDocCountry && addDocCountry['iso2-code'] === 'ES';
      },

      kycChange: (value) => {
        if (value === 'changeKycLevel') {
          this.needToChangeKycLevel = true;
        } else if (value === 'keepKycLevel') {
          this.needToChangeKycLevel = false;
        } else {
          this.needToChangeKycLevel = '';
        }
      },

      isNotKycLvlTwo: (value) => {
        return value.dueDiligenceLevel !== 1 && value.dueDiligenceLevel !== 2;
      },

      containsElement(elem, arr) {
        return arr.includes(elem);
      },

      handleClick: async () => {
        if(this.user.dueDiligenceLevel === 1 || this.user.dueDiligenceLevel === 2){
          this.needToChangeKycLevel = true;
        }
        this.disableButton = true;
        const editKycAdditionalDoc = {
          additionalDateUntilDocument: this.addDoc.additionalDateUntilDocument,
          additionalDocumentNumber: this.addDoc.additionalDocumentNumber,
          additionalPersonalNumber: this.addDoc.additionalPersonalNumber,
          additionalDocumentType: this.addDoc.additionalDocumentType,
          additionalDocumentIssueCountryId: this.addDoc.additionalDocumentIssueCountryId,
          additionalDocumentDescription: this.addDoc.additionalDocumentDescription,
        }
        let user = '';
        try {
          user = await this.checkTheSameDoc({
            userId: this.user.id,
            dueDiligenceLevel: this.dueDiligenceLevel,
            needToChangeKycLevel: this.needToChangeKycLevel,
            dateUntilDocument: this.mainDoc.dateUntilDocument,
            documentNumber: this.mainDoc.documentNumber,
            personalNumber: this.mainDoc.personalNumber,
            documentType: this.mainDoc.documentType,
            documentIssueCountryId: this.mainDoc.documentIssueCountryId,
            editKycAdditionalDoc: editKycAdditionalDoc,
          }).then((doc) => {
            this.changeDueDiligenceLevel({
              userId: this.user.id,
              dueDiligenceLevel: this.dueDiligenceLevel,
              needToChangeKycLevel: this.needToChangeKycLevel,
              dateUntilDocument: this.mainDoc.dateUntilDocument,
              documentNumber: this.mainDoc.documentNumber,
              personalNumber: this.mainDoc.personalNumber,
              documentType: this.mainDoc.documentType,
              documentIssueCountryId: this.mainDoc.documentIssueCountryId,
              description: this.mainDoc.documentDescription,
              editKycAdditionalDoc: editKycAdditionalDoc,
            });
          });
          this.click();
        } catch (error) {
          this.disableButton = false;
        }
      },
    };
  },

  mounted() {
    this.level = this.dueDiligenceLevel;
  },

  created() {
    const documentsByLevel = this.user.documentsByLevel;

    if (documentsByLevel['2']) {
      const doc = documentsByLevel['2'];
      let mainDoc = null;
      let additionalDoc = null;
      const mainDocTypes = ['passport', 'national_identity_card', 'driving_licence', 'residence_permit'];
      const additionalDocTypes = ['visa'];
      if (doc.mainDocument.documentType != null && doc.mainDocument.documentNumber != null) {
        /**If main document is VISA - swap it to additional document block*/
        if (this.containsElement(doc.mainDocument.documentType, additionalDocTypes) &&
        doc.additionalDocument.additionalDocumentType==='') {
          additionalDoc = doc.mainDocument;

          /**Now main document with empty fields, then they will be filled by the user*/
          this.mainDoc.documentNumber = '';
          this.mainDoc.personalNumber = '';
          this.mainDoc.documentType = '';
          this.mainDoc.documentIssueCountryId = '';
          this.mainDoc.documentDescription = '';
          this.mainDoc.dateUntilDocument = '';
          /**Swap main VISA document to additional document block*/
          this.addDoc.additionalDocumentNumber = additionalDoc.documentNumber ? additionalDoc.documentNumber : '';
          this.addDoc.additionalPersonalNumber = additionalDoc.personalNumber ? additionalDoc.personalNumber : '';
          this.addDoc.additionalDocumentType = additionalDoc.documentType ? additionalDoc.documentType : '';
          this.addDoc.additionalDocumentIssueCountryId = additionalDoc.documentIssueCountryId ?
            additionalDoc.documentIssueCountryId : '';
          this.addDoc.additionalDocumentDescription = additionalDoc.documentDescription;
          this.addDoc.additionalDateUntilDocument = additionalDoc.dateUntilDocument;

          /**Two flags of Espanol user*/
          this.mainDoc.showPersonalNumber = '';

          let addDocCountry = this.countries.filter((c) => c.id === this.addDoc.additionalDocumentIssueCountryId)[0];
          this.addDoc.showAdditionalPersonalNumber = addDocCountry && addDocCountry['iso2-code'] === 'ES';

        } else if (this.containsElement(doc.mainDocument.documentType, additionalDocTypes) &&
          this.containsElement(doc.additionalDocument.additionalDocumentType, mainDocTypes)) {

          mainDoc = doc.additionalDocument;
          additionalDoc = doc.mainDocument;

          /**Swap main VISA document to additional document block*/
          this.mainDoc.documentNumber = mainDoc.additionalDocumentNumber ? mainDoc.additionalDocumentNumber : '';
          this.mainDoc.personalNumber = mainDoc.additionalPersonalNumber ? mainDoc.additionalPersonalNumber : '';
          this.mainDoc.documentType = mainDoc.additionalDocumentType ? mainDoc.additionalDocumentType : '';
          this.mainDoc.documentIssueCountryId = mainDoc.additionalDocumentIssueCountryId ? mainDoc.additionalDocumentIssueCountryId : '';
          this.mainDoc.documentDescription = mainDoc.additionalDocumentDescription;
          this.mainDoc.dateUntilDocument = mainDoc.additionalDateUntilDocument;
          this.addDoc.additionalDocumentNumber = additionalDoc.documentNumber ? additionalDoc.documentNumber : '';
          this.addDoc.additionalPersonalNumber = additionalDoc.personalNumber ? additionalDoc.personalNumber : '';
          this.addDoc.additionalDocumentType = additionalDoc.documentType ? additionalDoc.documentType : '';
          this.addDoc.additionalDocumentIssueCountryId = additionalDoc.documentIssueCountryId ?
            additionalDoc.documentIssueCountryId : '';
          this.addDoc.additionalDocumentDescription = additionalDoc.documentDescription;
          this.addDoc.additionalDateUntilDocument = additionalDoc.dateUntilDocument;

          let mainDocCountry = this.countries.filter((c) => c.id === this.addDoc.additionalDocumentIssueCountryId)[0];
          this.mainDoc.showPersonalNumber = mainDocCountry && mainDocCountry['iso2-code'] === 'ES';
          let addDocCountry = this.countries.filter((c) => c.id === this.mainDoc.documentIssueCountryId)[0];
          this.addDoc.showAdditionalPersonalNumber = addDocCountry && addDocCountry['iso2-code'] === 'ES';
        }
        else{
          mainDoc = doc.mainDocument;
          additionalDoc = doc.additionalDocument;

          /**Swap main VISA document to additional document block*/
          this.mainDoc.documentNumber = mainDoc.documentNumber ? mainDoc.documentNumber : '';
          this.mainDoc.personalNumber = mainDoc.personalNumber ? mainDoc.personalNumber : '';
          this.mainDoc.documentType = mainDoc.documentType ? mainDoc.documentType : '';
          this.mainDoc.documentIssueCountryId = mainDoc.documentIssueCountryId ? mainDoc.documentIssueCountryId : '';
          this.mainDoc.documentDescription = mainDoc.documentDescription;
          this.mainDoc.dateUntilDocument = mainDoc.dateUntilDocument;
          this.addDoc.additionalDocumentNumber = additionalDoc.additionalDocumentNumber ? additionalDoc.additionalDocumentNumber : '';
          this.addDoc.additionalPersonalNumber = additionalDoc.additionalPersonalNumber ? additionalDoc.additionalPersonalNumber : '';
          this.addDoc.additionalDocumentType = additionalDoc.additionalDocumentType ? additionalDoc.additionalDocumentType : '';
          this.addDoc.additionalDocumentIssueCountryId = additionalDoc.additionalDocumentIssueCountryId ?
            additionalDoc.additionalDocumentIssueCountryId : '';
          this.addDoc.additionalDocumentDescription = additionalDoc.additionalDocumentDescription;
          this.addDoc.additionalDateUntilDocument = additionalDoc.additionalDateUntilDocument;

          /**Two flags of Espanol user*/
          let mainDocCountry = this.countries.filter((c) => c.id === this.mainDoc.documentIssueCountryId)[0];
          this.mainDoc.showPersonalNumber = mainDocCountry && mainDocCountry['iso2-code'] === 'ES';
          let addDocCountry = this.countries.filter((c) => c.id === this.addDoc.additionalDocumentIssueCountryId)[0];
          this.addDoc.showAdditionalPersonalNumber = addDocCountry && addDocCountry['iso2-code'] === 'ES';
        }
      }
    }

    if (!this.dateUntilDocument) {
      let currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + 6);
      const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(currentDate);
      const mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(currentDate);
      const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(currentDate);
      this.dateUntilDocument = `${ye}-${mo}-${da}`;
    }
  },

  methods: {
    ...mapActions(['changeDueDiligenceLevel', 'checkTheSameDoc']),
  },
};
</script>
<style src="./modalDateUntilDDLevel.scss" lang="scss" scoped></style>
