<template>
  <div class="body-modal">
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <div class="modal_block__header">
      <p class="modal_block__title">Exchange</p>
      <p class="modal_block__subtitle">Enter target currency and amount for exchange</p>
    </div>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Amount of {{ frCurrency.label }} to exchange</span>
        <imask-input
          v-model="amount"
          class="modal_edit__input"
          autofocus
          :mask="Number"
          radix="."
          :unmask="'typed'"
          :max="max"
        />
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Exchange to currency</span>
        <v-select
          v-model="targetCurrencies[0]"
          placeholder="Pocket currency"
          class="style-chooser"
          :options="targetCurrencies"
          @input="changeCurrency"
        ></v-select>
      </div>
    </div>
    <div class="modal_block__form">
      <span class="modal_block__label">Comment</span>
      <textarea v-model="comment" class="modal_block__input" />
    </div>
    <Button
      title="Exchange"
      size="tight"
      color="orangeFull"
      class="modal_block__btn"
      :disabled="loadExchange || amount === 0 || !comment || comment.length < 5"
      @click="exchange"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import { IMaskComponent } from 'vue-imask';
import vSelect from 'vue-select';
import { createNamespacedHelpers } from 'vuex';
import Http from '@/services/http';

const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'Modal',

  components: {
    Button: Button,
    'imask-input': IMaskComponent,
    'v-select': vSelect,
  },

  props: {
    targetCurrencies: {
      type: Array,
      required: true,
    },
    pockets: {
      type: Array,
      required: true,
    },
    success: {
      type: Function,
      required: true,
    },
  },

  data() {
    let balance = this.getBalance(this.pockets[0].balance);
    return {
      loadExchange: false,
      frCurrency: this.pockets[0],
      toCurrency: null,
      amount: balance,
      pan: '',
      comment: '',
      max: balance,
      selectedTargetCur: this.targetCurrencies[0],
      currencies: [],
    };
  },

  computed: {
    ...mapGetters(['user']),
  },

  created() {},

  methods: {
    ...mapActions(['makeExchange']),

    changeCurrency(curCode) {
      this.selectedTargetCur = curCode;
    },
    getBalance(balance) {
      return balance / 100;
    },

    exchange() {
      this.loadExchange = true;
      this.makeExchange({
        amount: this.amount,
        frCurCode: this.frCurrency.label,
        toCurCode: this.selectedTargetCur,
        comment: this.comment,
        userId: this.user.id,
      })
        .finally(() => {
          this.loadExchange = false;
        })
        .then(() => {
          this.success();
        });
    },
  },
};
</script>

<style src="./modalExchange.scss" lang="scss" scoped></style>
