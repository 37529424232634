<template>
  <main>
    <div class="search">
      <input
        v-model.trim="stmId"
        class="search__input"
        type="text"
        placeholder="Statement Id"
      />
      <v-select
        v-model="statuses"
        multiple
        placeholder="Statement status"
        class="style-chooser-stm"
        :options="statements.statuses"
      ></v-select>
      <Button
        title="Search"
        color="primaryFull"
        size="fit"
        class="button-send"
        @click="$emit('queryChange', currentFilter())"
      />
    </div>
  </main>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
import vSelect from 'vue-select';
import Button from '@/components/Button/Button';

const { mapGetters, mapActions } = createNamespacedHelpers('grow');
export default {
  name: 'SearchStatements',

  components: {
    Button,
    'v-select': vSelect,
  },

  data() {
    return {
      stmId: null,
      statuses: [],
    }
  },

  computed: {
    ...mapGetters(['statements']),
  },

  created() {
    this.$emit('queryChange', this.currentFilter());
  },

  methods: {
    currentFilter(){
      return {
        stmId: this.stmId,
        statuses: this.statuses.map(item => item.id)
      }
    }
  }
}
</script>
<style src="./searchStatements.scss" lang="scss" scoped></style>
<style>
.style-chooser-stm{
  width:350px;
  margin-right: 30px;
}
.style-chooser-stm .vs__search::placeholder{
  line-height: 50px;
  color: #999;
}
.style-chooser-stm .vs__dropdown-toggle,
.style-chooser-stm .vs__dropdown-menu{
  border: 1px solid rgba(215, 222, 224, 0.64);
}
.style-chooser-stm .vs__selected-options{
  min-width: 100px;
  height: 50px;
}
.style-chooser-stm .vs__selected{
  background: white;
  border-color: #7633FF;
  color: #7633FF;
  height: 22px;
}

.style-chooser-stm .vs__deselect{
  fill: #7633FF;
}
</style>
