import Http from '@/services/http';
import {format} from 'date-fns';

const state = {
  typesWL: [
    {id: 'GROW_WHITELIST', label: 'Grow Whitelist'},
    {id: 'GROW_BNPL_WHITELIST', label: 'BNPL Whitelist'},
    {id: 'GROW_REWARDS_WHITELIST', label: 'Rewards Whitelist'},
    {id: 'GROW_BNPL_BLACKLIST', label: 'BNPL Blacklist'},
    {id: 'PLUS_WHITELIST', label: 'Plus Whitelist'},
    {id: 'PLUS_BLACKLIST', label: 'Plus Blacklist'}
  ],
  phonesWL: [],
  filteredPhonesWL: [],
  balances: [],
  filteredBalances: [],
  statements: {
    items: [],
    filter: {},
    hasNextPage: false,
    count: null,
    nextCursor: null,
    statuses: [
      {id: 'initial', label: 'Initial'},
      {id: 'in_progress', label: 'In Progress'},
      {id: 'pending', label: 'Pending'},
      {id: 'repaid', label: 'Repaid'},
      {id: 'missed', label: 'Missed'},
      {id: 'archived', label: 'Archived'}
    ]
  },
  payments: [],
  sortedKey: 'Time',
  sortedDirection: 'desc',
  filter: {},
  hasNextPage: false,
  paymentsCount: null,
  paymentStatuses: [
    {id: 10, label: 'Pending (10)'},
    {id: 90, label: 'Completed (90)'},
    {id: 110, label: 'Declined (110)'},
    {id: 190, label: 'Refunded (190)'}
  ],
  growProducts: {
    cb: 'Credit Builder',
    bnpl: 'Buy Now Pay Later',
    rewards: 'Cashback Program',
    'cb-lite': 'Credit Builder Lite',
    'fee-free': 'Fee-free Transfers',
  }
}

const getters = {
  typesWL: (state) => state.typesWL,
  phonesWL: (state) => state.phonesWL,
  filteredPhonesWL: (state) => state.filteredPhonesWL,
  balances: (state) => state.balances,
  filteredBalances: (state) => state.filteredBalances,
  statements: (state) => state.statements,
  payments: (state) => state.payments,
  hasNextPage: (state) => state.hasNextPage,
  paymentsCount: (state) => state.paymentsCount,
  sortedKey: (state) => state.sortedKey,
  sortedDirection: (state) => state.sortedDirection,
  paymentStatuses: (state) => state.paymentStatuses,
  growProducts: (state) => state.growProducts,
}

const actions = {
  async getPhonesWL({ commit, state }, { type }){
    commit('LOADING_START', null, { root: true });
    let phones = await Http.getGrowUserTest({type}).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });
    commit('PHONES_WL_LOADED',{phones})
  },

  async deletePhoneWl({ commit, state }, { type, phone }){
    commit('LOADING_START', null, { root: true });
    await Http.deleteGrowUserTest({type,phone}).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });
    commit('PHONE_DELETED_FROM_LIST',{phone})
  },

  async addPhoneWl({ commit, state }, { type, phone }){
    commit('LOADING_START', null, { root: true });
    await Http.addGrowUserTest({type,phone}).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });
    commit('PHONE_ADDED_TO_LIST',{phone})
  },

  filterPhones({ commit, state }, { search }){
    commit('FILTER_PHONES',{search})
  },

  async getCollectedBalances({ commit, state }){
    commit('LOADING_START', null, { root: true });
    let balances = await Http.getGrowCollectedBalances().finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });
    commit('COLLECTED_BALANCES_LOADED',{balances})
    commit('FILTER_BALANCES',{isDifferent: true})
  },

  filterBalances({ commit, state }, { isDifferent }){
    commit('FILTER_BALANCES',{isDifferent})
  },

  async getStatements({ commit, state }, { filter, after, nextPage = false }) {
    commit('STATEMENTS_LOAD_START');

    if (!nextPage) {
      commit('LOADING_START', null, { root: true });
    }

    let {
      data: {
        statementsConnection: { pageInfo, edges },
      },
    } = await Http.getStatements({
      filter: filter || {},
      after
    }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('STATEMENTS_LOADED', { edges, pageInfo, filter, nextPage });
  },

  getMoreStatements({ dispatch, state }) {
    if (state.statements.hasNextPage) {
      dispatch('getStatements', {
        filter: state.statements.filter,
        after: state.statements.nextCursor,
        nextPage: true,
      });
    }
  },

  async editStatementInfo({ commit }, { id, info }) {
    await Http.editStatementInfo({
      id: id,
      info: {
        dueDate: format(info.dueDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
        fee: info.fee,
        extraFee: info.extraFee,
        amount: info.amount,
        status: info.status
      },
    });
    commit('STATEMENT_UPDATE_SUCCESS', { info });
  },

  sortedKeyChanged({ commit, state, dispatch }, sortedKey) {
    if (_.includes(['ID', 'Time', 'Status', 'Type'], sortedKey)) {
      if (sortedKey === state.sortedKey) {
        commit('GROW_SORTED_DIRECTION_CHANGED');
      } else {
        commit('GROW_SORTED_DIRECTION_RESETED');
      }

      commit('GROW_SORTED_KEY_CHANGED', sortedKey);
      dispatch('getGrowPayments', {
        filter: state.filter,
        sortedKey,
        nextPage: false,
      });
    }
  },

  getMorePayments({ dispatch, state }) {
    if (state.hasNextPage) {
      dispatch('getGrowPayments', {
        filter: state.filter,
        after: state.nextCursor,
        sortedKey: state.sortedKey,
        nextPage: true,
      });
    }
  },

  async getGrowPayments({ commit, state }, { filter, after, sortedKey, nextPage = false }) {
    commit('GROW_PAYMENTS_LOAD_START');

    if (!nextPage) {
      commit('LOADING_START', null, { root: true });
    }

    let {
      data: {
        paymentsConnection: { pageInfo, edges },
      },
    } = await Http.getPayments({
      filter: filter || {},
      after,
      sortedKey,
      sortedDirection: state.sortedDirection,
    }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('GROW_PAYMENTS_LOADED', { edges, pageInfo, filter, nextPage });
  },
}

const mutations = {
  PHONES_WL_LOADED(state, {phones}){
    state.phonesWL = phones;
    state.filteredPhonesWL = phones;
  },

  FILTER_PHONES(state, {search}){
    state.filteredPhonesWL = state.phonesWL.filter(item => {
      return String(item).indexOf(search) === 0
    });
  },

  PHONE_DELETED_FROM_LIST(state, {phone}){
    state.phonesWL = state.phonesWL.filter(item => item !== phone);
    state.filteredPhonesWL = state.filteredPhonesWL.filter(item => item !== phone);
  },

  PHONE_ADDED_TO_LIST(state, {phone}){
    state.phonesWL.push(phone);
    state.filteredPhonesWL.push(phone);
  },

  COLLECTED_BALANCES_LOADED(state, {balances}){
    state.balances = balances;
    state.filteredBalances = balances;
  },

  FILTER_BALANCES(state, {isDifferent}){
    state.filteredBalances = isDifferent ? state.balances.filter(item => {
      return (item.balance + item.stmtsAmount) !== 0;
    }) : state.balances;
  },

  STATEMENTS_LOAD_START(state) {
    state.statements.count = null;
  },

  STATEMENTS_LOADED(state, { edges, pageInfo, filter, nextPage }) {
    state.statements.hasNextPage = pageInfo.hasNextPage;
    state.statements.count = pageInfo.allCount;
    state.statements.filter = filter;

    if (edges.length) {
      if (nextPage) {
        state.statements.items = state.statements.items.concat(
          edges
            .filter((e) => state.statements.items.map((u) => u.id).indexOf(e.node.id) < 0)
            .map((e) => e.node),
        );
      } else {
        state.statements.items = edges.map((e) => e.node);
      }

      state.statements.nextCursor = _.last(edges).cursor;
    } else {
      if (!nextPage) {
        state.statements.items = [];
      }

      state.statements.nextCursor = null;
    }
  },

  STATEMENT_UPDATE_SUCCESS(state, {info}) {
    let item = state.statements.items.find(item => item.id === info.id);
    item.status = info.status;
    item.amount = info.amount;
    item.lateFee = info.fee;
    item.extraLateFee = info.extraFee;
    item.dueDate = info.dueDate;
  },

  GROW_PAYMENTS_LOAD_START(state) {
    state.paymentsCount = null;
  },

  GROW_PAYMENTS_LOADED(state, { edges, pageInfo, filter, nextPage }) {
    state.hasNextPage = pageInfo.hasNextPage;
    state.paymentsCount = pageInfo.allCount;
    state.filter = filter;

    if (edges.length) {
      if (nextPage) {
        state.payments = state.payments.concat(
          edges
            .filter((e) => state.payments.map((u) => u.id).indexOf(e.node.id) < 0)
            .map((e) => e.node),
        );
      } else {
        state.payments = edges.map((e) => e.node);
      }

      state.nextCursor = _.last(edges).cursor;
    } else {
      if (!nextPage) {
        state.payments = [];
      }

      state.nextCursor = null;
    }
  },

  GROW_SORTED_DIRECTION_CHANGED(state) {
    if (state.sortedDirection === 'asc') {
      state.sortedDirection = 'desc';
    } else {
      state.sortedDirection = 'asc';
    }
  },

  GROW_SORTED_DIRECTION_RESETED(state) {
    state.sortedDirection = 'desc';
  },

  GROW_SORTED_KEY_CHANGED(state, sortedKey) {
    state.sortedKey = sortedKey;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
