<template>
  <div
    v-infinite-scroll="getMorePayments"
    infinite-scroll-disabled="$loading"
    infinite-scroll-distance="20"
    infinite-scroll-throttle-delay="600"
    class="grow-payments"
  >
    <SearchPaymentsGrow
      class="grow-payments__search"
      @queryChange="paymentQuery"
    />
    <p v-if="paymentsCount && !$loading" class="count-p">Payments count: {{ paymentsCount }}</p>
    <div v-if="$loading" class="grow-payments__results">
      <Spinner class="grow-payments__spinner" />
    </div>
    <div
      v-else
      class="grow-payments__results"
    >
      <span v-if="!payments.length" class="grow-payments__noresults">No payments</span>
      <div v-else class="grow-payments__list">
        <div class="grow-payments__label">
          <span
            v-for="key in filters"
            :id="key"
            :key="key"
            class="default"
            :class="[
            key.toLowerCase(),
            {
              sorted__label_asc: sortedKey === key && sortedDirection == 'asc',
              sorted__label_desc: sortedKey === key && sortedDirection == 'desc',
            },
          ]"
            @click="sortedKeyChanged(key)"
          >{{ key }}</span>
        </div>
        <div v-for="(payment, index) in payments" :key="index" class="grow-payments__item">
          <span class="id">{{ payment.id }}</span>
          <span>{{ payment.createdAt | formatTime}}</span>
          <span>{{ payment.dueDate | formatTime}}</span>
          <span class="name">
            <span class="user_link" @click="showUserProfile(payment.fromUserId)">{{
                payment.fromUserName
              }}</span>
          </span>
          <span class="desc">
            {{ payment.typeName }}
            <br />
            {{ payment.fromCategoryName != null ? payment.fromCategoryName : '' }}
            ({{payment.frPrvId }}- {{ payment.toPrvId }})</span>
          <span v-if="payment.fromCurrency" class="from">
            {{ payment.fromAmount | formatMoney(payment.paymentKind, 'from') }}
            {{ payment.fromCurrency.code }}
          </span>
          <span class="to">{{ payment.toAmount | formatMoney(payment.paymentKind, 'to') }}
                {{ payment.toCurrency.code }}</span>
          <span
            class="status"
            style="white-space: nowrap"
            :class="{
                    green: payment.stsId === 90,
                    yellow: payment.stsId < 90,
                  }"
          >{{ payment.stsName ? payment.stsName : '' }} ({{ payment.stsId }})</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import SearchPaymentsGrow from '@/components/SearchPaymentsGrow/SearchPaymentsGrow';

const { mapGetters, mapActions } = createNamespacedHelpers('grow');

export default {
  name: 'GrowPayments',

  components: {
    SearchPaymentsGrow,
    Spinner,
  },

  data() {
    return {
      filters: ['ID', 'Time', 'Due', 'Name', 'Desc','From', 'To', 'Status'],
    };
  },

  computed: {
    ...mapGetters(['payments', 'sortedKey', 'sortedDirection', 'paymentsCount']),
  },

  created() {
  },

  methods: {
    ...mapActions(['getGrowPayments', 'sortedKeyChanged', 'getMorePayments']),

    paymentQuery(filter) {
      this.getGrowPayments({ filter });
    },
    showUserProfile(userId) {
      let routeData = this.$router.resolve({ name: 'users-profile', params: { userId } });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>

<style src="./payments.scss" lang="scss" scoped></style>
