<template>
  <div class="allPayments">
    <p class="allPayments__title">Payments</p>

    <div class="bp__tab">
      <div class="bp__tab_item"
           :class="{active: tab === 'payments'}"
           @click="tab = 'payments'"
      >Payments</div>
      <div class="bp__tab_item"
           :class="{active: tab === 'crypto-payments'}"
           @click="tab = 'crypto-payments'"
      >Crypto payments</div>
    </div>

    <div v-if="tab === 'payments'">
      <SearchPayments
        class="allPayments__search"
        @queryChange="paymentQuery"
      />

      <SearchResultsPayments class="allPayments__results" />
    </div>
    <div v-if="tab === 'crypto-payments'">
      <SearchCryptoPayments
        class="allPayments__search"
        @queryChange="paymentCryptoQuery"
      />
      <SearchResultsCryptoPayments class="allPayments__results" />
    </div>
    <!--    <input-->
    <!--      v-model="paymentQuery"-->
    <!--      placeholder="payment Query"-->
    <!--      type="text"-->
    <!--      @keydown.enter.prevent="getPayments({query: paymentQuery})"-->
    <!--    >-->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SearchResultsPayments from './SearchResultsPayments/SearchResultsPayments';
import SearchPayments from '@/components/SearchPayments/SearchPayments';
import SearchCryptoPayments from '@/components/SearchCryptoPayments/SearchCryptoPayments';
import SearchResultsCryptoPayments
  from '@/views/Payments/SearchResultsCryptoPayments/SearchResultsCryptoPayments';

const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'Payments',

  components: { SearchPayments, SearchResultsPayments, SearchCryptoPayments, SearchResultsCryptoPayments },

  data() {
    return {
      tab: 'payments',
    }
  },

  created() {
    // this.getPayments({filter:{}});
  },

  methods: {
    paymentQuery(filter) {
      this.getPayments({ filter });
    },
    paymentCryptoQuery(filter) {
      this.getCryptoPayments({filter});
    },

    ...mapActions(['getPayments', 'getCryptoPayments']),
  },
};
</script>

<style src="./payments.scss" lang="scss" scoped></style>
