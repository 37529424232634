<!--
size - fit, tight
color - primaryFull, orangeFull, primary, orange
-->

<template>
  <button
    class="default_btn"
    :class="{
      primaryFull: color === 'primaryFull',
      orangeFull: color === 'orangeFull',
      accentFull: color === 'accentFull',
      primary: color === 'primary',
      orange: color === 'orange',
      fit: size === 'fit',
      micro: size === 'micro',
      tight: size === 'tight',
      small: size === 'small',
    }"
    @click="$emit('click')"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'Button',

  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style src="./button.scss" lang="scss" scoped></style>
