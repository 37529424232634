<template>
  <div class="grow">
    <p class="title">Grow</p>
    <div class="grow__navbar">
      <router-link
        class="navbar__label"
        :to="{ name: 'grow-whitelists' }"
      >
        Whitelist/Blacklist
      </router-link>
      <router-link
        class="navbar__label"
        :to="{ name: 'grow-balances' }"
      >
        Balances
      </router-link>
      <router-link
        class="navbar__label"
        :to="{ name: 'grow-statements' }"
      >
        Statements
      </router-link>
      <router-link
        class="navbar__label"
        :to="{ name: 'grow-payments' }"
      >
        Payments
      </router-link>
    </div>

    <router-view />
  </div>
</template>
<script>
export default {
  name: 'Grow',
}
</script>
<style src="./grow.scss" lang="scss" scoped></style>
