<template>
  <div>
    <button class="modal_details__btn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <div class="modal_details__full">
      <p class="modal_details__title">PAYMENT</p>
      <div v-for="(value, name) in addDetails" :key="name">
        <div v-if="value" class="modal_details__row">
          <span class="modal_row__label">{{ name }}</span>
          <div class="modal_row__value">{{ value }}</div>
        </div>
      </div>
      <div v-if="payment" class="modal_details__row">
        <span class="modal_row__label">Payment: </span>
        <div class="modal_row__value">
          <tree-view
            :data="payment"
            :options="{
              maxDepth: 5,
            }"
          />
        </div>
      </div>
      <div v-if="payment.details && Object.keys(payment.details).length" class="modal_details__row">
        <span class="modal_row__label">Details: </span>
        <div class="modal_row__value">
          <tree-view
            :data="payment.details"
            :options="{
              maxDepth: 5,
            }"
          />
        </div>
      </div>

      <div v-if="updating && !isCrypto">
        <Spinner />
      </div>

      <div v-if="extras && Object.keys(extras).length" class="modal_details__row">
        <span class="modal_row__label">Extras: </span>
        <div class="modal_row__value">
          <tree-view
            :data="extras"
            :options="{
              maxDepth: 5,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner/Spinner';
import Http from '@/services/http';

export default {
  name: 'PaymentDetailsModal',

  components: {
    Spinner,
  },

  props: {
    payment: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    addDetails: {
      type: Object,
      required: false,
    },
    isCrypto: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      paymentId: this.payment.id,
      updating: true,
      extras: null,
    };
  },

  created() {
    if (!this.isCrypto){
      this.getExtras(this.paymentId);
    }
  },

  methods: {
    getExtras(payId) {
      this.updating = true;
      Http.getPaymentExtras({
        id: payId,
      })
        .then((data) => {
          this.extras = data;
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>

<style src="./jsonDetailsModal.scss" lang="scss" scoped></style>
