var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user_fraud"},[(!_vm.complianceHistory.length)?_c('div',[_c('Spinner')],1):(_vm.complianceHistory.length)?_c('div',[_c('div',{staticClass:"row__label"},_vm._l((_vm.filters),function(key){return _c('span',{key:key,staticClass:"results__column",class:[
          key.toLowerCase(),
          {
            sorted__label_asc: _vm.sortedKey === key && _vm.sortedDirection == 'asc',
            sorted__label_desc: _vm.sortedKey === key && _vm.sortedDirection == 'desc',
          } ],attrs:{"id":key},on:{"click":function($event){return _vm.sortedKeyChanged({ sortedKey: key })}}},[_vm._v(_vm._s(key))])}),0),_vm._l((_vm.complianceHistory),function(event,id){return _c('div',{key:id,staticClass:"results__row"},[(event.event)?_c('div',{staticClass:"row__wrapper"},[_c('span',{staticClass:"results__column time"},[_vm._v(_vm._s(_vm._f("formatTime")(event.time)))]),_c('span',{staticClass:"results__column event",class:{
            blocked: event.event.toLowerCase().includes('blocked'),
            unblocked: event.event.toLowerCase().includes('unblocked'),
          }},[_vm._v(_vm._s(event.event))]),_c('span',{staticClass:"results__column source"},[_vm._v(_vm._s(event.source || '-'))]),_c('span',{staticClass:"results__column description"},[_vm._v(_vm._s(event.description || '-'))])]):_vm._e()])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }