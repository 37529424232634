<template>
  <main>
    <search-statements
      @queryChange="statementsQuery"
    ></search-statements>
    <search-results-statements></search-results-statements>
  </main>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
import SearchResultsStatements
  from '@/views/Grow/Statements/SearchResultsStatements/SearchResultsStatements';
import SearchStatements from '@/views/Grow/Statements/SearchStatements/SearchStatements';

const { mapGetters, mapActions } = createNamespacedHelpers('grow');

export default {
  name: 'GrowStatements',
  components: {
    SearchStatements,
    SearchResultsStatements,
  },

  methods: {
    ...mapActions(['getStatements']),
    statementsQuery(filter) {
      this.getStatements({ filter });
    },
  }
}
</script>
<style src="./statements.scss" lang="scss" scoped></style>
