export const SINGLE_USER_DATA = `
  id
  extId
  email
  phone
  state
  firstName
  lastName
  dateOfBirth
  avatar
  createdAt
  dueDiligenceLevelExpiresAt
  dueDiligenceLevelStartedAt
  dueDiligenceLevel

  locks {
    id
    name
  }

  blockReasons

  baseCurrency {
    id
    code
    numberCode
    scale
    isActive
    symbol
    title
  }

  dueDiligenceLimit {
    incomeLimit
    cashWithdrawalLimit
    singleCashWithdrawalLimit
    incomeLimitUsed
    cashWithdrawalLimitUsed
  }
`;

export const SINGLE_BASE_PAYMENT_DATA = `
  id
  paymentKind
  title
  type
  state
  createdAt
  description
  stsId
  toAmount
  toCurrency {
    id
    code
    numberCode
    scale
    isActive
    symbol
    title
  }
  fromAmount
  toPrvId
  frPrvId
  fromCurrency {
    id
    code
    numberCode
    scale
    isActive
    symbol
    title
  }
`;

export const SINGLE_FUll_PAYMENT_DATA = `
  id
  externalSmartvistaId
  details

  paymentKind
  title
  type
  state
  createdAt
  completedAt
  description
  location

  fromUserId
  toUserId
  fromUserName
  toUserName

  fxRate
  paysendCard
  external
  authCode
  error
  userFee
  providerFee
  providerTermsCurrency {
    id
    code
    numberCode
    scale
    isActive
    symbol
    title
  }

  deliveryType
  deliveryAddress

  topupCard
  topupBank
  topupType
  topupCountry

  posCardDataInputCapability
  posCardholderAuthenticationCapability
  posCardholderPresenceIndicator
  posCardPresence
  posCardDataInputMode
  posCardholderAuthenticationMethod
  posCardholderAuthenticationEntity
  stsId
  toAmount
  toCurrency {
    id
    code
    numberCode
    scale
    isActive
    symbol
    title
  }
  fromAmount
  toPrvId
  frPrvId
  fromCurrency {
    id
    code
    numberCode
    scale
    isActive
    symbol
    title
  }
`;

export const GRANTS = {
  CREATE_CUSTOM_REFUNDS_TO_CARD: 'CREATE_CUSTOM_REFUNDS_TO_CARD',
  CREATE_PAYMENT_REFUNDS: 'CREATE_PAYMENT_REFUNDS',
  REMOVE_USERS: 'REMOVE_USERS',
  CAN_WATCH_CARD_PAN: 'CAN_WATCH_CARD_PAN',
  CREATE_PAYMENT_CANCEL_EXCHANGE: 'CREATE_PAYMENT_CANCEL_EXCHANGE',
  CREATE_PAYMENT_REFUNDS_ECOM: 'CREATE_PAYMENT_REFUNDS_ECOM',
  RECALC_BALANCES: 'RECALC_BALANCES',
  CREATE_PAYMENT_EXCHANGE: 'CREATE_PAYMENT_EXCHANGE',
  CAN_UNSUBSCRIBE_GROW: 'CAN_UNSUBSCRIBE_GROW',
  CAN_WORK_WITH_GROW_WHITELISTS: 'CAN_WORK_WITH_GROW_WHITELISTS',
  CAN_CHANGE_PAYSENDER_FLAG: 'CAN_CHANGE_PAYSENDER_FLAG',
  CAN_WATCH_PAYSENDER_PAYMENTS: 'CAN_WATCH_PAYSENDER_PAYMENTS',
  CAN_WORK_WITH_GROW_STATEMENTS: 'CAN_WORK_WITH_GROW_STATEMENTS',
};
