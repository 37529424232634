<template>
  <div>
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <p class="modal_block__title">
      {{ title }}
    </p>

    <p class="modal_block__form">
      {{ description }}
    </p>

    <Button
      id="modal_edit__btn"
      title="Confirm"
      size="tight"
      color="primaryFull"
      class="modal_edit__btn"
      @click="click"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import { IMaskComponent } from 'vue-imask';
import { createNamespacedHelpers } from 'vuex';
export default {
  name: 'Modal',

  components: {
    Button: Button,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style src="./modalConfirm.scss" lang="scss" scoped></style>
