<template>
  <div>
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <p class="modal_block__title">
      Update {{ user.firstName }} {{ user.lastName }}'s KYC level <br />({{ level }})
    </p>

    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Document expiry date</span>
        <imask-input
          v-model="dateUntilDocument"
          class="modal_edit__input"
          autofocus
          placeholder="2020-12-25"
          :mask="'0000-00-00'"
        />
      </div>
    </div>

    <div class="modal_block__form">
      <span class="modal_block__label">Description</span>
      <textarea v-model="description" class="modal_block__input" autofocus />
    </div>

    <Button
      id="modal_edit__btn"
      title="Update"
      size="tight"
      color="primaryFull"
      class="modal_edit__btn"
      :disabled="disableButton || !description || description.length < 5"
      @click="handleClick"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import { IMaskComponent } from 'vue-imask';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'Modal',

  components: {
    Button: Button,
    'imask-input': IMaskComponent,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
    dueDiligenceLevel: {
      type: String,
      required: true,
    },
    kycResolvedDocument: {
      type: Object,
      required: false,
      default: null
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      dateUntilDocument: '',
      disableButton: false,
      level: '',
      description: '',
      handleClick: async () => {
        this.disableButton = true;
        try {
          await this.changeDueDiligenceLevel({
            userId: this.user.id,
            dueDiligenceLevel: this.dueDiligenceLevel,
            dateUntilDocument: this.dateUntilDocument,
            description: this.description,
          });
          this.click();
        } catch (error) {
          //console.error(error)
          this.disableButton = false;
        }
      },
    };
  },

  mounted() {
    this.level = this.dueDiligenceLevel;
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 6);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(currentDate);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(currentDate);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate);
    this.dateUntilDocument = `${ye}-${mo}-${da}`;
  },

  methods: {
    ...mapActions(['changeDueDiligenceLevel']),
  },
};
</script>
<style src="./modalDateUntilDDLevel.scss" lang="scss" scoped></style>
