import { extend } from 'vee-validate';
import { required, required_if } from 'vee-validate/dist/rules';
import moment from 'moment';

extend('positive', value => {
  return value >= 0;
});

extend('required', required);

extend('required_if', required_if);

extend('date_after_now', (value) => {
  const result =  moment(value).isAfter(moment());
  return result;
});

extend('document_number_regex', {
  validate: value => {
    const rgx = /[^A-Za-z0-9]/;
    return !rgx.test(value);
  }
});

extend('description_regex', {
  validate: value => {
    const rgx = /[^a-zA-Z0-9@(),.\-; ]/;
    return !rgx.test(value);
  }
});
