<template>
  <div>
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <p class="modal_block__title">Update {{ user.firstName }} {{ user.lastName }}'s phone number</p>

    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Phone number</span>
        <input v-model="userPhone" class="modal_edit__input" autofocus />
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Email</span>
        <input v-model="email" class="modal_edit__input" autofocus />
      </div>
    </div>
    <Button
      id="modal_edit__btn"
      title="Update"
      size="tight"
      color="primaryFull"
      class="modal_edit__btn"
      @click="click({ phone: userPhone, email: email })"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';

export default {
  name: 'Modal',

  components: { Button },

  props: {
    user: {
      type: Object,
      required: true,
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      userPhone: '',
      email: '',
    };
  },

  mounted() {
    this.userPhone = this.user.phone;
    this.email = this.user.email;
  },
};
</script>

<style src="./modalEditUserPhone.scss" lang="scss" scoped></style>
