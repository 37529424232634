var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',[_c('button',{staticClass:"modal_block__closebtn",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/img/close.svg")}})]),(_vm.kycResolvedDocument != null && _vm.kycResolvedDocument.documentNumber != null)?_c('p',{staticClass:"modal_block__title_warning"},[_vm._v(" Attention, "+_vm._s(_vm.kycResolvedDocument.source)+" document exists ")]):_vm._e(),_c('p',{staticClass:"modal_block__title"},[_vm._v(" Update "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+"'s KYC level "),_c('br'),_vm._v("("+_vm._s(_vm.level)+") ")]),_c('div',{staticClass:"modal_block__form"},[_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Document type")]),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-select',{staticClass:"modal_edit__select style-chooser",class:{ modal_edit__select__error: invalid },attrs:{"options":_vm.docTypes,"reduce":function (docType) { return docType.type; },"label":"text","autofocus":""},model:{value:(_vm.mainDoc.documentType),callback:function ($$v) {_vm.$set(_vm.mainDoc, "documentType", $$v)},expression:"mainDoc.documentType"}})]}}],null,true)})],1)]),_c('div',{staticClass:"flex_block"},[_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Document number")]),_c('ValidationProvider',{attrs:{"rules":"document_number_regex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('imask-input',{staticClass:"modal_edit__input",class:{ modal_edit__error: invalid },model:{value:(_vm.mainDoc.documentNumber),callback:function ($$v) {_vm.$set(_vm.mainDoc, "documentNumber", $$v)},expression:"mainDoc.documentNumber"}}),(invalid)?_c('span',{staticClass:"modal_edit__error_doc_number"},[_vm._v(" Only EN letters and digits without whitespaces! ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Date of expiration")]),_c('ValidationProvider',{attrs:{"rules":"date_after_now"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mainDoc.dateUntilDocument),expression:"mainDoc.dateUntilDocument"}],staticClass:"modal_edit__input",class:{ modal_edit__error: invalid },attrs:{"type":"date"},domProps:{"value":(_vm.mainDoc.dateUntilDocument)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mainDoc, "dateUntilDocument", $event.target.value)}}}),(invalid)?_c('span',{staticClass:"modal_edit__error_doc_number"},[_vm._v(" Check the input date ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"modal_block__form"},[_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Issuing country")]),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-select',{staticClass:"style-chooser modal_edit__select",class:{ modal_edit__select__error: invalid },attrs:{"options":_vm.countries,"reduce":function (country) { return country.id; },"label":"title"},on:{"input":_vm.countryChangedMainDoc},model:{value:(_vm.mainDoc.documentIssueCountryId),callback:function ($$v) {_vm.$set(_vm.mainDoc, "documentIssueCountryId", $$v)},expression:"mainDoc.documentIssueCountryId"}})]}}],null,true)})],1)]),_c('div',{staticClass:"modal_block__form"},[(_vm.mainDoc.showPersonalNumber)?_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Personal number")]),_c('imask-input',{staticClass:"modal_edit__input",model:{value:(_vm.mainDoc.personalNumber),callback:function ($$v) {_vm.$set(_vm.mainDoc, "personalNumber", $$v)},expression:"mainDoc.personalNumber"}})],1):_vm._e()]),_c('div',{staticClass:"modal_block__form"},[_c('span',{staticClass:"modal_block__label"},[_vm._v("Description")]),_c('ValidationProvider',{attrs:{"rules":"description_regex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.mainDoc.documentDescription),expression:"mainDoc.documentDescription"}],staticClass:"modal_block__input",attrs:{"autofocus":""},domProps:{"value":(_vm.mainDoc.documentDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mainDoc, "documentDescription", $event.target.value)}}}),_vm._v(" "),(invalid)?_c('span',{staticClass:"modal_block__error_description"},[_vm._v("Only EN letters, numbers, whitespaces and symbols as @(),.-; are accepted!")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"modal_block__title"},[_c('label',{attrs:{"for":"checkbox"}},[_vm._v("Additional document ")])]),_c('div',{staticClass:"modal_block__form"},[_c('div',{staticClass:"modal_block__form"},[_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Document type")]),_c('v-select',{staticClass:"modal_edit__select style-chooser",attrs:{"options":_vm.additionalDocTypes,"reduce":function (additionalDocTypes) { return additionalDocTypes.type; },"label":"text"},model:{value:(_vm.addDoc.additionalDocumentType),callback:function ($$v) {_vm.$set(_vm.addDoc, "additionalDocumentType", $$v)},expression:"addDoc.additionalDocumentType"}})],1)]),_c('div',{staticClass:"flex_block"},[_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Document number")]),_c('ValidationProvider',{attrs:{"rules":"document_number_regex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('imask-input',{staticClass:"modal_edit__input",class:{ modal_edit__error: invalid },model:{value:(_vm.addDoc.additionalDocumentNumber),callback:function ($$v) {_vm.$set(_vm.addDoc, "additionalDocumentNumber", $$v)},expression:"addDoc.additionalDocumentNumber"}}),(invalid)?_c('span',{staticClass:"modal_edit__error_doc_number"},[_vm._v(" Only EN letters and digits without whitespaces! ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"flex_item_block"},[_c('ValidationProvider',{attrs:{"rules":"date_after_now"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('span',{staticClass:"modal_edit__label"},[_vm._v("Date of expiration")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addDoc.additionalDateUntilDocument),expression:"addDoc.additionalDateUntilDocument"}],staticClass:"modal_edit__input",class:{ modal_edit__error: invalid },attrs:{"type":"date"},domProps:{"value":(_vm.addDoc.additionalDateUntilDocument)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addDoc, "additionalDateUntilDocument", $event.target.value)}}}),(invalid)?_c('span',{staticClass:"modal_edit__error_doc_number"},[_vm._v("Check the input date")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"modal_block__form"},[_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Issuing country")]),_c('v-select',{staticClass:"style-chooser modal_edit__select",attrs:{"options":_vm.countries,"reduce":function (country) { return country.id; },"label":"title"},on:{"input":_vm.countryChangedAdditionalDoc},model:{value:(_vm.addDoc.additionalDocumentIssueCountryId),callback:function ($$v) {_vm.$set(_vm.addDoc, "additionalDocumentIssueCountryId", $$v)},expression:"addDoc.additionalDocumentIssueCountryId"}})],1)]),_c('div',{staticClass:"modal_block__form"},[(_vm.addDoc.showAdditionalPersonalNumber)?_c('div',{staticClass:"flex_item_block"},[_c('span',{staticClass:"modal_edit__label"},[_vm._v("Personal number")]),_c('imask-input',{staticClass:"modal_edit__input",model:{value:(_vm.addDoc.additionalPersonalNumber),callback:function ($$v) {_vm.$set(_vm.addDoc, "additionalPersonalNumber", $$v)},expression:"addDoc.additionalPersonalNumber"}})],1):_vm._e()]),_c('div',{staticClass:"modal_block__form"},[_c('ValidationProvider',{attrs:{"rules":"description_regex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.addDoc.additionalDocumentDescription),expression:"addDoc.additionalDocumentDescription"}],staticClass:"modal_block__input",attrs:{"autofocus":""},domProps:{"value":(_vm.addDoc.additionalDocumentDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addDoc, "additionalDocumentDescription", $event.target.value)}}}),_vm._v(" "),(invalid)?_c('span',{staticClass:"modal_block__error_description"},[_vm._v("Only EN letters, numbers, whitespaces and symbols as @(),.-; are accepted!")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"modal_block__form",class:{modal_block__last_desc: !_vm.isNotKycLvlTwo(_vm.user)}},[(_vm.isNotKycLvlTwo(_vm.user))?_c('div',{staticClass:"dropdown_block"},[_c('span',{staticClass:"modal_block__dropdown"},[_vm._v("Change the KYC level to 2")]),_c('v-select',{staticClass:"modal_edit__select__dropdown style-chooser",attrs:{"default-open":"","placeholder":"Please select the option","options":_vm.changeKycLevelList,"reduce":function (changeKycLevelList) { return changeKycLevelList.type; },"label":"text","autofocus":""},on:{"input":_vm.kycChange},model:{value:(_vm.changeKycLevelFlag),callback:function ($$v) {_vm.changeKycLevelFlag=$$v},expression:"changeKycLevelFlag"}})],1):_vm._e()])]),_c('Button',{staticClass:"modal_edit__btn",attrs:{"type":"submit","id":"modal_edit__btn","title":"Update","size":"tight","color":"primaryFull","disabled":invalid || _vm.disableButton || !_vm.mainDoc.documentType || !_vm.mainDoc.documentNumber
        || _vm.mainDoc.documentNumber.length < 4 || !_vm.mainDoc.documentIssueCountryId
        || (_vm.needToChangeKycLevel === '' && _vm.isNotKycLvlTwo(_vm.user))},on:{"click":_vm.handleClick}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }