<template>
  <main class="results__wrapper">
    <modals-container />
    <div v-if="$loading" class="cards__results">
      <Spinner class="cards__spinner" />
    </div>

    <div v-else class="cards__results">
      <span v-if="!cards.length" class="cards__noresults">No cards</span>

      <div v-else class="cards__list">
        <div class="cards__label">
          <span class="label__default id">ID</span>
          <!--          <span class="label__default name">Name</span>-->
          <span class="label__default">Number</span>
          <span class="label__default type">Type</span>
          <span class="label__default">State</span>
          <span class="label__default ext-wallets">Wallets</span>
          <span class="label__default">Created</span>
          <span class="label__default">Deleted</span>
        </div>

        <!-- eslint-disable-next-line -->
        <div v-for="(card, index) in cards" :key="index" class="cards__item">
          <span class="id">{{ card.extId }}</span>
          <!--          <span class="name">{{ card.name }}</span>-->
          <span class="number">{{ card.showPan ? card.pan : card.number }}
            <img
              v-if="!loadPan && !card.showPan && showButtonEye()"
              src="~@/assets/img/eye.png"
              class="icon"
              @click="getPan(card)"
            />
          </span>
          <span class="type">{{ card.type }}</span>
          <span>{{ card.state }}</span>
          <span class="ext-wallets">
            <p v-for="(wallet, ind) in card.extWallets" :key="ind">{{ wallet }}</p>
            <p v-if="card.extWallets == null || card.extWallets.length === 0">-</p>
          </span>
          <span>{{ card.createdTs | formatTime }}</span>
          <span>{{
            (card.deletedTs != null || card.state !== 'removed' ? card.deletedTs : card.updatedTs)
              | formatTime
          }}</span>

          <Button
            v-if="showRemove(card.state)"
            class="btn_right max"
            title="Remove"
            color="orangeFull"
            size="small"
            @click="remove(card)"
          />

          <Button
            v-if="['active', 'frozen_by_system', 'frozen_by_user'].includes(card.state)"
            class="btn_right max"
            :title="card.state === 'frozen_by_system' ? 'Unfreeze' : 'Freeze'"
            :color="card.state === 'frozen_by_system' ? 'primary' : 'primaryFull'"
            size="small"
            @click="card.state === 'frozen_by_system' ? unfreeze(card) : freeze(card)"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import Button from '@/components/Button/Button';
import ModalReason from '@/components/ModalReason/ModalReason';
import Http from '@/services/http';
import { GRANTS } from '@/services/const';

const { mapGetters, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'Cards',
  components: { Button, Spinner },

  data() {
    return {
      loadPan: false,
    };
  },

  computed: {
    ...mapGetters(['cards']),
  },

  created() {
    this.getCards({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getCards', 'blockCard', 'freezeCard', 'unfreezeCard', 'getCardPan']),
    showRemove(state) {
      return [
        'active',
        'pending_generation',
        'pending_activation',
        'frozen_by_user',
        'active_without_pin',
      ].includes(state);
    },

    remove(card) {
      this.$modal.show(
        ModalReason,
        {
          title: 'Blocking without the ability to unlock',
          titleBtn: 'Block',
          click: (message) => {
            this.blockCard({
              cardId: card.id,
              reason: message,
            });
            this.$modal.hide('removeCardModal');
          },
        },
        {
          name: 'removeCardModal',
          height: '400',
        },
      );
    },

    freeze(card) {
      this.$modal.show(
        ModalReason,
        {
          title: 'Freezing card',
          titleBtn: 'Freeze',
          click: (message) => {
            this.freezeCard({
              cardId: card.id,
              reason: message,
            });
            this.$modal.hide('freezeCardModal');
          },
        },
        {
          name: 'freezeCardModal',
          height: '400',
        },
      );
    },

    unfreeze(card) {
      this.$modal.show(
        ModalReason,
        {
          title: 'Unfreezing card',
          titleBtn: 'Unfreeze',
          click: (message) => {
            this.unfreezeCard({
              cardId: card.id,
              reason: message,
            });
            this.$modal.hide('unfreezeCardModal');
          },
        },
        {
          name: 'unfreezeCardModal',
          height: '400',
        },
      );
    },

    getPan(card) {
      this.getAsyncCardPan(card.id);
    },

    async getAsyncCardPan(cardId) {
      this.loadPan = true;
      await this.getCardPan({ cardId });
      this.loadPan = false;
    },

    showButtonEye() {
      return this.$hasGrant(GRANTS.CAN_WATCH_CARD_PAN);
    },
  },
};
</script>

<style src="./cards.scss" lang="scss" scoped></style>
