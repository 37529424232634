<template>
  <div class="grow__list">
    <div class="grow__label">
      <span class="label__default">Product</span>
      <span class="label__default">Available</span>
      <span class="label__default">Allow for user</span>
      <span class="label__default">User is blocked</span>
      <span class="label__default">Reason</span>
    </div>
    <div v-for="(product, index) in products" :key="index" class="grow__item">
            <span>{{ growProducts[product.product] || product.product }}
              <p v-if="hasProduct(product.product)" class="opacity text-success uppercase">enabled</p>
              <p v-if="!hasProduct(product.product)" class="opacity text-fail uppercase">disabled</p>
            </span>
      <span>{{ product.available ? 'Yes' : 'No' }}</span>
      <span>{{ product.allowed ? 'Yes' : 'No' }}</span>
      <span>{{ product.blocked ? 'Yes' : 'No' }}</span>
      <span>{{ product.message }}</span>

      <Button
        v-if="hasProduct(product.product) && showCancelButton"
        class="btn_right max"
        title="Disable"
        color="orangeFull"
        size="small"
        @click="$emit('cancel',[product.product])"
      />
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import ModalReason from '@/components/ModalReason/ModalReason';
import Button from '@/components/Button/Button';
import {GRANTS} from '@/services/const';

const { mapGetters, mapActions } = createNamespacedHelpers('user');
const grow = createNamespacedHelpers('grow');

export default {
  name: 'GrowProducts',
  components: {
    Button,
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    userProducts: {
      type: Array,
      required: true,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
    }
  },

  computed: {
    ...grow.mapGetters(['growProducts'])
  },

  methods: {
    hasProduct(product) {
      return !!this.userProducts && this.userProducts.indexOf(product) >= 0;
    }
  }
}
</script>

<style src="../userGrow.scss" lang="scss" scoped></style>
