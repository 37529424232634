<template>
  <div class="balances">
    <p class="title">Balances</p>
    <div v-if="updating && !report">
      <Spinner />
    </div>
    <div v-else-if="report" class="balance_report">
      <div class="balance_table">
        <p class="balance_table__title title_now">
          Balances for {{ report.balance_date | moment('dddd, MMMM Do YYYY, h:mm:ss a') }}
        </p>
        <div class="balance_table__row">
          <span class="balance_table__col1 balance_table__label">Currency</span>
          <span class="balance_table__col2 balance_table__label">Balance</span>
        </div>
        <div
          v-for="balance in report.balances"
          :key="balance.currency.id"
          class="balance_table__row"
        >
          <div class="balance_table__col1">
            {{ balance.currency.code }}
          </div>
          <div class="balance_table__col2">
            {{ balance.display_amount }}
          </div>
        </div>
      </div>

      <div class="balance_table">
        <p class="balance_table__title">VARIANCE FOR PERIOD</p>
        <DateTimeInput :value="dates" @input="changeValue" />
        <!--        <p class="balance_table__subtitle">-->
        <!--          <span>From&nbsp;</span>-->
        <!--          <datetime-->
        <!--            v-model="varianceFrom"-->
        <!--            type="datetime"-->
        <!--            format="dd LLL yyyy HH:mm ZZZZ"-->
        <!--            input-class="picker"-->
        <!--            @close="updateReport"-->
        <!--          />-->
        <!--          <span>to&nbsp;</span>-->
        <!--          <datetime-->
        <!--            v-model="varianceTo"-->
        <!--            type="datetime"-->
        <!--            format="dd LLL yyyy HH:mm ZZZZ"-->
        <!--            input-class="picker"-->
        <!--            @close="updateReport"-->
        <!--          />-->
        <!--        </p>-->
        <div v-if="updating">
          <Spinner />
        </div>
        <div v-else-if="!updating">
          <div class="balance_table__row">
            <span class="balance_table__col1 balance_table__label">Currency</span>
            <span class="balance_table__col2 balance_table__label">VARIANCE</span>
          </div>
          <div
            v-for="variance in report.variance"
            :key="variance.currency.id"
            class="balance_table__row"
          >
            <div class="balance_table__col1">
              {{ variance.currency.code }}
            </div>
            <div class="balance_table__col2">
              {{ variance.variance }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/http';
import Spinner from '@/components/Spinner/Spinner';

import { startOfTomorrow, startOfYesterday } from 'date-fns';
import DateTimeInput from '@/components/DateTimeInput/DateTimeInput';

let today = startOfTomorrow();
let yesterday = startOfYesterday();

export default {
  name: 'Balances',

  components: {
    DateTimeInput,
    Spinner,
  },

  data() {
    return {
      report: null,
      balanceDate: null,
      varianceFrom: yesterday.toISOString(),
      varianceTo: today.toISOString(),
      dates: [yesterday, today],
      updating: true,
    };
  },

  created() {
    this.updateReport();
  },

  methods: {
    updateReport() {
      this.updating = true;
      Http.getCurrencyReport({
        balanceDate: this.balanceDate,
        varianceFrom: this.varianceFrom,
        varianceTo: this.varianceTo,
      })
        .then((report) => {
          this.report = report.data.systemCurrencyBalanceReport;
        })
        .finally(() => {
          this.updating = false;
        });
    },

    changeValue(e) {
      if (e && e instanceof Array) {
        this.varianceFrom = e[0].toISOString();
        this.varianceTo = e[1].toISOString();
      } else {
        this.varianceFrom = null;
        this.varianceTo = null;
      }
      this.updateReport();
    },
  },
};
</script>

<style src="./balances.scss" lang="scss" scoped></style>
