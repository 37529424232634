import axios from 'axios';
import config from '@/config';
import router from '@/router';

const state = {
  token: localStorage.getItem('token'),
  user: localStorage.getItem('user'),
  grants: localStorage.getItem('grants'),
  loading: false,
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  user: (state) => state.user,
  grants: (state) => state.grants,
  loading: (state) => state.loading,
};

const actions = {
  async login({ commit }, { email, password }) {
    try {
      let { data } = await axios({
        url: config.LOGIN_URL,
        data: { email, password },
        method: 'POST',
      });

      const token = data.auth_token;
      const user = data.full_name;
      const grants = data.user_grants;

      commit('AUTH_SUCCESS', { token, user, grants });
    } catch (error) {
      commit('AUTH_ERROR', error);
    }
  },

  logout({ commit }) {
    commit('LOGOUT');
  },
};

const mutations = {
  LOADING_START(state) {
    state.loading = true;
  },

  LOADING_STOP(state) {
    state.loading = false;
  },

  AUTH_SUCCESS(state, { token, user, grants }) {
    state.token = token;
    state.user = user;
    if ((typeof grants) === 'string') {
      state.grants = grants;
    } else {
      state.grants = '';
    }

    localStorage.setItem('token', token);
    localStorage.setItem('user', user);
    localStorage.setItem('grants', grants);

    let target = localStorage.getItem('url_target');
    router.push({ name: 'users' });

    // gryazniy hack
    setTimeout(() => {
      window.location.href = target || '/payments';
      localStorage.removeItem('url_target');
    }, 200);
  },

  AUTH_ERROR(state, error) {
    state.token = null;
    localStorage.removeItem('token');
    alert(error);
  },

  LOGOUT(state) {
    state.user = '';
    state.token = '';

    if (window.location.href && !window.location.href.endsWith('/login')) {
      localStorage.setItem('url_target', window.location.href);
    }
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('grants');

    router.push({ name: 'login' });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
