<template>
  <div v-if="user" class="users profile">
    <div class="profile__header">
      <a href="#" @click.prevent="$router.push({ name: namespace, query: { query } })">
        <img src="~@/assets/img/back.svg" class="header__backArrow" />
      </a>
      <p class="header__title">
        {{ user.firstName }} {{ user.lastName }} ·
        <span class="header__status" v-bind:class="{ blocked: user.state === 'blocked' }">{{ user.state }}</span>
        <span v-if="user.sbBlocks.length && user.state !== 'blocked'" class="header__status blocked"> (has anti-fraud restrictions)</span>
      </p>
      <div class="header__userid">
        <div class="userid__id">User ID: {{ user.extId }} ({{ this.$route.params.userId }})</div>
        <div v-if="user.isPaysender" class="userid__splitter">·</div>
        <div v-if="user.isPaysender" class="userid__paysender"><img src="~@/assets/img/logo_icon.svg" /><span>Paysender</span></div>
      </div>

    </div>
    <div class="user__navbar">
      <router-link
        class="navbar__label"
        :to="{ name: namespace + '-profile', params: { userId: this.$route.params.userId } }"
      >
        Profile
      </router-link>

      <router-link v-if="!user.isPaysender || canWatchPaysenderPayments()" class="navbar__label" :to="{ name: 'users-balance-and-payments' }">
        Balance & payments
      </router-link>

      <router-link
        class="navbar__label"
        :to="{ name: namespace + '-cards', params: { userId: this.$route.params.userId } }"
      >
        Cards
      </router-link>

      <router-link
        v-if="!user.child"
        class="navbar__label"
        :to="{ name: namespace + '-kyc', params: { userId: this.$route.params.userId } }"
      >
        KYC(Onfido)
      </router-link>

      <router-link class="navbar__label" :to="{ name: namespace + '-fraud-and-compliance' }">
        Fraud & Compliance
      </router-link>

      <router-link class="navbar__label" :to="{ name: namespace + '-plus' }"> Plus </router-link>
    </div>

    <router-view />
  </div>
</template>

<script>
import { GRANTS } from '@/services/const';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'Profile',

  props: {
    namespace: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState(['user', 'kycResolvedDocument']),

    query() {
      return this.$store.state[this.namespace].query;
    },
  },

  created() {
    this.getUser(this.$route.params.userId);
    this.getKycResolvedDocument({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getUser', 'getKycResolvedDocument']),

    canWatchPaysenderPayments() {
      return this.$hasGrant(GRANTS.CAN_WATCH_PAYSENDER_PAYMENTS);
    }
  },
};
</script>

<style src="./user.scss" lang="scss" scoped></style>
