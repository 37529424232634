<template>
  <main>
    <div v-if="$loading" class="grow__results">
      <Spinner class="grow__spinner" />
    </div>
    <div v-else class="grow__results">
      <h2>Statements</h2>
      <span v-if="!growStatements.length" class="grow__noresults">User doesn't have statements</span>
      <div v-else class="grow__list">
        <div class="grow__label">
          <span class="label__default stmId">Product</span>
          <span class="label__default">Status</span>
          <span class="label__default">Amount</span>
          <span class="label__default">Currency</span>
          <span class="label__default">Repayment</span>
        </div>
        <div v-for="(statement, index) in growStatements" :key="index">
          <div v-if="countStatements > index" class="grow__item">
            <span class="stmId">
              <p class="opacity">
                From {{ statement.startPeriod | formatTime }} to
                {{ statement.endPeriod | formatTime }}
              </p>
              {{ growProducts[statement.product] || statement.product }}
              <p class="opacity">{{ statement.id }}</p>
            </span>
            <span>{{ statement.status }}
              <p class="opacity">Due: {{ statement.dueDate | formatTime }}</p>
              <p v-if="!!statement.blockDate" class="opacity">
                Block: {{ statement.blockDate | formatTime }}
              </p>
            </span>
            <span>Repaid {{ statement.repaid | formatMoneyNotDivide }} of
              {{ statement.amount | formatMoneyNotDivide }}
              <p class="opacity">Fee: {{ statement.fee | formatMoneyNotDivide }}</p>
              <p class="opacity">Extra Fee: {{ statement.extraFee | formatMoneyNotDivide }}</p>
            </span>
            <span>GBP</span>
            <span v-if="!statement.repaymentId">None</span>
            <span v-else>
              Pay Id: {{ statement.repaymentId }}
              <p class="opacity">Sts: {{ statement.paymentStatus }}</p>
            </span>
          </div>
        </div>
        <br/>
        <p class="text-center">
          <a v-if="countStatements > 5"
             class="link"
             @click="lessStatements"
          >Less</a>
          <a v-if="countStatements < growStatements.length"
             class="link"
             @click="moreStatements"
          >More</a>
        </p>
      </div>
    </div>
  </main>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
import Spinner from '@/components/Spinner/Spinner';

const { mapGetters, mapActions } = createNamespacedHelpers('user');
const grow = createNamespacedHelpers('grow');

export default {
  name: 'GrowStatements',
  components: {
    Spinner
  },

  data() {
    return {
      countStatements: 5,
    };
  },

  computed: {
    ...mapGetters(['growStatements']),
    ...grow.mapGetters(['growProducts'])
  },

  created() {
    this.getGrowStatements({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getGrowStatements']),
    moreStatements(){
      this.countStatements += 5;
    },

    lessStatements(){
      this.countStatements -= 5;
    },
  }
}
</script>

<style src="./growStatements.scss" lang="scss" scoped></style>
