var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[(_vm.$loading)?_c('div',{staticClass:"grow__results"},[_c('Spinner',{staticClass:"grow__spinner"})],1):_c('div',{staticClass:"grow__results"},[_c('div',{staticClass:"row__label"},_vm._l((_vm.filters),function(key){return _c('span',{key:key,staticClass:"results__column",class:[
          key.toLowerCase()
        ],attrs:{"id":key}},[_vm._v(_vm._s(key))])}),0),(!_vm.plusSubscriptionPayments.length)?_c('span',{staticClass:"grow__noresults"},[_vm._v("No payments")]):_c('div',[_c('BulmaAccordion',{attrs:{"icon":'custom',"slide":{
          duration: '.4s',
          timerFunc: 'ease',
        }}},_vm._l((_vm.plusSubscriptionPayments),function(payment){return _c('div',{key:payment.payId},[_c('BulmaAccordionItem',{on:{"open":function($event){return _vm.openItem(payment.payId)}}},[_c('div',{staticClass:"row__wrapper",class:{
                grey: payment.stsId > 90,
              },attrs:{"slot":"title","id":payment.payId},slot:"title"},[_c('span',{staticClass:"results__column id"},[_vm._v(_vm._s(payment.payId))]),_c('span',{staticClass:"results__column"},[_vm._v(_vm._s(_vm._f("formatTime")(payment.payTs)))]),_c('span',{staticClass:"results__column type type__two-lines"},[_c('p',[_vm._v("From "+_vm._s(payment.frPrvName)+" ("+_vm._s(payment.frPrvId)+")")]),_c('p',[_vm._v("To "+_vm._s(payment.toPrvName)+" ("+_vm._s(payment.toPrvId)+")")])]),_c('span',{staticClass:"results__column description"},[_vm._v(" "+_vm._s(payment.title)+" "),_c('p',{staticClass:"opacity"},[_vm._v(_vm._s(payment.comment))])]),_c('span',{staticClass:"results__column"},[_vm._v(_vm._s(_vm._f("formatMoneyNotDivide")(payment.frAmtReal))+" "+_vm._s(payment.frCurIso))]),_c('span',{staticClass:"results__column"},[_vm._v(_vm._s(_vm._f("formatMoneyNotDivide")(payment.toAmtReal))+" "+_vm._s(payment.toCurIso))]),_c('span',{staticClass:"results__column status",class:{
                  green: payment.stsId === 90,
                  yellow: payment.stsId < 90,
                },staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(payment.stsName ? payment.stsName : '')+" "),_c('br'),_vm._v(" ("+_vm._s(payment.stsId)+")")])]),_c('div',{staticClass:"showDet",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"generalDet"},[(payment.payId === _vm.opened)?_c('UserPlusPaymentExtra',{attrs:{"pay-id":payment.payId}}):_vm._e()],1)])])],1)}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }