<template>
  <div class="topups">
    <p class="topups__title">Top-ups</p>

    <SearchPayments class="topups__search" :topup="true" @queryChange="paymentQuery" />

    <SearchResultsPayments class="topups__results" />

    <!--    <input-->
    <!--      v-model="paymentQuery"-->
    <!--      placeholder="payment Query"-->
    <!--      type="text"-->
    <!--      @keydown.enter.prevent="getPayments({query: paymentQuery})"-->
    <!--    >-->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SearchResultsPayments from '../Payments/SearchResultsPayments/SearchResultsPayments';
import SearchPayments from '@/components/SearchPayments/SearchPayments';

const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'Topups',

  components: { SearchPayments, SearchResultsPayments },

  created() {
    // this.getPayments({ query: '', topups: true });
  },

  methods: {
    paymentQuery(filter) {
      this.getPayments({ filter: filter });
    },

    ...mapActions(['getPayments']),
  },
};
</script>

<style src="./topups.scss" lang="scss" scoped></style>
