<template>
  <main>
    <div v-if="plusUserPaymentExtraLoading">
      <Spinner class="grow__spinner" />
    </div>
    <div v-else>
      <div class="detBlock">
        <div
          v-for="(value, index) in plusUserPaymentExtra"
          :key="index"
          class="detBlock__item"
        >
          <span class="detBlock__label">{{ extLabel(value.label) }}: </span>
          <span class="detBlock__value">{{ value.value }}</span>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import {createNamespacedHelpers} from 'vuex';
import Spinner from '@/components/Spinner/Spinner';

const { mapGetters, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'UserPlusPaymentExtra',

  components: {
    Spinner,
  },

  props: {
    payId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      paymentId: this.payId,
    }
  },

  computed: {
    ...mapGetters(['plusUserPaymentExtraLoading', 'plusUserPaymentExtra']),
  },

  created() {
    this.getPlusSubscriptionPaymentExtra({ payId: this.paymentId });
  },

  methods: {
    ...mapActions(['getPlusSubscriptionPaymentExtra']),
    extLabel(extName){
      switch (extName){
        case 'ga__payment_id': return 'GA Payment ID';
        case 'ga__user_id': return 'GA User ID';
        case 'ga__sts_id': return 'GA Payment Status';
        case 'plus__error_id': return 'Error';
        case 'plus__subscription_plan': return 'Subscription Plan';
        case 'plus__sub_due_date': return 'Due Date';
        case 'plus__payment_next_date': return 'Due of Next Payment';
        case 'plus__card_pan': return 'Card Number';
        case 'plus__card_cardholder': return 'Card Holder';
        case 'plus__next_payment_id': return 'Next Payment ID';
        case 'business__payment_order_id': return 'Business Payment ID';
        case 'business__payment_state': return 'Business Payment Status';
        case 'business__payment_payment_method': return 'Business Payment Method';
        case 'business__payment_next_date': return 'Business Next Due Date';
        case 'business__payment_created': return 'Business Created Date';
        case 'business__payment_reference': return 'Business Payment Ref';
        case 'business__state_description': return 'Business Status Desc';
        case 'business__error_id': return 'Business Error ID'
        default:
          return extName;
      }
    }
  }

}
</script>

<style src="./userPlusPaymentExtra.scss" lang="scss" scoped></style>
