var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"default_btn",class:{
    primaryFull: _vm.color === 'primaryFull',
    orangeFull: _vm.color === 'orangeFull',
    accentFull: _vm.color === 'accentFull',
    primary: _vm.color === 'primary',
    orange: _vm.color === 'orange',
    fit: _vm.size === 'fit',
    micro: _vm.size === 'micro',
    tight: _vm.size === 'tight',
    small: _vm.size === 'small',
  },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.title)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }