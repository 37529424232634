<template>
  <div>
    <div class="search">
      <DateTimeInput :value="dates"
                     @input="changeValue"
      />
      <v-select v-if="currencies.length"
                v-model="currency"
                placeholder="Pocket"
                class="style-chooser"
                :options="currencies"
      ></v-select>
      <Button
        title="Search"
        color="primaryFull"
        size="fit"
        class="button-send"
        @click="$emit('queryChange', currentFilter())"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Button from '@/components/Button/Button';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {startOfYesterday,startOfTomorrow} from 'date-fns';
import DateTimeInput from '@/components/DateTimeInput/DateTimeInput';

const { mapGetters, mapActions } = createNamespacedHelpers('user');

let today = startOfTomorrow();
let yesterday = startOfYesterday();

export default {
  name: 'SearchCryptoPayments',

  components: {
    DateTimeInput,
    Button,
    'v-select': vSelect
  },

  props: {
    user:{
      type: String,
      required: false,
      default:null
    }
  },

  data() {
    return {
      userId: this.user,
      dateFormat: 'yyyy-MM-dd HH:mm:ss',
      frDate: yesterday.toISOString(),
      toDate: today.toISOString(),
      dates:[yesterday,today],
      currency: null,
      currencies:[],
    };
  },

  computed: {
    ...mapGetters([
      'cryptoPaymentsCount',
      'cryptoPockets'
    ]),
  },

  created() {
    this.$emit('queryChange', this.currentFilter())

    this.currencies = this.cryptoPockets.map(cur => {
      return {
        id: cur.asset.id,
        label: `${cur.asset.title} (${cur.asset.code})`
      }
    });
  },

  methods: {
    changeValue(e) {
      if (e && e instanceof Array){
        this.frDate = e[0].toISOString();
        this.toDate = e[1].toISOString();
      }else{
        this.frDate = null;
        this.toDate = null;
      }
    },

    currentFilter(){
      return  {
        frDate: this.frDate,
        toDate: this.toDate,
        user_uuid: this.userId,
        currency: (this.currency) ? this.currency.id : null,
      }
    },
  }
};
</script>

<style src="./searchCryptoPayments.scss" lang="scss" scoped></style>
