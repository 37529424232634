<template>
  <div class="users">
    <p class="title">Paysenders</p>

    <Search
      placeholder="Search by name, phone number, email"
      class="users__search"
      @queryChange="queryChanged"
    />

    <SearchResults namespace="paysenders" />
  </div>
</template>

<script>
import Search from '@/components/Search/Search.vue';
import SearchResults from './SearchResultsUsers/SearchResultsUsers.vue';

export default {
  name: 'Paysenders',

  components: {
    Search,
    SearchResults,
  },

  created() {
    this.queryChanged(this.$route.query.query);
  },

  methods: {
    queryChanged(query) {
      this.$store.dispatch('paysenders/queryChanged', { query });
    },
  },
};
</script>

<style src="./users.scss" lang="scss" scoped></style>
