import Vue from 'vue';

import config from '@/config';
import axios from 'axios';
import store from '@/store';

const token = localStorage.getItem('token');

const instance = axios.create({
    baseURL: config.HTTP_API_URL,
    headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    },
});

const instanceCrypto = axios.create({
    baseURL: config.HTTP_API_CRYPTO_URL,
    headers: {
        'Authorization' : config.API_CTYPTO_TOKEN,
        'Content-Type' : 'application/json'
    }
});

const errorLink = (error) => {
  if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
      Vue.toasted.show(error.response.data.message || error.response.data.error, {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 5000
        });
      if (error.response.status === 401) {
            store.dispatch('logout');
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        Vue.toasted.show(error.response.data.message, {
        theme: 'toasted-primary',
          position: 'top-center',
          duration: 5000
      });
    } else {
        // Something happened in setting up the request that triggered an Error
        Vue.toasted.show(error.message, {
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 5000
        });
    }
    return new Promise((resolve, reject) => reject(error));
};

export default {
    getUserList: ({ query, sortedKey, sortedDirection, first = 30, after = null, isPaysender }) => {
        // console.log(`Http: getUser ${query}, ${sortedKey}, ${sortedDirection}, ${first}, ${after}`)
        return instance
            .get('/users', {
                params: {
                    query: query,
                    sortedKey: sortedKey,
                    sortedDirection: sortedDirection,
                    first: first,
                    after: after,
                    isPaysender,
                },
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response)
                    let data = response.data;
                    return {
                        data: {
                            usersConnection: {
                                pageInfo: {
                                    hasNextPage: data.hasNextPage,
                                },
                                edges: data.items.map((item, i) => {
                                    return { node: item, cursor: i + after + 1 };
                                }),
                            },
                        },
                    };
                }
            });
    },

    getUser: ({ id }) => {
        // console.log(`Http: getUser ${id}`)
        return instance
            .get(`/users/${id}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response)
                    let data = response.data;
                    return {
                        data: {
                            user: response.data,
                        },
                    };
                }
            });
    },

    blockUser: ({ id, reason, bdid }) => {
        // console.log(`Http: blockUser ${id}, ${reason}, ${bdid}`)
        return instance
            .post(`/users/${id}/block`, {
                reason: reason,
                byDeviceId: bdid,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            blockUser: response.data,
                        },
                    };
                }
            });
    },

    unblockUser: ({ id }) => {
        // console.log(`Http: unblockUser ${id}`)
        return instance
            .post(`/users/${id}/unblock`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            unblockUser: response.data,
                        },
                    };
                }
            });
    },

    removeUser: ({ id, reason, immediately }) => {
        //console.log(immediately)
        return instance
            .post(`/users/${id}/remove`, {
                reason: reason,
                immediately: immediately,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            removeUser: response.data,
                        },
                    };
                }
            });
    },

    reopenUser: ({ id }) => {
        return instance
            .post(`/users/${id}/reopen`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            reopenUser: response.data,
                        },
                    };
                }
            });
    },

    editUserInfo: ({ id, info }) => {
        // console.log(`Http: editUserInfo ${id}, ${info}`)
        return instance
            .post(`/users/${id}/edit`, info)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            editUserInfo: response.data,
                        },
                    };
                }
            });
    },

    changeDueDiligenceLevel: ({
        id,
        dueDiligenceLevel,
        needToChangeKycLevel,
        dateUntilDocument,
        documentNumber,
        personalNumber,
        documentType,
        documentIssueCountryId,
        description,
        editKycAdditionalDoc,
    }) => {
        return instance
            .post(`/users/${id}/level`, {
                level: dueDiligenceLevel,
                dateUntilDocument: dateUntilDocument,
                needToChangeKycLevel: needToChangeKycLevel,
                documentNumber: documentNumber,
                personalNumber: personalNumber,
                documentType: documentType,
                documentIssueCountryId: documentIssueCountryId,
                description: description,
                editKycAdditionalDoc: editKycAdditionalDoc
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            changeDueDiligenceLevel: response.data,
                        },
                    };
                }
            });
    },

    checkTheSameDoc: ({
        id,
        dueDiligenceLevel,
        needToChangeKycLevel,
        dateUntilDocument,
        documentNumber,
        personalNumber,
        documentType,
        documentIssueCountryId,
        editKycAdditionalDoc
    }) => {
        return instance
            .post(`/users/${id}/check`, {
                level: dueDiligenceLevel,
                dateUntilDocument: dateUntilDocument,
                needToChangeKycLevel: needToChangeKycLevel,
                documentNumber: documentNumber,
                personalNumber: personalNumber,
                documentType: documentType,
                documentIssueCountryId: documentIssueCountryId,
                editKycAdditionalDoc : editKycAdditionalDoc,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            changeDueDiligenceLevel: response.data,
                        },
                    };
                }
            });
    },

    getComplianceHistory: ({ userId, sortKey, sortDirection }) => {
        // console.log(`Http: getComplianceHistory ${userId}`)
        return instance
            .get(`/users/${userId}/compliance`, {
                params: {
                    sortKey: sortKey,
                    sortDirection: sortDirection,
                },
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            complianceHistory: response.data,
                        },
                    };
                }
            });
    },

    getPayments: ({ filter, sortedKey, sortedDirection, first = 30, after = null }) => {
        let send = {};
        send.sortedKey = sortedKey;
        send.sortedDirection = sortedDirection;
        send.first = first;
        send.after = after;

        Object.keys(filter).map((key) => {
            send[key] = filter[key];
        });

        return instance
            .get('/payments', { params: send })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response)
                    let data = response.data;
                    return {
                        data: {
                            paymentsConnection: {
                                pageInfo: {
                                    allCount: data.allCount,
                                    hasNextPage: data.hasNextPage,
                                },
                                edges: data.items.map((item, i) => {
                                    return { node: item, cursor: i + after + 1 };
                                }),
                            },
                        },
                    };
                }
            });
    },

    downloadReportPayments: ({ filter, sortedKey, sortedDirection, first = 30, after = null }) => {
        let send = {};
        send.sortedKey = sortedKey;
        send.sortedDirection = sortedDirection;
        send.first = first;
        send.after = after;

        Object.keys(filter).map((key) => {
            send[key] = filter[key];
        });

        return instance
            .get('/reports/payments', { params: send })
            .catch(errorLink)
            .then((response) => {
                let data = response.data;
                // let rows = data.replaceAll('\r','')
                //   .split('\n').filter(item => item !== '')
                //   .map(item => item.split(','));
                return data;
            });
    },

    downloadReportPocket: ({ pktId, filter }) => {
        return instance
            .get(`/reports/pocket/${pktId}`, { params: filter, responseType: 'blob' })
            .catch(errorLink)
            .then((response) => {
                let data = response.data;
                return data;
            });
    },

    getCards: ({ userId }) => {
        return instance
            .get(`/cards/${userId}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            cards: data,
                        },
                    };
                }
            });
    },

    getOnfidoReports: ({ userId }) => {
        return instance
            .get(`/users/${userId}/onfido-report`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    getOnfidoSimpleReport: ({ userId }) => {
        return instance
            .get(`/users/${userId}/onfido-simple-report`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    getOnfidoChecks: ({ userId }) => {
        return instance
            .get(`/users/${userId}/onfido-checks`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    if (data.length === 0) return null;
                    return response.data;
                }
            });
    },

    getCountries: () => {
        return instance
            .get('/common/countries')
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            countries: data,
                        },
                    };
                }
            });
    },

    getCurrencies: () => {
        return instance
            .get('/common/currencies')
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            currencies: data.map((item) => {
                                return {
                                    id: item.id,
                                    label: item.title + ` (${item.code})`,
                                };
                            }),
                        },
                    };
                }
            });
    },

    blockCard: ({ cardId, reason }) => {
        return instance
            .post('/cards/remove', {
                cardId: cardId,
                reason: reason,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            result: data,
                        },
                    };
                }
            });
    },

    freezeCard: ({ cardId, reason }) => {
        return instance
            .post('/cards/freeze', {
                cardId: cardId,
                reason: reason,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            result: data,
                        },
                    };
                }
            });
    },

    unfreezeCard: ({ cardId, reason }) => {
        return instance
            .post('/cards/unfreeze', {
                cardId: cardId,
                reason: reason,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            result: data,
                        },
                    };
                }
            });
    },

    getCardFullPan: ({ cardId }) => {
        return instance
            .get(`/cards/card-pan/${cardId}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return { data };
                }
            });
    },

    getPaymentExtras: ({ id }) => {
        return instance
            .get(`/payments/${id}/extras`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return response.data;
                }
            });
    },

    completePayment: ({ id }) => {
        // console.log(`Http: completePayment ${id}`)
        return instance
            .post(`/payments/${id}/complete`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            completePayment: response.data,
                        },
                    };
                }
            });
    },

    refundPayment: ({ id, reason, amount, sortCode, accountNumber, recipient, reference }) => {
        return instance
            .post(`/payments/${id}/refund`, {
                reason: reason,
                amount: amount,
                sortCode: sortCode,
                accountNumber: accountNumber,
                recipient: recipient,
                reference: reference,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            refundPayment: response.data,
                        },
                    };
                }
            });
    },

    refundPaymentToCard: ({ userId, amount, frCurId, toCurId, pan, comment, expDate }) => {
        return instance
            .post('/payments/to-card', {
                amount: amount,
                frCurId: frCurId,
                toCurId: toCurId,
                comment: comment,
                destination: {
                    userId: userId,
                    pan: pan,
                    expDate: expDate,
                },
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            refundPayment: response.data,
                        },
                    };
                }
            });
    },

    exchangePayment: ({ amount, frCurCode, toCurCode, comment, userId }) => {
        return instance
            .post('/payments/exchange', {
                owner: userId,
                frCurIsoCode: frCurCode,
                toCurIsoCode: toCurCode,
                frAmtBase: amount,
                reason: comment,
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return {
                        data: {
                            exchangePayment: response.data,
                        },
                    };
                }
            });
    },

    getCurrencyReport: ({ balanceDate, varianceFrom, varianceTo }) => {
        // console.log(`Http: getCurrencyReport ${balanceDate}, ${varianceFrom}, ${varianceTo}`)
        return instance
            .get('/reports/currency', {
                params: {
                    balanceDate: balanceDate,
                    varianceFrom: varianceFrom,
                    varianceTo: varianceTo,
                },
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    // console.log(response);
                    return {
                        data: {
                            systemCurrencyBalanceReport: response.data,
                        },
                    };
                }
            });
    },

    getPaymentTypes: (onlyTopup = false) => {
        return instance
            .get('/common/payment-types', {
                params: {
                    topup: onlyTopup,
                },
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            types: data.map((item) => {
                                return {
                                    id: item.id,
                                    label: item.name,
                                };
                            }),
                        },
                    };
                }
            });
    },

    getPaymentStatuses: () => {
        return instance
            .get('/common/payment-statuses')
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            statuses: data.map((item) => {
                                return {
                                    id: item.id,
                                    label: item.name + ` (${item.id})`,
                                };
                            }),
                        },
                    };
                }
            });
    },

    getRates: ({ currencyIsoCode }) => {
        return instance
            .get(`/common/rates/${currencyIsoCode}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data.map((item) => {
                        return {
                            value: item.value,
                            source: item.source,
                            destination: item.destination,
                        };
                    });
                }
            });
    },

    getGrowSubscriptionInfo: ({ userId }) => {
        return instance
            .get(`/grow/subscription/info/${userId}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },


    getGrowStatusesHistory: ({ userId }) => {
        return instance
            .get(`/grow/history/${userId}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    getGrowStatements: ({ userId }) => {
        return instance
            .get(`/grow/statements/${userId}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    cancelGrow: ({ userId, products, reason }) => {
        return instance
            .post('/grow/subscription/cancel',{
                userId,
                products,
                reason
            })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    getGrowRewards: ({ userId }) => {
        return instance
            .get(`/grow/rewards/${userId}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    getKycResolvedDocument: ({ userId }) => {
        return instance
            .get(`/users/${userId}/kyc-resolved-document`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    getGrowUserTest: ({ type }) => {
        return instance
            .get(`/grow/list/${type}`)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    deleteGrowUserTest: ({ type, phone }) => {
        return instance
            .post(`/grow/list/${type}/delete`, {phone})
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    addGrowUserTest: ({ type, phone }) => {
        return instance
            .post(`/grow/list/${type}`, {phone})
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response.data;
                }
            });
    },

    getCryptoUser: ({userId}) => {
        return instanceCrypto.get(`/api/v3/cb/admin/user/${userId}`)
            .catch(errorLink)
            .then(response => {
                if (response) {
                    return response.data;
                }
            })
    },

    getCryptoUserTransactions: ({ filter, sortedDirection, first = 30, after = 0 }) => {
        return instanceCrypto.get('/api/v3/cb/admin/payments', {
                params: {
                    sortedDirection,
                    first,
                    after,
                    ...filter
                }
            })
            .catch(errorLink)
            .then(response => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            paymentsConnection: {
                                pageInfo: {
                                    allCount: data.allCount,
                                    hasNextPage: data.hasNextPage
                                },
                                edges: data.items.map((item, i) => {
                                    return { node: item, cursor: i + after + 1 }
                                })
                            }
                        }
                    }
                }
            })
    },

    getGrowCollectedBalances: () => {
        return instance.get('/grow/collected-balances')
            .catch(errorLink)
            .then(response => {
                if (response) {
                    return response.data;
                }
            })
    },

    getStatements: ({ filter, first = 30, after = null }) => {
        let send = {};
        send.first = first;
        send.after = after;
        Object.keys(filter).map((key) => {
            send[key] = filter[key];
        });

        return instance
            .get('/grow/statements-history', { params: send })
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    let data = response.data;
                    return {
                        data: {
                            statementsConnection: {
                                pageInfo: {
                                    allCount: data.allCount,
                                    hasNextPage: data.hasNextPage,
                                },
                                edges: data.items.map((item, i) => {
                                    return { node: item, cursor: i + after + 1 };
                                }),
                            },
                        },
                    };
                }
            });
    },

    editStatementInfo: ({ id, info }) => {
        return instance
            .post(`/grow/statement//${id}`, info)
            .catch(errorLink)
            .then((response) => {
                if (response) {
                    return response;
                }
            });
    },
    getPlusInfo: ({ userId }) => {
      return instance
        .get(`/plus/info/${userId}`)
        .catch(errorLink)
        .then((response) => {
          if (response) {
            return response.data;
          }
        });
    },

    getPlusSubscriptionPayments: ({ userId }) => {
      return instance
        .get(`/plus/subscription-payments/${userId}`)
        .catch(errorLink)
        .then((response) => {
          if (response) {
            return response.data;
          }
        });
    },

    getPlusSubscriptionPaymentExtra: ({ payId }) => {
      return instance
        .get(`/plus/payment-extra/${payId}`)
        .catch(errorLink)
        .then((response) => {
          if (response) {
            return response.data;
          }
        });
    },

    cancelPlusSubscription: ({ userId }) => {
      return instance
        .post('/plus/subscription/unsubscribe', { userId })
        .catch(errorLink)
        .then((response) => {
          if (response) {
            return response
          }
        });
    },
};
