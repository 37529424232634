<template>
  <div>
    <button class="modal_details__btn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <div class="modal_details__full">
      <p class="modal_details__title">Reports</p>
      <div v-for="(report, i) in reports" :key="i">
        <div v-if="report" class="modal_details__row">
          <span class="modal_row__label">Report: {{ i + 1 }}</span>
          <div class="modal_row__value">
            <tree-view
              :data="report"
              :options="{
                maxDepth: 5,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportOnfidoModal',

  components: {},

  props: {
    // eslint-disable-next-line vue/require-default-prop
    reports: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>

<style src="./jsonReportOnfidoModal.scss" lang="scss" scoped></style>
