<template>
  <div id="app" class="flex-container">
    <Sidebar v-if="this.$route.name !== 'login'" />

    <div class="content" :class="{ sidebarPadding: this.$route.name !== 'login' }">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar/Sidebar.vue';

export default {
  name: 'App',
  components: { Sidebar },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.content,
.flex-container {
  background-color: #fff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}
.modal_edit__select__error .vs__dropdown-toggle {
  border: 1px solid $accent !important;
}

.sidebarPadding {
  padding-left: 265px;
}
</style>
