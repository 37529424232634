<template>
  <div>
    <div class="search">
      <DateTimeInput :value="dates" @input="changeValue" />
      <v-select
        v-model="sts"
        placeholder="Payment status"
        class="style-chooser"
        :options="paymentStatuses"
      ></v-select>
      <Button
        title="Search"
        color="primaryFull"
        size="fit"
        class="button-send"
        @click="$emit('queryChange', currentFilter())"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Button from '@/components/Button/Button';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { startOfYear, startOfTomorrow } from 'date-fns';
import DateTimeInput from '@/components/DateTimeInput/DateTimeInput';

const { mapGetters, mapActions } = createNamespacedHelpers('grow');

let today = startOfTomorrow();
let yesterday = startOfYear(today);

export default {
  name: 'SearchPaymentsGrow',

  components: {
    DateTimeInput,
    Button,
    'v-select': vSelect,
  },

  data() {
    return {
      dateFormat: 'yyyy-MM-dd HH:mm:ss',
      frDate: yesterday.toISOString(),
      toDate: today.toISOString(),
      dates: [yesterday, today],
      sts: null,
      payType: 'GrowSubscription',
    };
  },

  computed: {
    ...mapGetters(['sortedKey', 'sortedDirection', 'paymentsCount', 'paymentStatuses']),
  },

  created() {

    this.$emit('queryChange', this.currentFilter());
  },

  methods: {

    changeValue(e) {
      if (e && e instanceof Array) {
        this.frDate = e[0].toISOString();
        this.toDate = e[1].toISOString();
      } else {
        this.frDate = null;
        this.toDate = null;
      }
    },

    currentFilter() {
      return {
        payType: this.payType ? this.payType : null,
        stsId: this.sts ? this.sts.id : null,
        frDate: this.frDate,
        toDate: this.toDate,
      };
    },
  },
};
</script>

<style src="./searchPaymentsGrow.scss" lang="scss" scoped></style>
