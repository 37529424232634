<template>
  <div class="body-modal">
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <p class="modal_block__title">Update statement info</p>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Amount of statement</span>
        <imask-input
          v-model="amount"
          class="modal_edit__input"
          autofocus
          :mask="Number"
          radix="."
          :unmask="'typed'"
        />
      </div>
      <div class="flex_item_block">
        <DropdownSelect
          v-model="status"
          class="modal_edit__dropdown"
          :label="'Status'"
        >
          <option value=""></option>
          <option v-for="st in statements.statuses" :key="st.id" :value="st.id">{{ st.label }}</option>
        </DropdownSelect>
      </div>
    </div>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Late Fee</span>
        <imask-input
          v-model="fee"
          class="modal_edit__input"
          autofocus
          :mask="Number"
          radix="."
          :unmask="'typed'"
        />
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Extra Late Fee</span>
        <imask-input
          v-model="extraFee"
          class="modal_edit__input"
          autofocus
          :mask="Number"
          radix="."
          :unmask="'typed'"
        />
      </div>
    </div>

    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Due date</span>
        <imask-input
          v-model="dueDate"
          class="modal_edit__input"
          autofocus
          placeholder="2020-12-25 10:00:00"
          :mask="'0000-00-00 00:00:00'"
        />
      </div>
    </div>




    <Button
      id="modal_edit__btn"
      title="Update"
      size="tight"
      color="primaryFull"
      class="modal_edit__btn"
      @click="
        click({
          id, amount, fee, extraFee, dueDate, status
        })
      "
    />
  </div>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
import Button from '@/components/Button/Button';
import {IMaskComponent} from 'vue-imask';
import DropdownSelect from '@/components/DropdownSelect/DropdownSelect';

const { mapGetters, mapActions } = createNamespacedHelpers('grow');

export default {
  name: 'Modal',

  components: {
    Button,
    'imask-input': IMaskComponent,
    DropdownSelect
  },

  props: {
    statement: {
      type: Object,
      required: true,
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data(){
    return {
      id: null,
      amount: null,
      dueDate: null,
      fee: null,
      extraFee: null,
      status: null,
    }
  },

  computed: {
    ...mapGetters(['statements']),
  },

  mounted() {
    this.id = this.statement.id;
    this.amount = this.statement.amount;
    this.dueDate = this.statement.dueDate;
    this.fee = this.statement.lateFee;
    this.extraFee = this.statement.extraLateFee;
    this.status = this.statement.status;
  }
}
</script>
<style src="./modalEditGrowStatement.scss" lang="scss" scoped></style>
