var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getMoreUsers),expression:"getMoreUsers"}],attrs:{"infinite-scroll-disabled":"$loading","infinite-scroll-distance":"20"}},[(_vm.$loading)?_c('div',{staticClass:"users__results"},[_c('Spinner',{staticClass:"users__spinner"})],1):(_vm.query != '')?_c('div',{staticClass:"users__results"},[_c('div',{staticClass:"row__label"},_vm._l((_vm.filters),function(value,key){return _c('span',{key:key,staticClass:"results__column",class:[
          value.title.toLowerCase(),
          {
            sorted__label_asc: _vm.sortedKey === key && _vm.sortedDirection == 'asc',
            sorted__label_desc: _vm.sortedKey === key && _vm.sortedDirection == 'desc',
          } ],attrs:{"id":key},on:{"click":function($event){return _vm.sortedKeyChanged({ sortedKey: key })}}},[_vm._v(_vm._s(value.title))])}),0),(!_vm.users.length)?_c('span',{staticClass:"users__noresults"},[_vm._v("No results")]):_c('div',_vm._l((_vm.users),function(user){return _c('div',{key:user.id,staticClass:"results__row",on:{"click":function($event){return _vm.showUserProfile(user.id)}}},[_c('div',{staticClass:"row__wrapper"},[_c('p',{staticClass:"results__column name"},[_c('span',{staticClass:"firstName",class:{
                active: user.firstName && _vm.query && user.firstName.toUpperCase() === _vm.query.toUpperCase(),
              }},[_vm._v(_vm._s(user.firstName))]),_c('span',{class:{
                active: user.lastName && _vm.query && user.lastName.toUpperCase() === _vm.query.toUpperCase(),
              }},[_vm._v(" "+_vm._s(user.lastName)+" ")])]),_c('span',{staticClass:"results__column phone",class:{ active: user.phone === _vm.query }},[_vm._v(_vm._s(_vm._f("formatPhone")(user.phone)))]),_c('span',{staticClass:"results__column email",class:{ active: user.email && _vm.query && user.email.toUpperCase() === _vm.query.toUpperCase() }},[_vm._v(_vm._s(user.email))]),_c('span',{staticClass:"results__column dob"},[_vm._v(_vm._s(_vm._f("formatDate")(user.dateOfBirth)))]),_c('span',{staticClass:"results__column status",class:{ blocked: user.state === 'blocked' },staticStyle:{"color":"#00b84d","text-transform":"capitalize"}},[(user.state === 'blocked')?_c('span',[_vm._v("blocked")]):_vm._e(),(user.state != 'blocked')?_c('span',[_vm._v(_vm._s(user.state))]):_vm._e()])])])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }