<template>
  <div
    v-infinite-scroll="getMorePayments"
    infinite-scroll-disabled="$loading"
    infinite-scroll-distance="20"
    infinite-scroll-throttle-delay="600"
    class="results__wrapper"
  >
    <modals-container />
    <p v-if="paymentsCount && !$loading" class="count-p">Payments count: {{ paymentsCount }}</p>

    <div v-if="$loading" class="payments__results">
      <Spinner class="payments__spinner" />
    </div>

    <div v-else class="payments__results">
      <div class="row__label">
        <span
          v-for="key in filters"
          :id="key"
          :key="key"
          class="results__column"
          :class="[
            key.toLowerCase(),
            {
              sorted__label_asc: sortedKey === key && sortedDirection == 'asc',
              sorted__label_desc: sortedKey === key && sortedDirection == 'desc',
            },
          ]"
          @click="sortedKeyChanged(key)"
          >{{ key }}</span>
      </div>
      <span v-if="!payments.length" class="payments__noresults">No payments</span>

      <div v-else>
        <BulmaAccordion
          dropdown
          :icon="'custom'"
          :slide="{
            duration: '.4s',
            timerFunc: 'ease',
          }"
        >
          <div v-for="payment in payments" :key="payment.id">
            <BulmaAccordionItem>
              <div
                :id="payment.id"
                slot="title"
                class="row__wrapper"
                :class="{
                  grey: payment.stsId > 90,
                }"
              >
                <span class="results__column id" v-if="payment.gtPayId==null" >{{ payment.id}}</span>
                <span class="results__column id" v-else-if="payment.gtPayId==0" >{{ payment.id}}</span>
                <span class="results__column id" v-else >{{ payment.id + " (GT " + payment.gtPayId + ")"}}</span>
                <span class="results__column time">{{ payment.createdAt | formatTime }}</span>
                <span class="results__column type">
                  {{ payment.typeName }}
                  {{
                    payment.typeName === 'PaymentReversal' ? 'for Payment ' + (payment.id - 1) : ''
                  }}<br />
                  {{ payment.fromCategoryName != null ? payment.fromCategoryName : '' }} ({{
                    payment.frPrvId
                  }}
                  - {{ payment.toPrvId }})</span>
                <span class="results__column desc">{{ payment.title }}</span>
                <span v-if="payment.fromCurrency" class="results__column auth">{{ payment.fromAmount | formatMoney(payment.paymentKind, 'from') }}
                  {{ payment.fromCurrency.code }}</span>
                <span v-else class="results__column auth">No transactions</span>
                <span class="results__column pocket">{{ payment.toAmount | formatMoney(payment.paymentKind, 'to') }}
                  {{ payment.toCurrency.code }}</span>
                <span
                  class="results__column status"
                  style="white-space: nowrap"
                  :class="{
                    green: payment.stsId === 90,
                    yellow: payment.stsId < 90,
                  }"
                  >{{ payment.stsName ? payment.stsName : '' }} <br />
                  ({{ payment.stsId }})</span>
              </div>
              <div slot="content" class="showDet">
                <div class="generalDet">
                  <div class="detBlock detBlock__first">
                    <p v-if="payment.fromCurrency.code !== payment.toCurrency.code">
                      <span class="detBlock__label">FX rate: </span>
                      <span>1 {{ payment.toCurrency.code }} =
                        {{ payment.fromAmount / payment.toAmount }}
                        {{ payment.fromCurrency.code }}</span>
                    </p>
                    <p v-if="payment.userFee">
                      <span class="detBlock__label">User fee: </span>
                      <span>{{ payment.userFee | formatMoney }}
                        {{ payment.providerTermsCurrency.code }}</span>
                    </p>

                    <p v-if="payment.frAmtBase !== payment.frAmtReal">
                      <span class="detBlock__label">From fee: </span>
                      <span>{{ (payment.frAmtReal - payment.frAmtBase)  | formatMoneyNotDivide}} {{ payment.fromCurrency.code }}</span>
                    </p>

                    <p v-if="payment.providerFee">
                      <span class="detBlock__label">Provider fee: </span>
                      <span>{{ payment.providerFee | formatMoney }}
                        {{ payment.providerTermsCurrency.code }}</span>
                    </p>

                    <p v-if="payment.toAmtBase !== payment.toAmtReal">
                      <span class="detBlock__label">To fee: </span>
                      <span>{{ (payment.toAmtBase - payment.toAmtReal)  | formatMoneyNotDivide}} {{ payment.toCurrency.code }}</span>
                    </p>

                    <p v-if="payment.paysendCard">
                      <span class="detBlock__label">Paysend card: </span>
                      <span>{{ payment.paysendCard }}</span>
                    </p>
                    <p v-if="payment.external && payment.typeName !== 'PosPayment'">
                      <span class="detBlock__label">External card: </span>
                      <span>{{ payment.external }}</span>
                    </p>
                    <p v-if="payment.posCardDataInputMode">
                      <span class="detBlock__label">Entry method: </span>
                      <span>{{ payment.posCardDataInputMode }}</span>
                    </p>
                    <p v-if="payment.location">
                      <span class="detBlock__label">Location: </span>
                      <span>{{ payment.location }}</span>
                    </p>
                    <p>
                      <span class="detBlock__label">User desc: </span>
                      <span>{{ payment.title || '-' }}</span>
                    </p>
                    <p v-if="payment.fromUserId">
                      <span class="detBlock__label">{{
                          payment.type === 'InternalTransferPayment'
                          ? 'Sender details:'
                          : 'User:'
                      }}</span>
                      <span class="user_link" @click="showUserProfile(payment.fromUserId)">{{
                        payment.fromUserName
                      }}</span>
                    </p>
                    <p
                      v-if="
                        payment.toUserId &&
                        payment.type !== 'ExchangePayment'
                      "
                    >
                      <span class="detBlock__label">Beneficiary details: </span>
                      <span class="user_link" @click="showUserProfile(payment.toUserId)">{{
                        payment.toUserName
                      }}</span>
                    </p>
                  </div>
                  <div class="detBlock">
                    <p v-if="payment.externalSmartvistaId">
                      <span class="detBlock__label">SV payment ID: </span>
                      <span>{{ payment.externalSmartvistaId }}</span>
                    </p>
                    <p v-if="payment.completedAt">
                      <span class="detBlock__label">Completed at: </span>
                      <span>{{ payment.completedAt | formatTime }}</span>
                    </p>
                    <p v-if="payment.refundedTs">
                      <span class="detBlock__label">Refunded ts: </span>
                      <span>{{ payment.refundedTs | formatTime }}</span>
                    </p>
                    <p v-if="payment.authCode">
                      <span class="detBlock__label">Auth code: </span>
                      <span>{{ payment.authCode }}</span>
                    </p>
                    <p v-if="payment.deliveryAddress">
                      <span class="detBlock__label">Delivery address: </span>
                      <span>{{ payment.deliveryAddress }}</span>
                    </p>
                    <p v-if="payment.deliveryAddress">
                      <span class="detBlock__label">Delivery type: </span>
                      <span>{{ payment.deliveryType || '-' }}</span>
                    </p>
                    <p v-if="payment.error">
                      <span class="detBlock__label">Error desc: </span>
                      <span>{{ payment.error }}</span>
                    </p>
                    <p v-if="payment.errorGt" class="word-wrap">
                      <span class="detBlock__label">Error GT desc: </span>
                      <span>{{ payment.errorGt }}</span>
                    </p>
                    <p v-if="payment.errorGtName">
                      <span class="detBlock__label">Error GT name: </span>
                      <span>{{ payment.errorGtName }}</span>
                    </p>
                    <p v-if="payment.topupCard">
                      <span class="detBlock__label">Topup card: </span>
                      <span>*{{ payment.topupCard }}</span>
                    </p>
                    <p v-if="payment.iban">
                      <span class="detBlock__label">IBAN: </span>
                      <span>{{ payment.iban }}</span>
                    </p>
                    <p v-if="payment.topupType">
                      <span class="detBlock__label">Payment system: </span>
                      <span>{{ payment.topupType }}</span>
                    </p>
                    <p v-if="payment.topupCountry">
                      <span class="detBlock__label">Topup country: </span>
                      <span>{{ payment.topupCountry }}</span>
                    </p>
                    <p v-if="payment.topupBank">
                      <span class="detBlock__label">Bank: </span>
                      <span>{{ payment.topupBank }}</span>
                    </p>
                  </div>
                </div>
                <div class="detBlock__btns">
                  <Button
                    v-if="payment.stsName && payment.stsName.indexOf('on_review') >= 0"
                    title="Complete!"
                    color="orange"
                    size="tight"
                    @click="completePayment(payment.id)"
                  />
                  <Button
                    v-if="checkPaymentForFullRefund(payment) && showButtonRefund()"
                    title="Refund payment"
                    color="orange"
                    size="tight"
                    @click="showRefund(payment, true)"
                  />
                  <Button
                    v-if="checkPaymentForPartialRefund(payment) && showButtonRefund()"
                    title="Refund back to card"
                    color="orange"
                    size="tight"
                    @click="showRefund(payment)"
                  />
                  <Button
                    class="btn_right"
                    title="More details"
                    color="orange"
                    size="tight"
                    @click="show(payment)"
                  />
                </div>
              </div>
            </BulmaAccordionItem>
          </div>
        </BulmaAccordion>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import { BulmaAccordion, BulmaAccordionItem } from 'vue-bulma-accordion';
import JsonDetailsModal from '@/components/JsonDetailsModal/JsonDetailsModal';
import Button from '@/components/Button/Button';
import ModalRefund from '@/components/ModalRefund/ModalRefund.vue';
import { GRANTS } from '@/services/const';

const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'SearchResultsPayments',

  components: {
    Button,
    Spinner,
    BulmaAccordion,
    BulmaAccordionItem,
  },

  data() {
    return {
      showDetails: [],
      filters: ['ID', 'Time', 'Type', 'Desc', 'From', 'To', 'Status'],
    };
  },

  computed: {
    ...mapGetters(['user', 'topups', 'payments', 'sortedKey', 'sortedDirection', 'paymentsCount']),
  },

  methods: {
    ...mapActions([
      'getUser',
      'getPayments',
      'getMorePayments',
      'sortedKeyChanged',
      'completePayment',
      'refundPayment',
    ]),

    show(payment) {
      let addDetails = {
        'Auth method': payment.posCardholderAuthenticationMethod,
        'Input Capability': payment.posCardDataInputCapability,
        'Auth Capability': payment.posCardholderAuthenticationCapability,
        'Auth Entity': payment.posCardholderAuthenticationEntity,
        'Card Presence': payment.posCardPresence,
        'Cardholder Presence Indicator': payment.posCardholderPresenceIndicator,
      };

      this.$modal.show(
        JsonDetailsModal,
        {
          payment: payment,
          addDetails,
        },
        { scrollable: true, width: '700', height: 'auto' },
      );
    },

    showUserProfile(userId) {
      let routeData = this.$router.resolve({ name: 'users-profile', params: { userId } });
      window.open(routeData.href, '_blank');
    },

    showRefund(payment, full) {
      //console.log(payment)
      this.$modal.show(
        ModalRefund,
        {
          title: `Refund ${payment.typeName}`,
          subtitle: `Refund ${this.$options.filters.formatMoney(payment.fromAmount, 'from')} ${
            payment.fromCurrency.code
          } back to source`,
          titleBtn: 'Refund',
          payment: payment,
          full: full,
          click: (amount, message, sortCode, accountNumber, recipient, reference) => {
            // console.log(amount, message)
            this.refundPayment({
              id: payment.id,
              reason: message,
              amount: amount,
              sortCode: sortCode,
              accountNumber: accountNumber,
              recipient: recipient,
              reference: reference,
            });
            this.$modal.hide('refundPaymentModal');
          },
        },
        {
          name: 'refundPaymentModal',
          height: this.checkFSPayment(payment) ? '892' : '492',
        },
      );
    },

    showButtonRefund() {
      return this.$hasGrant(GRANTS.CREATE_PAYMENT_REFUNDS);
    },

    checkPaymentForPartialRefund(payment) {
      if (payment.stsId !== 90) {
        return false;
      }

      return [103, 104, 201, 202, 205, 206].includes(payment.frPrvId);
    },

    checkPaymentForFullRefund(payment) {
      if (payment.stsId !== 90) {
        return false;
      }

      return (
        [141, 144, 108].includes(payment.toPrvId) ||
        ([207, 7].includes(payment.frPrvId) && [99, 259].includes(payment.toPrvId)) ||
        ([106, 107].includes(payment.toPrvId) &&
          this.$hasGrant(GRANTS.CREATE_PAYMENT_REFUNDS_ECOM)) ||
        (payment.frPrvId === 7 &&
          payment.toPrvId === 109 &&
          this.$hasGrant(GRANTS.CREATE_PAYMENT_CANCEL_EXCHANGE))
      );
    },

    checkFSPayment(payment) {
      if (payment.stsId !== 90) {
        return false;
      }

      return [205, 206].includes(payment.frPrvId);
    },
  },
};
</script>

<style src="./searchResultsPayments.scss" lang="scss" scoped></style>
