<template>
  <main>
    <div class="flex_item_block">
      <div class="flex_item_block__title">
        Only Different
      </div>
      <div class="flex_item_block__toggle">
        <Toggle :enabled="isDifferent" @change="changeIsDifferent($event)" />
      </div>
    </div>

    <div v-if="$loading" class="balances__results">
      <Spinner class="balances__spinner" />
    </div>

    <div v-else class="balances__results">
      <span v-if="!filteredBalances.length" class="balances__noresults">No balances</span>
      <div v-else class="balances__list">
        <div class="balances__label">
          <span class="name">Name</span>
          <span>Pocket Type</span>
          <span>Pocket Status</span>
          <span>Balance</span>
          <span>Overdraft</span>
          <span>Deposit</span>
          <span>Statements</span>
        </div>
        <div v-for="(balance, index) in filteredBalances" :key="index" class="balances__item">
          <span class="name">
            <span class="user_link" @click="showUserProfile(balance.userId)">{{
                balance.name
              }}</span>
          </span>
          <span>{{ balance.pktType }}</span>
          <span>{{ balance.pktStsId }}</span>
          <span>{{ balance.balance | formatMoneyNotDivide }} GBP</span>
          <span>{{ balance.overdraft | formatMoneyNotDivide }} GBP</span>
          <span>{{ balance.deposit | formatMoneyNotDivide }} GBP</span>
          <span>{{ balance.stmtsAmount | formatMoneyNotDivide }} GBP</span>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Spinner from '@/components/Spinner/Spinner';
import Toggle from '@/components/Toggle/Toggle.vue';
import {createNamespacedHelpers} from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('grow');

export default {
  name: 'GrowBalances',
  components: {
    Spinner,Toggle
  },

  data() {
    return {
      isDifferent: true,
    }
  },

  computed: {
    ...mapGetters(['filteredBalances']),
  },

  mounted() {
    this.getCollectedBalances()
  },

  methods: {
    ...mapActions(['getCollectedBalances','filterBalances']),
    showUserProfile(userId) {
      let routeData = this.$router.resolve({ name: 'users-profile', params: { userId } });
      window.open(routeData.href, '_blank');
    },
    changeIsDifferent(isDifferent){
      this.isDifferent = isDifferent;
      this.filterBalances({isDifferent})
    }
  }
}
</script>
<style src="./balances.scss" lang="scss" scoped></style>
