<template>
  <main class="profile__info">
    <modals-container />
    <div class="info info__personal">
      <p class="info__title">Personal info</p>
      <div class="info__row">
        <span class="info__label">Photo:</span>
        <!--        <img-->
        <!--          v-if="user.avatar && serverUrl.indexOf('http://localhost') != -1"-->
        <!--          :src="`${serverUrl}${user.avatar}`"-->
        <!--          class="ava"-->
        <!--        >-->
        <div class="avatar-wrap">
          <img v-if="user.avatar" :src="`${user.avatar}`" class="ava" />
          <span v-else class="info__defaultAva">{{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}</span>
          <img v-if="user.isPaysender" src="~@/assets/img/logo_icon2.svg" class="ava-bage" />
        </div>
      </div>
      <div class="info__row">
        <span class="info__label">First name:</span>
        <span>{{ user.firstName }}</span>
      </div>
      <div class="info__row">
        <span class="info__label">Last name:</span>
        <span>{{ user.lastName }}</span>
      </div>
      <div class="info__row">
        <span class="info__label">Date of birth:</span>
        <span v-if="!isValidAge">
          <span class='error_block'>
            <span class='info_error_state'>{{ user.dateOfBirth | formatDate }}</span>
            <Button
              class = 'error_button'
              title="Change"
              size="micro"
              color="accentFull"
              @click="editInfo()"
            />
          </span>
          <span class='error_message'> INVALID DOB! </span>
        </span>
        <div v-else>{{ user.dateOfBirth | formatDate }}</div>
      </div>
      <div class="info__row">
        <span class="info__label">Phone:</span>
        <span style="margin-right: 5px;">{{ user.phone | formatPhone }}</span>
        ({{ user.countryOfPhone ? user.countryOfPhone['iso3-code'] : 'none' }})
      </div>
      <div v-if="user.office" class="info__row">
        <span class="info__label">Office:</span>
        <span>{{ officeName }}</span>
      </div>
      <div class="info__row">
        <span class="info__label">Email:</span>
        <span>{{ user.email || 'none' }}</span>
      </div>
      <div class="info__row">
        <span class="info__label">Nationality:</span>
        <span>{{ user.countryOfNationality ? user.countryOfNationality.title : 'none' }}</span>
      </div>
      <div class="info__row">
        <span class="info__label">Residence addr:</span>
        <span>{{ user.addressLine1 }}<br v-if="user.addressLine2" />
          {{ user.addressLine2 }}<br />
          {{ user.city }}<br />
          {{ user.postalCode }}<br />
          {{ user.countryOfResidence ? user.countryOfResidence.title : '' }}
        </span>
      </div>
      <Button
        title="Edit info"
        size="tight"
        color="primary"
        style="margin-top: auto;"
        @click="editInfo()"
      />
    </div>
    <div class="info info__limits">
      <p class="info__title">Verification & limits</p>
      <div class="limits__block">
        <div class="info__row">
          <span class="info__label">State:</span>
          <span v-if="user.state === 'blocked'" class="info__state__blocked">blocked</span>
          <span v-else class="info__state">{{ user.state }}</span>
        </div>

        <!--        <div class="info__row">-->
        <!--          <span class="info__label">Fraud Monitoring:</span>-->
        <!--          <span v-if="user.locks.length" class="info__state__blocked">-->
        <!--            blocked-->
        <!--          </span>-->
        <!--          <span v-else class="info__state">clean</span>-->
        <!--        </div>-->

        <div class="info__row">
          <span class="info__label">Fraud Monitoring:</span>
          <span v-if="user.sbBlocks.length" class="info__state__blocked">
            <div v-for="block in user.sbBlocks" :key="block.id" class="info__body">
              {{ block.id }} - {{ block.comment }} ({{ block.whoAdd }})
            </div>
          </span>
          <span v-else class="info__state">clean</span>
        </div>

        <!--        <div v-if="user.locks.length" class="info__row">-->
        <!--          <span class="info__label">Lock ID:</span>-->
        <!--          <span>-->
        <!--            <div v-for="lock in user.locks" :key="lock.id" class="info__body">-->
        <!--              {{ lock.id }} - {{ lock.name }}-->
        <!--            </div>-->
        <!--          </span>-->
        <!--        </div>-->

        <div v-if="user.state === 'blocked'" class="info__row">
          <span class="info__label">Blocked reason:</span>
          <span>{{ user.blockReasons }}</span>
        </div>

        <div class="info__row">
          <span class="info__label">Joined:</span>
          <span>{{ user.createdAt | formatDate }}</span>
        </div>
        <div v-if="user.baseCurrency" class="info__row">
          <span class="info__label">Base currency:</span>
          <span>{{ user.baseCurrency.code }}</span>
        </div>
        <div v-if="user.dueDiligenceLimit" class="info__row">
          <span class="info__label">Top-up limit:</span>
          <span v-if="user.dueDiligenceLimit.incomeLimit > 0">Left
            {{
              (user.dueDiligenceLimit.incomeLimit - user.dueDiligenceLimit.incomeLimitUsed)
                | formatMoney
            }}
            / {{ user.dueDiligenceLimit.incomeLimit | formatMoney }} {{ user.baseCurrency.code }}
            <br />From {{ user.dueDiligenceLevelStartedAt | formatDate }} until
            {{ user.dueDiligenceLevelExpiresAt | formatDate }}
          </span>
          <span v-else>No limit</span>
        </div>
        <div v-if="user.dueDiligenceLimit" class="info__row">
          <span class="info__label">ATM limit:</span>
          <span v-if="user.dueDiligenceLimit.cashWithdrawalLimit > 0">Left
            {{
              (user.dueDiligenceLimit.cashWithdrawalLimit -
                user.dueDiligenceLimit.cashWithdrawalLimitUsed)
                | formatMoney
            }}
            / {{ user.dueDiligenceLimit.cashWithdrawalLimit | formatMoney }}
            {{ user.baseCurrency.code }} <br />From
            {{ user.dueDiligenceLevelStartedAt | formatDate }} until
            {{ user.dueDiligenceLevelExpiresAt | formatDate }}
          </span>
          <span v-else>No limit</span>
        </div>

        <div v-if="user.userLimits">
          <div v-for="limit in notHiddenUserLimits" :key="limit.limit_type" class="info__row">
            <span class="info__label__verification_limits_cont"><b> {{limit.limit_name}} </b><br />
              <div class="info__label__limit_info">
                resets
                <span v-if="limit.cycle_month_interval === undefined"> NEVER</span>
                <span v-else> every <b>{{limit.cycle_month_interval}}</b> {{getCycleType(limit)}}</span>
                <br />
                status:
                <span v-if="limit.limit_state === 'locked'" class="info__state__blocked">locked</span>
                <span v-else class="info__state">{{ limit.limit_state }}</span>
              </div>
            </span>
            <span v-if="limit.limit_state !== 'unlimited'">
              Left {{ (limit.max_value - limit.current_value) | formatMoneyNotDivide }} /
              {{ limit.max_value | formatMoneyNotDivide }} {{ limit.cur_iso }} <br />From
              {{ limit.cycle_start_date | formatShortDate }} to
              {{ limit.cycle_reset_date | formatCheckableDate }}
            </span>
            <span v-else>
              <!--              Spent {{limit.current_value}}/-->
              Unlimited
              <br />From {{ limit.cycle_start_date | formatShortDate }} to
              {{ limit.cycle_reset_date | formatCheckableDate }}
            </span>
          </div>
          <div class="hidden_limits__block">Hidden limits</div>
          <div v-for="limit in hiddenUserLimits" :key="limit.limit_type" class="info__row">
            <span class="info__label__verification_limits_cont"><b> {{limit.limit_name}} </b><br />
              <div class="info__label__limit_info">
                resets
                <span v-if="limit.cycle_month_interval === undefined"> NEVER</span>
                <span v-else> every <b>{{limit.cycle_month_interval}}</b> {{getCycleType(limit)}}</span>
                <br />
                status:
                <span v-if="limit.limit_state === 'locked'" class="info__state__blocked">locked</span>
                <span v-else class="info__state">{{ limit.limit_state }}</span>
              </div>
            </span>
            <span v-if="limit.limit_state !== 'unlimited'">
              Left {{ (limit.max_value - limit.current_value) | formatMoneyNotDivide }} /
              {{ limit.max_value | formatMoneyNotDivide }} {{ limit.cur_iso }} <br />From
              {{ limit.cycle_start_date | formatShortDate }} to
              {{ limit.cycle_reset_date | formatCheckableDate }}
            </span>
            <span v-else>
              <!--              Spent {{limit.current_value}}/-->
              Unlimited
              <br />From {{ limit.cycle_start_date | formatShortDate }} to
              {{ limit.cycle_reset_date | formatCheckableDate }}
            </span>
          </div>
        </div>

      </div>
      <div
        v-if="kycResolvedDocument != null && kycResolvedDocument.documentNumber != null"
        class="onfidodoc__block"
      >
        <p class="onfidodoc__title">{{ kycResolvedDocument.source }} document</p>
        <div class="info__row">
          <span class="info__label">KYC state: </span>
          <span>{{ kycResolvedDocument.state }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Document type: </span>
          <span>{{ kycResolvedDocument.documentType }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Document number: </span>
          <span>{{ kycResolvedDocument.documentNumber }}</span>
        </div>
        <div v-if="kycResolvedDocument.personalNumber != null" class="info__row">
          <span class="info__label">Personal number: </span>
          <span>{{kycResolvedDocument.personalNumber}}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Issuing date: </span>
          <span>{{ kycResolvedDocument.issuingDate }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Date of expiration: </span>
          <span>{{ kycResolvedDocument.dateOfExpiry }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Issuing country: </span>
          <span>{{ kycResolvedDocument.issuingCountry }}</span>
        </div>
      </div>
      <div v-if="mainDoc != null && mainDoc.documentNumber != null">
        <p class="onfidodoc__title" :class="{onfidodoc__title_visa: isDocumentVisa()}">Due diligence Main document</p>
        <div class="info__row" :class="{info__row_visa: isDocumentVisa()}">
          <span class="info__label" :class="{info__label_visa: isDocumentVisa()}">Document type:  </span>
          <span>{{getDocumentTypeName(mainDoc.documentType)}}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Document number: </span>
          <span>{{ mainDoc.documentNumber }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Date of expiration: </span>
          <span>{{ mainDoc.dateUntilDocument }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Issuing country: </span>
          <span>{{ mainDoc.documentIssueCountryId }}</span>
        </div>
        <div v-if="mainDoc.showPersonalNumber" class="info__row">
          <span class="info__label">Personal number: </span>
          <span>{{ mainDoc.personalNumber }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Description: </span>
          <span>{{ mainDoc.documentDescription }}</span>
        </div>
      </div>

      <div
        v-if="user.dueDiligenceLevel === 2 && addDoc.additionalDocumentNumber "
        class="onfidodoc__block"
      >
        <p class="onfidodoc__title">Due diligence Additional document</p>
        <div class="info__row">
          <span class="info__label">Document type: </span>
          <span>{{ getDocumentTypeName(addDoc.additionalDocumentType) }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Document number: </span>
          <span>{{ addDoc.additionalDocumentNumber }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Date of expiration: </span>
          <span>{{ addDoc.additionalDateUntilDocument }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Issuing country: </span>
          <span>{{ addDoc.additionalDocumentIssueCountryId }}</span>
        </div>
        <div v-if="addDoc.showAdditionalPersonalNumber" class="info__row">
          <span class="info__label">Personal number: </span>
          <span>{{ addDoc.additionalPersonalNumber }}</span>
        </div>
        <div class="info__row">
          <span class="info__label">Description: </span>
          <span>{{ addDoc.additionalDocumentDescription }}</span>
        </div>
      </div>

      <div v-if="!user.child">
        <div class="info__row">
          <span class="info__label">Due diligence:</span>
          <div class="info__row">
            <!-- <span>Level {{ user.dueDiligenceLevel }} ·</span> -->
            <span>Level {{ user.dueDiligenceLevel }}</span>
            <div>
              <dropdown
                :on-click="changeLevel"
                btn-name="Edit"
                :active-item="levels[user.dueDiligenceLevel - 1]"
                :items="levels"
              />
            </div>
          </div>
        </div>
      </div>

      <Button
        :disabled="disableBlock"
        :title="user.state === 'blocked' ? 'Unblock user' : 'Block user'"
        color="orange"
        size="tight"
        @click="user.state === 'blocked' ? handleUnblockUser() : showBlock()"
      />

      <div v-if="user.closeInfo != null || showButtonRemove()" class="close_info">
        <p class="info__title">Account removing</p>

        <div v-if="user.closeInfo != null">
          <div class="info__row">
            <span class="info__label">Date of removing:</span>
            <span>{{ user.closeInfo.closeDate }}</span>
          </div>

          <div class="info__row">
            <span class="info__label">Comment:</span>
            <span v-if="user.closeInfo != null">
              {{ user.closeInfo.comment }}
            </span>
          </div>
        </div>

        <!--  Remove user buttons   -->
        <div v-if="showButtonRemove()" class="info__row">
          <span>
            <Button
              :disabled="disableBlock || user.state === 'closed' || user.state === 'for_close'"
              title="Remove user"
              color="orange"
              size="tight"
              @click="showRemove()"
            />
          </span>

          <span>
            <Button
              :disabled="disableBlock || user.state !== 'for_close'"
              title="Reopen user"
              color="primary"
              size="tight"
              @click="showReopen()"
            />
          </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import config from '@/config';
import { createNamespacedHelpers } from 'vuex';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import Button from '@/components/Button/Button.vue';
import ModalReason from '@/components/ModalReason/ModalReason.vue';
import ModalEditUserPhone from '@/components/ModalEditUserPhone/ModalEditUserPhone.vue';
import ModalEditUserInfo from '@/components/ModalEditUserInfo/ModalEditUserInfo.vue';
import ModalDateUntilDDLevel from '@/components/ModalDateUntilDDLevel/ModalDateUntilDDLevel';
import ModalDateUntilDDLevelKycTwo from '@/components/ModalDateUntilDDLevel/ModalDateUntilDDLevelKycTwo';
import { GRANTS } from '@/services/const';
import Http from '@/services/http';

const { mapGetters, mapState, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'Profile',
  components: { Dropdown, Button },

  data() {
    return {
      disableBlock: false,
      updateUserPhoneInProgress: false,
      message: '',
      docByLevel: {},
      mainDoc:{
        personalNumber: '',
        documentNumber: '',
        dateUntilDocument: '',
        documentType: '',
        documentIssueCountryId: '',
        documentDescription: '',
      },
      addDoc:{
        additionalPersonalNumber: '',
        additionalDocumentNumber: '',
        additionalDateUntilDocument: '',
        additionalDocumentType: '',
        additionalDocumentIssueCountryId: '',
        additionalDocumentDescription: '',
      },
      byDeviceId: true,
      serverUrl: config.SERVER_URL,
      countries: [],
      levels: [
        'Level 1',
        'Level 2 · KYC verified',
        'Level 3 · POA verified',
        'Level 4 · SOF verified',
      ],
      notHiddenUserLimits: [],
      hiddenUserLimits: [],
    };
  },

  computed: {
    isValidAge() {
      const today = new Date();
      const birthDate = new Date(this.user.dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= 18 && age < 125;
    },
    ...mapState(['user', 'offices', 'kycResolvedDocument']),
    officeName: {
      get() {
        var officeObj = this.offices.find(o => o.id == this.user.office);
        return officeObj ? officeObj.label : '';
      }
    }
  },

  watch: {
    user: {
      handler(cnts) {
        //console.log(['cnt', cnts]);
        this.setDoc();
        this.setLimits();
      },
      immediate: true,
    },
    countries: {
      handler(cnts) {
        //console.log(['cnt', cnts]);
        this.setDoc();
      },
      immediate: true,
    },
  },

  mounted() {
    let input = document.querySelector('[autofocus]');
    if (input) {
      input.focus();
    }
    this.loadCountries();
  },

  methods: {
    ...mapActions([
      'blockUser',
      'removeUser',
      'reopenUser',
      'unblockUser',
      'editUserInfo',
      'changeDueDiligenceLevel',
    ]),

    setDoc() {
      let documentsByLevel = this.user.documentsByLevel;
      if (documentsByLevel[this.user.dueDiligenceLevel]) {
        let doc = documentsByLevel[this.user.dueDiligenceLevel];
        let mainDoc = doc.mainDocument;
        let additionalDoc = doc.additionalDocument;
        let selectedCountry = this.countries
          .filter((x) => x.id === mainDoc.documentIssueCountryId);
        let selectedAdditionalDocCountry = this.countries
          .filter((x) => x.id === additionalDoc.additionalDocumentIssueCountryId);

        this.mainDoc = {
          documentNumber: mainDoc.documentNumber ? mainDoc.documentNumber : '',
          documentType: mainDoc.documentType ? mainDoc.documentType : '',
          documentIssueCountryId: mainDoc.documentIssueCountryId
            ? selectedCountry.map((x) => x.title)[0] : '',
          showPersonalNumber : mainDoc.documentIssueCountryId
            ? selectedCountry.map(x => x['iso2-code'])[0] === 'ES' : false,
          personalNumber : mainDoc.personalNumber,
          documentDescription: mainDoc.documentDescription,
          dateUntilDocument: mainDoc.dateUntilDocument,
        };

        this.addDoc = {
          additionalDocumentNumber: additionalDoc.additionalDocumentNumber ? additionalDoc.additionalDocumentNumber : '',
          additionalDocumentType: additionalDoc.additionalDocumentType ? additionalDoc.additionalDocumentType : '',
          additionalDocumentIssueCountryId: additionalDoc.additionalDocumentIssueCountryId
            ? selectedAdditionalDocCountry.map((x) => x.title)[0] : '',
          showAdditionalPersonalNumber : additionalDoc.additionalDocumentIssueCountryId
            ? selectedAdditionalDocCountry.map(x => x['iso2-code'])[0] === 'ES' : false,
          additionalPersonalNumber : additionalDoc.additionalPersonalNumber,
          additionalDocumentDescription: additionalDoc.additionalDocumentDescription,
          additionalDateUntilDocument: additionalDoc.additionalDateUntilDocument,
        };


      }
    },

    setLimits() {
      this.notHiddenUserLimits = this.user.userLimits
        .filter(isHidden => isHidden.is_hide_from_api !== true)
        .sort((a, b) => {
          if (a.limit_type < b.limit_type) {
            return -1;
          }
          if (a.limit_type > b.limit_type) {
            return 1;
          }
          return 0;
        });
      this.hiddenUserLimits = this.user.userLimits
        .filter(isHidden => isHidden.is_hide_from_api === true)
        .sort((a, b) => {
          if (a.limit_type < b.limit_type) {
            return -1;
          }
          if (a.limit_type > b.limit_type) {
            return 1;
          }
          return 0;
        });
    },

    async loadCountries() {
      let data = await Http.getCountries();
      this.countries = data.data.countries;
    },

    isDocumentVisa() {
      return this.mainDoc.documentType === 'visa';
    },

    changeLevel(res) {
      let lvlIs2 = res.includes('Level 2');
      this.$modal.show(
        lvlIs2 ? ModalDateUntilDDLevelKycTwo : ModalDateUntilDDLevel,
        {
          user: this.user,
          dueDiligenceLevel: res,
          kycResolvedDocument: this.kycResolvedDocument,
          countries: this.countries,
          click: () => {
            if (lvlIs2) this.$modal.hide('ModalDateUntilDDLevelKycTwo');
            else this.$modal.hide('ModalDateUntilDDLevel');
          },
        },
        {
          name: lvlIs2 ? 'ModalDateUntilDDLevelKycTwo' : 'ModalDateUntilDDLevel',
          width: '640',
          scrollable: true,
          height: lvlIs2 ? 'auto' : '480',
        },
      );
    },

    editPhone() {
      this.$modal.show(
        ModalEditUserPhone,
        {
          user: this.user,
          click: this.handleEditUserInfo,
        },
        {
          name: 'editUserPhoneModal',
          width: '600',
          height: '360',
        },
      );
    },

    editInfo() {
      this.$modal.show(
        ModalEditUserInfo,
        {
          user: this.user,
          offices: this.offices,
          click: this.handleEditUserInfo,
        },
        {
          name: 'editUserInfoModal',
          width: '640',
          height: 'auto',
        },
      );
    },

    showBlock() {
      this.$modal.show(
        ModalReason,
        {
          title: `${this.user.state === 'blocked' ? 'Unblock' : 'Block'} ${this.user.firstName} ${
            this.user.lastName
          }`,
          titleBtn: 'Block',
          checkbox: 'Block other users with same DeviceID',
          click: this.handleBlockUser,
        },
        {
          name: 'blockUserModal',
          height: '400',
        },
      );
    },

    async handleBlockUser(message, byDeviceId) {
      this.$modal.hide('blockUserModal');
      this.disableBlock = true;
      await this.blockUser({
        userId: this.user.id,
        reason: message,
        bdid: byDeviceId,
      });
      this.disableBlock = false;
    },

    showRemove() {
      this.$modal.show(
        ModalReason,
        {
          title: `Remove ${this.user.firstName} ${this.user.lastName}`,
          titleBtn: 'Remove',
          flagTitle: 'Remove user immediately',
          click: this.handleRemoveUser,
        },
        {
          name: 'blockUserModal',
          height: '400',
        },
      );
    },

    showReopen() {
      this.$modal.show(
        ModalReason,
        {
          title: `Reopen ${this.user.firstName} ${this.user.lastName}?`,
          titleBtn: 'Reopen',
          withReason: false,
          click: this.handleReopenUser,
        },
        {
          name: 'reopenUserModal',
          height: '230',
        },
      );
    },

    async handleRemoveUser(message, flag) {
      this.$modal.hide('blockUserModal');
      //console.log(flag)
      this.disableBlock = true;
      await this.removeUser({
        userId: this.user.id,
        reason: message,
        immediately: flag,
      }).catch((e) => {
        this.disableBlock = false;
      });
      this.disableBlock = false;
    },

    async handleReopenUser(message, flag) {
      this.$modal.hide('reopenUserModal');
      //console.log(flag)
      this.disableBlock = true;
      await this.reopenUser({
        userId: this.user.id,
      }).catch((e) => {
        this.disableBlock = false;
      });
      this.disableBlock = false;
    },

    async handleUnblockUser() {
      this.disableBlock = true;
      await this.unblockUser({ userId: this.user.id });
      this.disableBlock = false;
    },

    async handleEditUserInfo(info) {
      document.getElementById('modal_edit__btn').disabled = true;
      try {
        await this.editUserInfo({
          userId: this.user.id,
          info: info,
        });
        this.$modal.hide('editUserPhoneModal');
        this.$modal.hide('editUserInfoModal');
      } catch (error) {
        //console.error(error)
      }
      document.getElementById('modal_edit__btn').disabled = false;
    },

    getCycleType(limit) {
      switch (limit.cycle_type) {
      case 'month_interval':
        return limit.cycle_month_interval === 1 ? ' month ' : ' months ';
      case 'month_interval_calendar':
        return limit.cycle_month_interval === 1 ? ' month ' : ' months ';
      case 'day_interval':
        return limit.cycle_month_interval === 1 ? ' day ' : ' days ';
      default:
        return limit.cycle_type;
      }
    },

    getDocumentTypeName(docType) {
      switch (docType) {
      case 'passport':
        return 'Passport';
      case 'national_identity_card':
        return 'National Identity Card';
      case 'driving_licence':
        return 'Driver License';
      case 'residence_permit':
        return 'Residence Permit';
      case 'visa':
        return 'Visa';
      default:
        return docType;
      }
    },

    showButtonRemove() {
      return this.$hasGrant(GRANTS.REMOVE_USERS);
    },
  },
};
</script>

<style src="./profile.scss" lang="scss" scoped></style>
