import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import store from '@/store';

import config from '@/config';
import { SINGLE_BASE_PAYMENT_DATA, SINGLE_FUll_PAYMENT_DATA, SINGLE_USER_DATA } from './const';

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
});

const logError = (error) => {
  //console.error(error);
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    logError('GraphQL - Error', {
      errors: graphQLErrors,
      operationName: operation.operationName,
      variables: operation.variables,
    });
  }

  if (networkError && networkError.statusCode === 401) {
    store.dispatch('logout');
  } else if (networkError) {
    logError('GraphQL - NetworkError', networkError);
  }
});

const token = localStorage.getItem('token');

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),

  cache: new InMemoryCache(),

  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default {
  changeDueDiligenceLevel: ({ id, dueDiligenceLevel }) => {
    let mutation = gql`
      mutation($id: ID!, $level: Int!) {
        changeDueDiligenceLevel(id: $id, level: $level) { ${SINGLE_USER_DATA} }
      }
    `;

    return client.mutate({
      mutation,
      variables: { id, level: parseInt(dueDiligenceLevel) },
    });
  },

  blockUser: ({ id, reason, bdid }) => {
    let mutation = gql`
      mutation($id: ID!, $reason: String!, $bdid: Boolean) {
        blockUser(id: $id, reason: $reason, byDeviceId: $bdid) { ${SINGLE_USER_DATA} }
      }
    `;

    return client.mutate({
      mutation,
      variables: { id, reason, bdid },
    });
  },

  editUserPhone: ({ id, phone }) => {
    let mutation = gql`
      mutation($id: ID!, $phone: String!) {
        editUserPhone(id: $id, phone: $phone) { ${SINGLE_USER_DATA} }
      }
    `;

    return client.mutate({
      mutation,
      variables: { id, phone },
    });
  },

  completePayment: ({ id }) => {
    let mutation = gql`
    mutation blockPayment($id: ID!) {
      completePayment(id: $id) { ${SINGLE_BASE_PAYMENT_DATA} }
    `;

    return client.mutate({
      mutation,
      variables: { id },
    });
  },

  refundPayment: ({ id, reason }) => {
    let mutation = gql`
    mutation blockPayment($id: ID!, $reason: String!) {
      refundPayment(id: $id, reason: $reason) { ${SINGLE_FUll_PAYMENT_DATA} }
    }
    `;

    return client.mutate({
      mutation,
      variables: { id, reason },
    });
  },

  unblockUser: ({ id }) => {
    let mutation = gql`
      mutation($id: ID!) {
        unblockUser(id: $id) { ${SINGLE_USER_DATA} }
      }
    `;

    return client.mutate({
      mutation,
      variables: { id },
    });
  },

  getCurrencyReport: ({ balanceDate, varianceFrom, varianceTo }) => {
    let q = gql`
      query ($bd: String, $vf: String, $vt: String) {
        systemCurrencyBalanceReport(balanceDate: $bd, varianceFrom: $vf, varianceTo: $vt)
      }
    `;

    return client.query({
      query: q,
      variables: {
        bd: balanceDate,
        vf: varianceFrom,
        vt: varianceTo,
      },
    });
  },

  getComplianceHistory: ({ userId }) => {
    let q = gql`
      query complianceHistory($u: ID!) {
        complianceHistory(userId: $u) {
          time
          event
          description
        }
      }
    `;

    return client.query({
      query: q,
      variables: {
        u: userId,
      },
    });
  },

  getUserList: ({ query, sortedKey, sortedDirection, first = 50, after = null }) => {
    let q = gql`
      query ($first: Int!, $after: String, $query: String, $sorted: String!, $direction: String) {
        usersConnection(
          first: $first
          after: $after
          query: $query
          sorted: $sorted
          direction: $direction
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              email
              firstName
              lastName
              phone
              dateOfBirth
              state
              locks {
                id
                name
              }
            }
          }
        }
      }
    `;

    return client.query({
      query: q,
      variables: {
        first,
        after,
        query,
        sorted: sortedKey,
        direction: sortedDirection,
      },
    });
  },

  getPayments: ({
    query,
    topups,
    sortedKey,
    sortedDirection,
    userId,
    first = 50,
    after = null,
  }) => {
    let q = gql`
      query(
        $first: Int!
        $after: String
        $userid: String
        $topups: Boolean
        $query: String
        $sorted: String
        $direction: String
      ) {
        paymentsConnection(
          first: $first
          after: $after
          userid: $userid
          topups: $topups
          query: $query
          sorted: $sorted
          direction: $direction
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {${SINGLE_FUll_PAYMENT_DATA}}
          }
        }
      }
    `;

    return client.query({
      query: q,
      variables: {
        first,
        after,
        query,
        userid: userId,
        topups: topups,
        sorted: sortedKey,
        direction: sortedDirection,
      },
    });
  },

  getUser: ({ id }) => {
    let q = gql`
      query ($id: ID!) {
        user(id: $id) {
          id
          email
          phone
          state
          firstName
          lastName
          dateOfBirth
          avatar
          createdAt
          dueDiligenceLevelExpiresAt
          dueDiligenceLevelStartedAt
          dueDiligenceLevel
          locks {
            id
            name
          }

          blockReasons

          baseCurrency {
            id
            code
            numberCode
            scale
            isActive
            symbol
            title
          }

          dueDiligenceLimit {
            incomeLimit
            cashWithdrawalLimit
            singleCashWithdrawalLimit
            incomeLimitUsed
            cashWithdrawalLimitUsed
          }

          pockets {
            id
            state
            createdAt
            isBase
            isCrypto
            isDefault
            balance
            balanceOnHold

            currency {
              id
              code
              numberCode
              scale
              isActive
              symbol
              title
            }

            cards {
              type
              number
              state
            }
          }

          address
          postalCode
          city

          countryOfResidence {
            title
          }

          country {
            title
          }
        }
      }
    `;

    return client.query({
      query: q,
      variables: {
        id,
      },
    });
  },
};
