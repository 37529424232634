<template>
  <div class="body-modal">
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <p class="modal_block__title">Update {{ user.firstName }} {{ user.lastName }}'s info</p>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">First name</span>
        <input v-model="firstName" class="modal_edit__input" autofocus />
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Last name</span>
        <input v-model="lastName" class="modal_edit__input" autofocus />
      </div>
    </div>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Date of birth</span>
        <input v-model="dateOfBirth" :class="{ 'error_block': invalidateDate() }" class="modal_edit__input" autofocus placeholder="YYYY-MM-DD" @input="filterNum" />
        <span v-if="!isValidAge" class="modal_edit__error">Age must be between 18 and 125.</span>
        <span v-if="!isValidFormat" class="modal_edit__error">Invalid date format (YYYY-MM-DD)</span>
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Phone number</span>
        <input v-model="phone" class="modal_edit__input" autofocus />
        <span v-if="isUserHasFrozenCard" class="modal_edit__error">Can't change the phone number.<br>The user has frozen card</span>
      </div>
    </div>
    <div class="flex_block">
      <div class="flex_item_block full">
        <span class="modal_edit__label">Email</span>
        <input v-model="email" class="modal_edit__input" autofocus />
      </div>
    </div>
    <div v-if="showPaysenderToggle()" class="flex_block">
      <div class="flex_item_block__paysender">
        <div class="paysender__title">
          Paysender
          <div class="paysender__desc">Has an access to GA features regardless of the phone number country</div>
        </div>
        <div class="paysender__toggle">
          <Toggle :enabled="isPaysender" @change="isPaysender = $event" />
        </div>
      </div>
    </div>
    <div v-if="showPaysenderToggle()" :class="{ 'paysender__details--expanded': isPaysender }" class="flex_block paysender__details">
      <div class="flex_item_block">
        <DropdownSelect
            v-model="office"
            class="modal_edit__dropdown"
            :label="'Office'"
        >
          <option value=""></option>
          <option v-for="o in offices" :key="o.id" :value="o.id">{{ o.label }}</option>
        </DropdownSelect>
      </div>
      <div class="flex_item_block">
        <DropdownSelect
            v-model="countryOfPhoneId"
            class="modal_edit__dropdown"
            :label="'Country of account'"
        >
          <option value=""></option>
          <option v-for="country in cnrForSignUp" :key="country.id" :value="country.id">{{ country.title }}</option>
        </DropdownSelect>
      </div>
    </div>
    <p class="modal_block__title">Residence address</p>
    <div class="flex_block">
      <div v-if="!updating" class="flex_item_block full">
        <span class="modal_edit__label">Country of residence</span>
        <input v-model="cnrName" class="modal_edit__input" @keyup="keyUpCnr" />
        <ul v-if="cnrItemsFiltered.length > 0" class="select-items">
          <li
            v-for="key in cnrItemsFiltered"
            :id="key.id"
            :key="key.title"
            class="item-country"
            @click="() => changeItem(key)"
          >
            {{ key.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">City</span>
        <input v-model="city" class="modal_edit__input" autofocus />
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Postal code</span>
        <input v-model="postalCode" class="modal_edit__input" autofocus />
      </div>
    </div>
    <div class="flex_block">
      <div class="flex_item_block full">
        <span class="modal_edit__label">Address line 1</span>
        <input v-model="addressLine1" class="modal_edit__input" autofocus />
      </div>
    </div>
    <div class="flex_block">
      <div class="flex_item_block full">
        <span class="modal_edit__label">Address line 2</span>
        <input v-model="addressLine2" class="modal_edit__input" autofocus />
      </div>
    </div>

    <Button
      id="modal_edit__btn"
      title="Update"
      size="tight"
      color="primaryFull"
      class="modal_edit__btn"
      :disabled="isUserHasFrozenCard || isDobInvalid"
      @click="
        click({
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: dateOfBirth,
          cnrId: cnrId,
          countryOfPhoneId: countryOfPhoneId || null,
          office: office || null,
          city: city,
          postalCode: postalCode,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          isPaysender: isPaysender,
          phone: phone,
          email: email,
        })
      "
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import DropdownSelect from '@/components/DropdownSelect/DropdownSelect.vue';
import Http from '@/services/http';
import { GRANTS } from '@/services/const';

export default {
  name: 'Modal',

  components: { Button, Toggle, DropdownSelect },

  props: {
    user: {
      type: Object,
      required: true,
    },
    offices: {
      type: Array,
      required: true,
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      isValidFormat: true,
      isValidAge: true,
      isDobInvalid: false,
      city: '',
      cnrId: 0,
      cnrName: '',
      countryOfPhoneId: 0,
      office: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      isPaysender: false,
      phone: '',
      email: '',
      cnrItemsFiltered: [],
      cnrItemsAll: [],
      cnrForSignUp: [],
      isUserHasFrozenCard: false,
      updating: true,
      pockets: [],
      keyUpCnr: (event) => {
        this.cnrId = 0;
        let value = event.target.value;
        this.cnrItemsFiltered = this.cnrItemsAll.filter(
          (item) => item.title.toLowerCase().indexOf(value.toLowerCase()) === 0,
        );
      },
      changeItem: (item) => {
        this.cnrId = item.id;
        this.cnrName = item.title;
        this.cnrItemsFiltered = [];
      },
      disableUpdateButton: false,
    };
  },

  computed: {
    userAge() {
      if (this.isValidFormat) {
        const birthDate = new Date(this.dateOfBirth);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          return age - 1;
        }
        return age;
      }
      return null;
    },
  },

  mounted() {
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.dateOfBirth = this.user.dateOfBirth;
    this.cnrId = this.user.countryOfResidence.id;
    this.cnrName = this.user.countryOfResidence.title;
    this.countryOfPhoneId = this.user.countryOfPhone.id;
    this.office = this.user.office;
    this.city = this.user.city;
    this.addressLine1 = this.user.addressLine1;
    this.addressLine2 = this.user.addressLine2;
    this.postalCode = this.user.postalCode;
    this.isPaysender = this.user.isPaysender;
    this.phone = this.user.phone;
    this.email = this.user.email;
    this.pockets = this.user.pockets;
    this.isUserHasFrozenCard = this.user.isUserHasFrozenCard;
  },

  created() {
    this.getCountries();
    window.addEventListener('click', this.eventClickListener, false);
    this.invalidateDate();
  },

  destroyed() {
    window.removeEventListener('click', this.eventClickListener, false);
  },

  methods: {
    getCountries() {
      this.updating = true;
      Http.getCountries()
        .then((data) => {
          this.cnrItemsAll = data.data.countries;
          this.cnrForSignUp = data.data.countries.filter(cnr => cnr['available-for-sign-up']).sort(cnr => cnr.title);
        })
        .finally(() => {
          this.updating = false;
        });
    },
    filterNum: function() {
      if (!/^\d+$/.test(this.dateOfBirth)) {
        this.dateOfBirth = this.dateOfBirth.replace(/[^\d\-]/g, '');
      }
    },

    invalidateDate() {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      this.isValidFormat = dateRegex.test(this.dateOfBirth);
      if (this.isValidFormat) {
        const age = this.userAge;
        this.isValidAge = age >= 18 && age < 125;
      }
      if (!this.isValidFormat || !this.isValidAge) {
        this.isDobInvalid = true;
        return true;
      } else {
        this.isDobInvalid = false;
        return false;
      }
    },

    eventClickListener(event) {
      if (event.target.className !== 'item-country') {
        if (this.cnrId === 0) {
          this.cnrId = this.user.countryOfResidence.id;
          this.cnrName = this.user.countryOfResidence.title;
          this.cnrItemsFiltered = [];
        }
      }
    },

    showPaysenderToggle() {
      return this.$hasGrant(GRANTS.CAN_CHANGE_PAYSENDER_FLAG);
    },
  },
};
</script>

<style src="./modalEditUserInfo.scss" lang="scss" scoped></style>
