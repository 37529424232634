export default {
  computed: {
    $loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    $hasGrant(grantName) {
      let grants = this.$store.state.grants;
      if (!grants) {
        return false;
      }
      return grants.split(',').indexOf(grantName) >= 0;
    },
  },
};
