import Vue from 'vue';
import Vuex from 'vuex';

import global from '@/store/global';
import users from '@/store/modules/users';
import user from '@/store/modules/user';
import grow from '@/store/modules/grow';
import paysenders from '@/store/modules/paysenders';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    ...global,

    modules: {
        users,
        user,
        grow,
        paysenders,
    },
});