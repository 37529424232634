<template>
  <div v-if="!updating">
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <div class="modal_block__header">
      <p class="modal_block__title">
        {{ title }}
      </p>
      <p v-if="subtitle" class="modal_block__subtitle">
        {{ subtitle }}
      </p>
    </div>
    <div v-if="!checkInfoSuccess" class="modal_block__body">
      <p>Username: {{ payment.toUserName }}</p>
      <p>Payer name: {{ extras['modulr_payer_name'] }}</p>
      <p>Reference: {{ extras['modulr_payment_reference'] }}</p>
      <p class="error-text">
        The user name and the payer's name do not match. Please confirm this operation
      </p>
      <Button
        :title="'Confirm'"
        size="tight"
        color="primaryFull"
        class="modal_block__btn"
        @click="checkInfoSuccess = true"
      />
    </div>
    <div v-else>
      <div v-if="!full" class="modal_block__form">
        <span class="modal_edit__label">Amount for refund</span>
        <imask-input
          v-model="value"
          class="modal_edit__input"
          autofocus
          :mask="Number"
          :max="payment.frAmtBase"
          radix="."
          :unmask="'typed'"
        />
      </div>
      <div class="modal_block__form">
        <span class="modal_block__label">Reason for {{ titleBtn.toLowerCase() }}</span>
        <textarea v-model="message" class="modal_block__input" autofocus />
      </div>
      <div v-if="checkbox" class="modal_block__row">
        <input id="checkbox" v-model="byDeviceId" class="modal_block__checkbox" type="checkbox" />
        <span class="modal_block__label2">{{ checkbox }}</span>
      </div>
      <div v-if="checkFSPayment()">
        <Button
          :title="'Override Account Number'"
          size="small"
          color="primaryFull"
          class="modal_block__overridebtn"
          @click="overrideAccountToggle()"
        />
        <div class="modal_block__row">

        </div>
        <div v-if='overrideAccount'>
          <div style="display: flex;">
            <div class="modal_block__form">
              <span class="modal_edit__label">Sort code</span>
              <input v-model="sortCode" class="modal_edit__input" />
            </div>
            <div class="modal_block__form">
              <span class="modal_edit__label">Account Number</span>
              <input v-model="accountNumber" class="modal_edit__input" />
            </div>
          </div>
          <div class="modal_block__form">
            <span class="modal_block__label">Recipient</span>
            <textarea v-model="recipient" class="modal_block__input" />
          </div>
          <div class="modal_block__form">
              <span class="modal_block__label">Reference</span>
              <textarea v-model="reference" class="modal_block__input" />
            </div>
        </div>
      </div>

      <Button
        :title="titleBtn"
        size="tight"
        color="orangeFull"
        class="modal_block__btn"
        :disabled="isFormValid() || !isOverrideAccountFilled()"
        @click="checkbox ? click(value, message, byDeviceId) : click(value, message, sortCode, accountNumber, recipient, reference)"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import { IMaskComponent } from 'vue-imask';
import Http from '@/services/http';

export default {
  name: 'Modal',

  components: {
    Button: Button,
    'imask-input': IMaskComponent,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    titleBtn: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    subtitle: {
      type: String,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    checkbox: {
      type: String,
      required: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    payment: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    full: {
      type: Boolean,
      required: false,
    },
    click: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      message: '',
      value: this.payment.frAmtBase,
      byDeviceId: true,
      extras: {},
      updating: false,
      needCheckInfo: false,
      checkInfoSuccess: false,
      overrideAccount: false,
      sortCode: '',
      accountNumber: '',
      recipient: '',
      reference: '',
    };
  },

  created() {
    this.getExtras(this.payment.id);
  },

  methods: {
    getExtras(payId) {
      this.updating = true;
      Http.getPaymentExtras({
        id: payId,
      })
        .then((data) => {
          this.extras = data;
        })
        .finally(() => {
          this.updating = false;
          if ([205, 206].indexOf(this.payment.frPrvId) >= 0) {
            this.needCheckInfo = true;
          }
          this.checkPayment();
        });
    },

    checkPayment() {
      if (!this.needCheckInfo) {
        this.checkInfoSuccess = true;
        return;
      }

      let payer = (this.extras['modulr_payer_name'] || '').toLowerCase();
      let reference = (this.extras['modulr_payment_reference'] || '').toLowerCase();
      let toUserName = (this.payment.toUserName || '').toLowerCase();

      if (toUserName) {
        let arrUserName = toUserName.split(' ');
        for (let i = 0; i < arrUserName.length; i++) {
          if (payer.indexOf(arrUserName[i]) >= 0 || reference.indexOf(arrUserName[i]) >= 0) {
            this.checkInfoSuccess = true;
          }
        }
      }
    },

    isFormValid() {
      return !this.message || this.message.replaceAll(' ', '').length < 5;
    },

    isOverrideAccountFilled() {

      if (!this.overrideAccount) {
        return true;
      }

      return this.sortCode && this.sortCode.replaceAll(' ', '').length > 0
        && this.accountNumber && this.accountNumber.replaceAll(' ', '').length > 0
        && this.recipient && this.recipient.replaceAll(' ', '').length > 0;
    },

    checkFSPayment() {
      if (this.payment.stsId !== 90) {
        return false;
      }

      return [205, 206].includes(this.payment.frPrvId);
    },

    overrideAccountToggle() {
      this.overrideAccount = !this.overrideAccount;
      this.sortCode = '';
      this.accountNumber = '';
      this.recipient = '';
      this.reference = '';
    }
  },
};
</script>

<style src="./modalRefund.scss" lang="scss" scoped></style>
