<template>
  <div class="picker_wrap">
    <el-date-picker
      v-model="content"
      type="datetimerange"
      :picker-options="pickerOptions"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      @input="handleInput"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  name: 'DateTimeInput',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
      pickerOptions: {
        shortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e);
    },
  },
};
</script>

<style src="./dateTimeInput.scss" lang="scss" scoped></style>
