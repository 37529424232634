<template>
  <main>
    <div class="bp__tab">
      <div class="bp__tab_item"
           :class="{active: tab === 'subscription'}"
           @click="tab = 'subscription'"
      >Subscription</div>
      <div class="bp__tab_item"
           :class="{active: tab === 'payments'}"
           @click="tab = 'payments'"
      >Payments</div>
      <div class="bp__tab_item"
           :class="{active: tab === 'statements'}"
           @click="tab = 'statements'"
      >Statements</div>
      <div class="bp__tab_item"
           :class="{active: tab === 'rewards'}"
           @click="tab = 'rewards'"
      >Rewards</div>
    </div>

    <div v-if="tab === 'subscription'">
      <UserPlusSubscription/>
    </div>

    <div v-if="tab === 'payments'">
      <UserPlusPayments/>
    </div>

    <div v-if="tab === 'statements'">
      <GrowStatements/>
    </div>

    <div v-if="tab === 'rewards'">
      <GrowRewards/>
    </div>
  </main>
</template>
<script>

import UserPlusSubscription from '@/views/User/UserPlus/UserPlusSubscription/UserPlusSubscription';
import GrowStatements from '@/views/User/UserGrow/GrowStatements/GrowStatements';
import GrowRewards from '@/views/User/UserGrow/GrowRewards/GrowRewards';
import UserPlusPayments from '@/views/User/UserPlus/UserPlusPayments/UserPlusPayments';
export default {
  name: 'UserPlus',
  components: {
    UserPlusPayments, GrowRewards,
    GrowStatements, UserPlusSubscription
  },

  data() {
    return {
      tab: 'subscription',
    }
  },
}
</script>

<style src="../UserGrow/userGrow.scss" lang="scss"></style>
<style src="./userPlus.scss" lang="scss" scoped></style>
