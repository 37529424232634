import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import AuthMixin from './mixins/auth';
import VModal from 'vue-js-modal';

import Datetime from 'vue-datetime';
import VueMoment from 'vue-moment';
import 'vue-datetime/dist/vue-datetime.css';
import TreeView from 'vue-json-tree-view';
import infiniteScroll from 'vue-infinite-scroll';
import Toasted from 'vue-toasted';
import ElementUI from 'element-ui';
import '@/assets/scss/element-variables.scss';
import locale from 'element-ui/lib/locale/lang/en';
import './validation.js'; //need for fields validations

Vue.use(Toasted, {
  action: {
    text: 'Close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
Vue.use(VModal, { dynamic: true });
Vue.use(Datetime);
Vue.use(VueMoment);
Vue.use(TreeView);
Vue.use(ElementUI, { locale });
Vue.use(infiniteScroll);

import('@/assets/scss/main.scss');

Vue.config.productionTip = false;

Vue.mixin(AuthMixin);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
