<template>
  <div class="users bp">
    <account-details :user="user" />
    <div v-if="user && user.pockets" class="balance">
      <p class="bp__title">
        Pocket Balances

        <Button
          v-if="showButtonRefund()"
          title="Refund balance"
          color="primary"
          size="small"
          @click="refundToCardModal()"
        />

        <v-select
          v-if="hasGrantToRecalcBalances()"
          ref="v-select-currencies"
          placeholder="Alternative currency"
          class="style-chooser currency-selector"
          :options="baseCurrencies"
          @input="loadRates"
        />
      </p>

      <div class="balance__pockets">
        <div class="balance__label">
          <span class="label__default">Currency</span>
          <span class="label__default">Available</span>
          <span
            v-if="rates !== undefined"
            class="label__default alt_column"
            v-text="selectedAltCur"
          />
          <span class="label__default">On hold</span>
          <span class="label__default type">Type</span>
          <span class="label__default cards">Linked Paysend cards</span>
          <span><!----></span>
        </div>

        <!-- eslint-disable-next-line -->
        <div v-for="(pocket, index) in user.pockets" :key="index" class="balance__pocket">
          <span>
            {{ pocket.currency.code }}
            <p v-if="pocket.default" class="opacity">default</p>
            <p v-if="pocket.base" class="opacity">base</p>
          </span>
          <span>{{ pocket.balance | formatMoney }}
            <p class="opacity">Overdraft: {{ pocket.overdraft | formatMoneyNotDivide }}</p></span>
          <span v-if="rates !== undefined" class="alt_column">
            {{ (pocket.balance / rates.get(pocket.currency.code)) | formatMoney }}
          </span>
          <span class="grey">{{ pocket.balanceOnHold | formatMoney }}</span>
          <span class="type" :class="{ grey: pocket.type === 'pocket' }">{{
            getPocketType(pocket.type)
          }}</span>
          <span class="cards" :class="{ grey: getNameCards(pocket.cards) === 'None' }">{{
            getNameCards(pocket.cards)
          }}</span>
          <span>
            <Button
              title="Download report"
              color="primary"
              size="small"
              class="mar-top"
              :disabled="loadReport"
              @click="downloadReport(pocket.id)"
            />
          </span>
          <span>
            <Button
              v-if="showExchangeButton(pocket.type)"
              title="Exchange"
              color="primary"
              size="small"
              class="mar-top"
              @click="exchange(pocket.id)"
            />
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="cryptoPockets && cryptoPockets.length"
      class="balance"
    >
      <p class="bp__title">Crypto Balances</p>

      <div class="balance__pockets">
        <div class="balance__label">
          <span class="label__default">Currency</span>
          <span class="label__default">Available</span>
          <span v-if="rates !== undefined" class="label__default alt_column" v-text="selectedAltCur"/>
          <span class="label__default">On hold</span>
          <span class="label__default type">Type</span>
          <span><!----></span>
        </div>
        <div
          v-for="(pocket, index) in cryptoPockets"
          :key="index"
          class="balance__pocket"
        >
          <span>{{pocket.asset.code}}
              <p class="opacity">{{pocket.asset.title}}</p>
          </span>
          <span>{{pocket.balance}}
              <p class="opacity">Overdraft: {{ pocket.overdraft }}</p>
          </span>
          <span class="grey">{{ pocket.balanceOnHold }}</span>
          <span class="grey">{{ getCryptoPocketType(pocket.type) }}</span>
        </div>
      </div>
    </div>

    <div class="userPayments">
      <div class="bp__tab">
        <div class="bp__tab_item"
             :class="{active: tab === 'payments'}"
             @click="tab = 'payments'"
        >Payments</div>
        <div class="bp__tab_item"
             :class="{active: tab === 'crypto-payments'}"
             @click="tab = 'crypto-payments'"
        >Crypto payments</div>
      </div>

      <div v-if="tab === 'payments'">
        <SearchPayments
          class="userPayments__search"
          :user="user.id"
          @queryChange="queryChanged"
        />

        <SearchResultsPayments class="userPayments__results" />
      </div>
      <div v-if="tab === 'crypto-payments'">
        <SearchCryptoPayments
          class="userPayments__search"
          :user="user.id"
          @queryChange="queryCryptoChanged"
        />
        <SearchResultsCryptoPayments class="userPayments__results" />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SearchResultsPayments from '@/views/Payments/SearchResultsPayments/SearchResultsPayments';
import AccountDetails from '@/views/User/AccountDetails/AccountDetails';
import SearchPayments from '@/components/SearchPayments/SearchPayments';
import ModalDates from '@/components/ModalDates/ModalDates';
import Http from '@/services/http';
import Button from '@/components/Button/Button';
import ModalRefundToCard from '@/components/ModalRefundToCard/ModalRefundToCard.vue';
import ModalExchange from '@/components/ModalExchange/ModalExchange';
import { GRANTS } from '@/services/const';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import SearchResultsCryptoPayments
  from '@/views/Payments/SearchResultsCryptoPayments/SearchResultsCryptoPayments';
import SearchCryptoPayments from '@/components/SearchCryptoPayments/SearchCryptoPayments';

const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'BalanceAndPayments',
  components: {SearchPayments, SearchResultsPayments, SearchCryptoPayments, SearchResultsCryptoPayments, Button, vSelect, AccountDetails},

  data() {
    return {
      tab: 'payments',
      loadReport: false,
      rates: undefined,
      selectedAltCur: '',
      baseCurrencies: ['EUR', 'USD', 'GBP'],
      filter: {},
      cryptoFilter: {}
    }
  },

  computed: {
    ...mapGetters(['user','cryptoPockets']),
  },

  created() {
    //console.log(this.$grants);
    let userId = this.$route.params.userId;
    this.getUser(userId);
    this.getCryptoPockets(userId);
    // this.getPayments({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getUser', 'getPayments', 'getCryptoPockets', 'getCryptoPayments']),

    queryChanged(filter) {
      this.filter = filter;
      this.getPayments({ filter: filter });
    },

    queryCryptoChanged(filter) {
      this.cryptoFilter = filter
      this.getCryptoPayments({filter: filter});
    },

    loadRates(currencyIsoCode) {
      if (currencyIsoCode == null) {
        this.rates = undefined;
      } else {
        Http.getRates({ currencyIsoCode: currencyIsoCode }).then((response) => {
          this.rates = new Map(response.map((i) => [i.destination.code, i.value]));
        });
      }

      this.selectedAltCur = currencyIsoCode;
    },

    getPocketType(type) {
      switch (type) {
      case 'pocket':
        return 'Account';
      case 'deposit':
        return 'Credit Builder Limit Account';
      case 'credit':
        return 'Credit Builder Account';
      case 'bnpl':
        return 'Pay Later Account';
      default:
        return 'None';
      }
    },

    getCryptoPocketType(type){
      switch (type){
      case 'pocket':
        return 'Pocket';
      default:
        return 'None'
      }
    },

    getNameCards(cards) {
      let cardsArr = cards
        .filter((card) => {
          if (card.state === 'active' || card.state === 'frozen_by_user') {
            return true;
          }
        })
        .map((card) => {
          if (card.state === 'active') {
            return `${card.type} ${card.number}`;
          } else if (card.state === 'frozen_by_user') {
            return `${card.type} ${card.number} (frozen)`;
          }
        });

      if (cardsArr.length) {
        return cardsArr.join(',\n');
      } else {
        return 'None';
      }
    },

    downloadReport(pktId) {
      this.$modal.show(
        ModalDates,
        {
          title: 'Download report',
          description: 'You want to download a report',
          click: (dates) => {
            this.$modal.hide('modalConfirm');
            this.downloadFile(pktId, dates);
          },
        },
        {
          name: 'modalConfirm',
          width: '500',
          height: '300',
        },
      );
    },

    exchange(pktId) {
      this.$modal.show(
        ModalExchange,
        {
          targetCurrencies: this.user.pockets
            .filter((p) => p.id !== pktId)
            .map((p) => p.currency.code),
          pockets: (this.user.pockets || [])
            .filter((item) => item.id === pktId)
            .map((item) => {
              return {
                label: item.currency.code,
                value: item.currency.id,
                balance: item.balance,
              };
            }),
          success: (_) => {
            this.$modal.hide('modalExchange');
            this.queryChanged(this.filter);
            this.getUser(this.$route.params.userId);
          },
        },
        {
          name: 'modalExchange',
          height: '570',
        },
      );
    },

    downloadFile(pktId, dates) {
      this.loadReport = true;
      Http.downloadReportPocket({
        pktId,
        filter: { frDate: dates[0], toDate: dates[1] },
      })
        .then((data) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = 'pocket_report_' + new Date().toISOString() + '.pdf';
          document.body.appendChild(link); // Required for FF
          link.click();
          link.remove();
        })
        .finally(() => {
          this.loadReport = false;
        });
    },

    refundToCardModal() {
      this.$modal.show(
        ModalRefundToCard,
        {
          pockets: (this.user.pockets || [])
            .filter((item) => item.balance > 0 && item.type === 'pocket')
            .map((item) => {
              return {
                label: item.currency.code,
                value: item.currency.id,
                balance: item.balance,
              };
            }),
          success: (data) => {
            this.$modal.hide('modalRefundToCard');
            this.queryChanged(this.filter);
            this.getUser(this.$route.params.userId);
          },
        },
        {
          name: 'modalRefundToCard',
          height: '570',
        },
      );
    },

    showExchangeButton(pktType) {
      return this.hasGrantToExchange() && pktType === 'pocket';
    },

    showButtonRefund() {
      return this.$hasGrant(GRANTS.CREATE_CUSTOM_REFUNDS_TO_CARD);
    },

    hasGrantToRecalcBalances() {
      return this.$hasGrant(GRANTS.RECALC_BALANCES);
    },

    hasGrantToExchange() {
      return this.$hasGrant(GRANTS.CREATE_PAYMENT_EXCHANGE);
    },
  },
};
</script>

<style src="./balanceAndPayments.scss" lang="scss" scoped></style>
