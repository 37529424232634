import Service from '@/services/graphql';
import Http from '@/services/http';
import router from '@/router';
import _ from 'lodash';

const state = {
  query: '',
  users: [],
  hasNextPage: false,
  nextCursor: null,

  sortedKey: 'firstName',
  sortedDirection: 'asc',
};

const getters = {
  users: state => state.users,
  query: state => state.query,
  sortedKey: state => state.sortedKey,
  sortedDirection: state => state.sortedDirection,
  filters: state => {
    return {
      firstName: {
        title: 'Name',
        active: state.sortedKey === 'firstName',
      },

      phone: {
        title: 'Phone',
        active: state.sortedKey === 'phone',
      },

      email: {
        title: 'Email',
        active: state.sortedKey === 'email',
      },

      dob: {
        title: 'DOB',
        active: state.sortedKey === 'dob',
      },

      state: {
        title: 'Status',
        active: state.sortedKey === 'state',
      },
    };
  },
  pageInfo: state => state.pageInfo,
  nextCursor: state => state.nextCursor,
  hasNextPage: state => state.hasNextPage,
};

const actions = {
  queryChanged({ commit, dispatch }, { query }) {
    commit('QUERY_CHANGED', { query });
    dispatch('getUsers', { query });
  },

  sortedKeyChanged({ commit, state, dispatch }, { sortedKey }) {
    if (sortedKey === state.sortedKey) {
      commit('SORTED_DIRECTION_CHANGED');
    } else {
      commit('SORTED_DIRECTION_RESETED');
    }

    commit('SORTED_KEY_CHANGED', { sortedKey });
    dispatch('getUsers', { query: state.query, sortedKey });
  },

  getMoreUsers({ dispatch, state }) {
    if (state.hasNextPage) {
      dispatch('getUsers', {
        query: state.query,
        after: state.nextCursor,
        sortedKey: state.sortedKey,
        nextPage: true,
      });
    }
  },

  async getUsers(
    { commit, state },
    { query, after, sortedKey = state.sortedKey, nextPage = false },
  ) {
    if (!nextPage) {
      commit('LOADING_START', null, { root: true });
    }

    let {
      data: {
        usersConnection: { pageInfo, edges },
      },
    } = await Http.getUserList({
      query,
      sortedKey,
      after,
      sortedDirection: state.sortedDirection,
      isPaysender: true,
    }).finally(() => {
      commit('LOADING_STOP', null, { root: true });
    });

    commit('USERS_LOADED', { edges, query, pageInfo, nextPage });
  },
};

const mutations = {
  SORTED_DIRECTION_CHANGED(state) {
    if (state.sortedDirection === 'asc') {
      state.sortedDirection = 'desc';
    } else {
      state.sortedDirection = 'asc';
    }
  },

  SORTED_DIRECTION_RESETED(state) {
    state.sortedDirection = 'asc';
  },

  QUERY_CHANGED(state, { query }) {
    state.users = [];
    state.query = query;
  },

  SORTED_KEY_CHANGED(state, { sortedKey }) {
    state.users = [];
    state.sortedKey = sortedKey;
  },

  USERS_LOADED(state, { edges, query, pageInfo, nextPage }) {
    state.hasNextPage = pageInfo.hasNextPage;
    state.query = query;

    if (edges.length) {
      if (nextPage) {
        state.users = state.users.concat(
          edges.filter(e => state.users.map(u => u.id).indexOf(e.node.id) < 0).map(e => e.node),
        );
      } else {
        state.users = edges.map(e => e.node);
      }
      // console.log(state.users)

      state.nextCursor = _.last(edges).cursor;
    } else {
      state.nextCursor = null;
    }

    router.push({ name: 'paysenders', query: { query } });
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
