<template>
  <main>
    <div class="whitelists__results">
      <div class="search">
          <v-select
            v-model="type"
            placeholder="Type of list"
            class="style-chooser"
            :options="typesWL"
            @input="loadPhones"
          ></v-select>
        <input
          v-model.trim="search"
          class="search__input image"
          type="text"
          placeholder="Search"
          @keyup="filter()"
        />
        <Button
          v-if="isValidPhone() && hasGrantGrowWhitelists()"
          title="Add"
          color="primaryFull"
          size="fit"
          class="button-add"
          @click="addPhone()"
        />
      </div>
    </div>
    <div v-if="$loading" class="whitelists__results">
      <Spinner class="whitelists__spinner" />
    </div>
    <div v-else class="whitelists__results">
      <span v-if="!filteredPhonesWL.length" class="whitelists__noresults">No phones</span>
      <div v-else class="whitelists__list">
        <div class="whitelists__label">
          <span class="whitelists__default">#</span>
          <span class="whitelists__default">Phone</span>
        </div>
        <div v-for="(phone, index) in filteredPhonesWL" :key="index" class="whitelists__item">
          <span class="index">{{ index + 1 }}</span>
          <span class="phone">{{ phone }}</span>
          <Button
            v-if="hasGrantGrowWhitelists()"
            class="btn_right max"
            title="Remove"
            color="orangeFull"
            size="small"
            @click="deletePhone(phone)"
          />
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import {createNamespacedHelpers} from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import vSelect from 'vue-select';
import Button from '@/components/Button/Button';
import {GRANTS} from '@/services/const';

const { mapGetters, mapActions } = createNamespacedHelpers('grow');

export default {
  name: 'Grow',
  components: {
    Spinner, Button,
    'v-select': vSelect,
  },

  data() {
    return {
      type: null,
      search: '',
    }
  },

  computed: {
    ...mapGetters(['typesWL', 'filteredPhonesWL']),
  },

  mounted() {
    this.type = this.typesWL[0];
    this.getPhonesWL({type: this.typesWL[0].id})
  },

  methods: {
    ...mapActions(['getPhonesWL','filterPhones','deletePhoneWl','addPhoneWl']),
    loadPhones(type){
      if (type === null){
        return;
      }
      this.search = '';
      this.getPhonesWL({type: type.id})
    },
    filter(){
      this.filterPhones({search: this.search})
    },
    deletePhone(phone){
      this.deletePhoneWl({type: this.type.id, phone})
    },
    addPhone(){
      this.addPhoneWl({type: this.type.id, phone: this.search})
    },
    isValidPhone(){
      return this.search.length >= 10 && isNaN(this.search) === false;
    },
    hasGrantGrowWhitelists(){
      return this.$hasGrant(GRANTS.CAN_WORK_WITH_GROW_WHITELISTS)
    },
  },
}
</script>
<style src="./whitelists.scss" lang="scss" scoped></style>
