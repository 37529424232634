<template>
  <div
    v-infinite-scroll="getMoreUsers"
    infinite-scroll-disabled="$loading"
    infinite-scroll-distance="20"
  >
    <div v-if="$loading" class="users__results">
      <Spinner class="users__spinner" />
    </div>

    <div v-else-if="query != ''" class="users__results">
      <div class="row__label">
        <span
          v-for="(value, key) in filters"
          :id="key"
          :key="key"
          class="results__column"
          :class="[
            value.title.toLowerCase(),
            {
              sorted__label_asc: sortedKey === key && sortedDirection == 'asc',
              sorted__label_desc: sortedKey === key && sortedDirection == 'desc',
            },
          ]"
          @click="sortedKeyChanged({ sortedKey: key })"
          >{{ value.title }}</span>
      </div>

      <span v-if="!users.length" class="users__noresults">No results</span>

      <div v-else>
        <div
          v-for="user in users"
          :key="user.id"
          class="results__row"
          @click="showUserProfile(user.id)"
        >
          <div class="row__wrapper">
            <p class="results__column name">
              <span
                class="firstName"
                :class="{
                  active: user.firstName && query && user.firstName.toUpperCase() === query.toUpperCase(),
                }"
                >{{ user.firstName }}</span>
              <span
                :class="{
                  active: user.lastName && query && user.lastName.toUpperCase() === query.toUpperCase(),
                }"
              >
                {{ user.lastName }}
              </span>
            </p>
            <span class="results__column phone" :class="{ active: user.phone === query }">{{
              user.phone | formatPhone
            }}</span>
            <span
              class="results__column email"
              :class="{ active: user.email && query && user.email.toUpperCase() === query.toUpperCase() }"
              >{{ user.email }}</span>
            <span class="results__column dob">{{ user.dateOfBirth | formatDate }}</span>
            <span
              style="color: #00b84d; text-transform: capitalize"
              class="results__column status"
              :class="{ blocked: user.state === 'blocked' }"
            >
              <span v-if="user.state === 'blocked'">blocked</span>
              <span v-if="user.state != 'blocked'">{{ user.state }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';

export default {
  name: 'SearchResults',

  components: {
    Spinner,
  },

  props: {
    namespace: {
      type: String,
      required: true
    }
  },

  computed: mapState({
    state (state) {
      return state[this.namespace]
    },
    users (state, getters) {
      return getters[this.namespace + '/users']
    },
    query (state, getters) {
      return getters[this.namespace + '/query']
    },
    filters (state, getters) {
      return getters[this.namespace + '/filters']
    },
    sortedUsers (state, getters) {
      return getters[this.namespace + '/sortedUsers']
    },
    sortedKey (state, getters) {
      return getters[this.namespace + '/sortedKey']
    },
    sortedDirection (state, getters) {
      return getters[this.namespace + '/sortedDirection']
    },
  }),

  methods: {
    ...mapActions({
      sortedKeyChanged (dispatch, payload) {
        return dispatch(this.namespace + '/sortedKeyChanged', payload)
      },
      getMoreUsers (dispatch, payload) {
        return dispatch(this.namespace + '/getMoreUsers', payload)
      },
    }
    ),

    showUserProfile(userId) {
      this.$router.push({
        name: this.namespace + '-profile',
        params: { userId },
      });
    },
  },
};
</script>

<style src="./searchResultsUsers.scss" lang="scss" scoped></style>
