<template>
  <main>
    <modals-container />
    <div v-if="$loading" class="grow__results">
      <Spinner class="grow__spinner" />
    </div>

    <div v-else class="grow__results">
      <h2>Product Information</h2>
      <span v-if="!growSubscriptionInfo" class="grow__noresults">Error getting information about products</span>
      <div v-else>
        <div class="info__subscription">
          <div v-if="!!growSubscriptionInfo.freePeriodStatus"
               class="info__row"
          >
            <span class="info__label">Free Trial Status (Left {{ growSubscriptionInfo.leftFreePeriodDays }} of {{ growSubscriptionInfo.freePeriodDays }})</span>
            <span>{{ (growSubscriptionInfo.freePeriodStatus === 'ACTIVE') ? 'Expires on ' : 'Expired on' }} {{ growSubscriptionInfo.expireTs | formatTime }}</span>
          </div>
          <div v-if="growSubscriptionInfo.nextPaymentDate"
               class="info__row"
          >
            <span class="info__label">Next Subscription Payment</span>
            <span>{{ growSubscriptionInfo.nextPaymentDate| formatDate }}</span>
          </div>

          <Button
            v-if="userProducts().length && hasGrantUnsubscribe()"
            class="btn_right max"
            title="Unsubscribe"
            color="orangeFull"
            size="small"
            @click="cancelGrowModal(userProducts())"
          />
        </div>

        <GrowProducts :products="growSubscriptionInfo.products"
                      :user-products="userProducts()"
                      :show-cancel-button="hasGrantUnsubscribe()"
                      @cancel="cancelGrowModal"
        ></GrowProducts>
      </div>
      <br/><br/>



      <h2>Subscription history</h2>
      <span v-if="!growStatuses.length" class="grow__noresults">User doesn't have history</span>
      <div v-else class="grow__list">
        <div class="grow__label">
          <span class="label__default">Change</span>
          <span class="label__default">Status</span>
          <span class="label__default">Products</span>
          <span class="label__default">Reasons</span>
        </div>

        <!-- eslint-disable-next-line -->
        <div v-for="(status, index) in growStatuses" :key="index">
          <div v-if="countHistory > index" class="grow__item">
            <span>{{ status.changeTs | formatTime }}</span>
            <span>{{ status.status }} <p v-if="index===0" class="opacity">CURRENT</p></span>
            <span><p v-for="(product, indexP) in getList(status.addInfo.products)"
                     :key="indexP"
            >{{ growProducts[product] || product }}</p></span>
            <span><p v-for="(reason, indexR) in getList(status.addInfo.reasons)"
                     :key="indexR"
            >{{ reason }}</p></span>
          </div>
        </div>

        <br/>
        <p class="text-center">
          <a v-if="countHistory > 5"
             class="link"
             @click="lessHistory"
          >Less</a>
          <a v-if="countHistory < growStatuses.length"
             class="link"
             @click="moreHistory"
          >More</a>
        </p>
      </div>
    </div>

    <GrowStatements/>

    <GrowRewards/>
  </main>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import ModalReason from '@/components/ModalReason/ModalReason';
import Button from '@/components/Button/Button';
import GrowProducts from '@/views/User/UserGrow/GrowProducts/GrowProducts';
import {GRANTS} from '@/services/const';
import GrowStatements from '@/views/User/UserGrow/GrowStatements/GrowStatements';
import GrowRewards from '@/views/User/UserGrow/GrowRewards/GrowRewards';

const { mapGetters, mapActions } = createNamespacedHelpers('user');
const grow = createNamespacedHelpers('grow');

export default {
  name: 'UserGrow',
  components: {
    GrowStatements,
    GrowRewards,
    Button, Spinner, GrowProducts,
  },

  data() {
    return {
      countHistory: 5,
    };
  },

  computed: {
    ...mapGetters(['growStatuses', 'growSubscriptionInfo']),
    ...grow.mapGetters(['growProducts'])
  },

  created() {
    this.getGrowInfo({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getGrowInfo', 'cancelGrow']),

    userProducts() {
      if (this.growStatuses.length) {
        let lastState = this.growStatuses[0];
        return lastState.status === 'SUBSCRIBED' ? this.getList(lastState.addInfo.products) : [];
      }else{
        return []
      }
    },

    cancelGrowModal(products) {
      let title = 'Unsubscribe from Grow';
      let button = 'Unsubscribe';
      if (products.length !== this.userProducts().length){
        title = `Disable products: ${products.map(i => this.growProducts[i] || i).join(',')}`;
        button = 'Disable'
      }
      this.$modal.show(ModalReason, {
        title: title,
        titleBtn: button,
        click: (message) => {
          this.cancelGrow({
            userId: this.$route.params.userId,
            products: products,
            reason: message,
          });
          this.$modal.hide('disableGrowProduct');
        },
      }, {
        name: 'disableGrowProduct',
        height: '400'
      });
    },

    hasProduct(product) {
      return this.userProducts().indexOf(product) >= 0;
    },

    getList(list){
      return list || [];
    },

    moreHistory(){
      this.countHistory += 5;
    },

    lessHistory(){
      this.countHistory -= 5;
    },

    hasGrantUnsubscribe(){
      return this.$hasGrant(GRANTS.CAN_UNSUBSCRIBE_GROW)
    },
  },
};
</script>

<style src="./userGrow.scss" lang="scss"></style>
