<template>
  <div class="body-modal">
    <button class="modal_block__closebtn" @click="$emit('close')">
      <img src="~@/assets/img/close.svg" />
    </button>
    <div class="modal_block__header">
      <p class="modal_block__title">Refund to card</p>
      <p class="modal_block__subtitle">Enter the card number for withdrawal of funds</p>
    </div>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Amount for refund</span>
        <imask-input
          v-model="amount"
          class="modal_edit__input"
          autofocus
          :mask="Number"
          radix="."
          :unmask="'typed'"
          :max="max"
        />
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Card number</span>
        <imask-input
          v-model="pan"
          class="modal_edit__input"
          autofocus
          :mask="'0000 0000 0000 0000 0'"
          radix="."
          :unmask="'typed'"
        />
      </div>
    </div>
    <div class="flex_block">
      <div class="flex_item_block">
        <span class="modal_edit__label">Pocket currency</span>
        <v-select
          v-model="frCurrency"
          placeholder="Pocket currency"
          class="style-chooser"
          :options="pockets"
          @input="changeCurrency"
        ></v-select>
      </div>
      <div class="flex_item_block">
        <span class="modal_edit__label">Card currency</span>
        <v-select
          v-model="toCurrency"
          placeholder="Card currency"
          class="style-chooser"
          :options="currencies"
        ></v-select>
      </div>
    </div>
    <div class="modal_block__form">
      <span class="modal_block__label">Comment</span>
      <textarea v-model="comment" class="modal_block__input" />
    </div>
    <Button
      title="Refund"
      size="tight"
      color="orangeFull"
      class="modal_block__btn"
      :disabled="loadRefund || amount === 0 || !comment || comment.length < 5"
      @click="refund"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import { IMaskComponent } from 'vue-imask';
import vSelect from 'vue-select';
import { createNamespacedHelpers } from 'vuex';
import Http from '@/services/http';
const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'Modal',

  components: {
    Button: Button,
    'imask-input': IMaskComponent,
    'v-select': vSelect,
  },

  props: {
    pockets: {
      type: Array,
      required: true,
    },
    success: {
      type: Function,
      required: true,
    },
  },

  data() {
    let balance = this.getBalance(this.pockets[0].balance);
    return {
      loadRefund: false,
      frCurrency: this.pockets[0],
      toCurrency: null,
      amount: balance,
      pan: '',
      comment: '',
      max: balance,
      currencies: [],
    };
  },

  computed: {
    ...mapGetters(['user']),
  },

  created() {
    this.getCurrencies();
  },

  methods: {
    ...mapActions(['refundPaymentToCard']),

    changeCurrency() {
      this.amount = this.frCurrency ? this.getBalance(this.frCurrency.balance) : 0.0;
      this.setActiveTo();
      this.max = this.amount;
    },
    getBalance(balance) {
      return balance / 100;
    },

    refund() {
      this.loadRefund = true;
      this.refundPaymentToCard({
        amount: this.amount,
        frCurId: this.frCurrency.value,
        toCurId: this.toCurrency ? this.toCurrency.id : this.frCurrency.value,
        comment: this.comment,
        pan: this.pan.replace(/\s/g, ''),
        userId: this.user.id,
        expDate: null,
      })
        .finally(() => {
          this.loadRefund = false;
        })
        .then(() => {
          this.success();
        });
    },

    getCurrencies() {
      Http.getCurrencies().then((data) => {
        this.currencies = data.data.currencies;
        this.setActiveTo();
      });
    },

    setActiveTo() {
      this.toCurrency = this.frCurrency
        ? this.currencies.find((item) => item.id === this.frCurrency.value)
        : null;
    },
  },
};
</script>

<style src="./modalRefundToCard.scss" lang="scss" scoped></style>
