<template>
  <main>
    <div v-if="$loading" class="results_loaded">
      <Spinner class="cards__spinner" />
    </div>
    <modals-container />
    <div v-if="!$loading && onfido && onfido.fullReport != null">
      <div class="kyc__info">
        <div class="info max">
          <p class="info__title">Summary result</p>
          <div class="info__row">
            <span class="info__label">Success</span>
            <span
              v-if="onfido.fullReport.validationSuccess != null"
              :class="onfido.fullReport.validationSuccess ? 'info__state' : 'info__state__failed'"
              >{{ onfido.fullReport.validationSuccess }}</span>
            <span v-else>-</span>
          </div>
          <div class="info__row">
            <span class="info__label">Status</span>
            <span
              v-if="onfido.simpleReport.status != null"
              :class="onfido.simpleReport.status === 'ok' ? 'info__state' : 'info__state__failed'"
              >{{ onfido.simpleReport.status }}</span>
            <span v-else>-</span>
          </div>

          <div class="info__row">
            <span class="info__label">Errors</span>
            <div v-if="onfido.fullReport.errorMessages">
              <p v-for="(error, i) in onfido.fullReport.errorMessages" :id="i" :key="i">
                {{ error }}
              </p>
            </div>
            <div v-else>-</div>
          </div>
        </div>
      </div>

      <h2>Reports</h2>
      <div v-if="onfido.simpleReport.reports" class="kyc__info">
        <div v-for="(report, i) in onfido.simpleReport.reports" :id="i" :key="i" class="info">
          <div class="info__row"><span class="info__label">Type</span>{{ report.type }}</div>
          <div class="info__row">
            <span class="info__label">Status</span>
            <span :class="report.status === 'ok' ? 'info__state' : 'info__state__failed'">{{
              report.status
            }}</span>
          </div>
          <div class="info__row">
            <span class="info__label">Errors</span>
            <div v-if="report.errors">
              <p v-for="(error, j) in report.errors" :id="j" :key="j">{{ error }}</p>
            </div>
            <div v-else>-</div>
          </div>
        </div>
      </div>
      <div v-else>Reports not found</div>

      <h2>Documents</h2>

      <div v-if="onfido.fullReport.reports" class="kyc__info">
        <div
          v-for="document in onfido.fullReport.reports"
          :id="document.document.id"
          :key="document.document.id"
          class="info"
        >
          <div class="info__row"><span class="info__label">ID</span>{{ document.document.id }}</div>
          <div class="info__row">
            <span class="info__label">Created at</span>{{ getDateString(document.document.createdAt) }}
          </div>
          <div class="info__row">
            <span class="info__label">File name</span>{{ document.document.fileName || '-' }}
          </div>
          <div class="info__row">
            <span class="info__label">File size</span>{{ getSize(document.document.fileSize) || '-' }}
          </div>
          <div class="info__row">
            <span class="info__label">Type</span>{{ document.document.type || '-' }}
          </div>
          <div class="info__row">
            <span class="info__label">Side</span>{{ document.document.side || '-' }}
          </div>
          <div class="info__row">
            <span class="info__label">Reports</span>{{ document.reports.length }}
          </div>

          <!--          <p><a href="#" class="info__link">Download</a></p>-->

          <Button
            class="btn_right"
            title="Show reports"
            color="orange"
            size="tight"
            @click="showModalReports(document.reports)"
          />
        </div>
      </div>

      <div v-else>Documents not found</div>
    </div>

    <div v-if="!$loading && onfido && onfido.report">
      <div class="kyc__info">
        <div class="info max">
          <p class="info__title">Overall Summary</p>
          <div class="info__row">
            <span class="info__label">Applicant ID</span>
            <span v-if="onfido.report.applicantId != null">{{ onfido.report.applicantId }}</span>
            <span v-else>-</span>
          </div>
          <div class="info__row">
            <span class="info__label">Check Ids</span>
            <span>
              <p v-for="checkId in onfido.report.checkIds" :key="checkId">{{ checkId }}</p>
              <p v-if="onfido.report.checkIds.length === 0">-</p>
            </span>
          </div>
        </div>
      </div>

      <h2>Checks</h2>
      <div v-if="onfido.report.checks.length">
        <div class="kyc__info">
          <div v-for="(check, i) in onfido.report.checks" :id="i" :key="i" class="info max">
            <div class="info__row"><span class="info__label">Check</span>{{ check.checkId }}</div>
            <div class="info__row">
              <span class="info__label">Time</span>{{ check.dateTime | formatTime }}
            </div>
            <div v-if="check.documentType" class="info__row">
              <span class="info__label">Document</span>{{ check.documentType }}
            </div>
            <div v-if="check.documentSide" class="info__row">
              <span class="info__label">Side</span>{{ check.documentSide }}
            </div>
            <div class="info__row">
              <span class="info__label">Result</span>
              <span
                v-if="check.result != null"
                :class="check.result ? 'info__state' : 'info__state__failed'"
                >{{ check.result ? 'OK' : 'FAIL' }}</span>
              <span v-else>-</span>
            </div>
            <div class="info__row">
              <span class="info__label">Errors</span>
              <div v-if="check.errors.length">
                <p v-for="(error, j) in check.errors" :id="j" :key="j">{{ error }}</p>
              </div>
              <div v-else>-</div>
            </div>
            <h3>Reports</h3>
            <div v-if="check.reports.length">
              <div v-for="(report, j) in check.reports" :id="j" :key="j" class="info">
                <div class="info__row"><span class="info__label">ID</span>{{ report.id }}</div>
                <div class="info__row">
                  <span class="info__label">Name</span>{{ report.title || report.name }}
                </div>
                <div class="info__row">
                  <span class="info__label">Status</span>{{ report.status }}
                </div>
                <div class="info__row">
                  <span class="info__label">Result</span>
                  <span v-if="report.result != null">{{ report.result }}</span>
                  <div v-else>-</div>
                </div>
                <div class="info__row">
                  <span class="info__label">Sub Result</span>
                  <span v-if="report.subResult != null">{{ report.subResult }}</span>
                  <div v-else>-</div>
                </div>
              </div>
            </div>
            <div v-else>-</div>
          </div>
        </div>

        <h3 class="label_h">
          For older checks please visit
          <a href="https://dashboard.onfido.com" target="_blank">Onfido dashboard</a>
        </h3>
        <br />
      </div>
      <div v-else>Checks not found</div>
    </div>

    <div v-if="!$loading && onfido && onfido.report == null && onfido.fullReport == null">
      <div class="kyc__info">
        <p class="no-data">No data available</p>
      </div>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import JsonReportOnfidoModal from '@/components/JsonReportOnfidoModal/JsonReportOnfidoModal';
import Button from '@/components/Button/Button';

const { mapGetters, mapActions } = createNamespacedHelpers('user');

export default {
  name: 'Kyc',
  components: {
    Spinner,
    Button,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['onfido']),
  },

  created() {
    this.getOnfidoReports({ userId: this.$route.params.userId });
  },

  methods: {
    ...mapActions(['getOnfidoReports']),
    getDateString(obj) {
      let dateTime = obj.dateTime;
      let date = dateTime.date;
      let time = dateTime.time;
      return `${date.day}.${date.month}.${date.year} ${time.hour}:${time.minute}:${time.second}`;
    },
    getSize(fileSizeInBytes) {
      // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
      let fileSizeInKB = fileSizeInBytes / 1024;
      // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
      let fileSizeInMB = fileSizeInKB / 1024;
      return fileSizeInMB + 'Mb';
    },
    showModalReports(reports) {
      this.$modal.show(
        JsonReportOnfidoModal,
        {
          reports: reports,
        },
        { scrollable: true, width: '700', height: 'auto' },
      );
    },
  },
};
</script>

<style src="./kyc.scss" lang="scss" scoped></style>
