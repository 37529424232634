<template>
  <div class="user_fraud">
    <div v-if="!complianceHistory.length">
      <Spinner />
    </div>

    <div v-else-if="complianceHistory.length">
      <div class="row__label">
        <span
          v-for="key in filters"
          :id="key"
          :key="key"
          class="results__column"
          :class="[
            key.toLowerCase(),
            {
              sorted__label_asc: sortedKey === key && sortedDirection == 'asc',
              sorted__label_desc: sortedKey === key && sortedDirection == 'desc',
            },
          ]"
          @click="sortedKeyChanged({ sortedKey: key })"
          >{{ key }}</span>
      </div>

      <div v-for="(event, id) in complianceHistory" :key="id" class="results__row">
        <div v-if="event.event" class="row__wrapper">
          <span class="results__column time">{{ event.time | formatTime }}</span>
          <span
            class="results__column event"
            :class="{
              blocked: event.event.toLowerCase().includes('blocked'),
              unblocked: event.event.toLowerCase().includes('unblocked'),
            }"
            >{{ event.event }}</span>
          <span class="results__column source">{{ event.source || '-' }}</span>
          <span class="results__column description">{{ event.description || '-' }}</span>
          <!--          <span class="results__column validity">{{ event.validity || '-'}}</span>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Spinner from '@/components/Spinner/Spinner';

const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'FraudAndCompliance',

  components: {
    Spinner,
  },

  data() {
    return {
      filters: ['time', 'event', 'source', 'description'],
      sortedKey: 'time',
      sortedDirection: 'asc',
    };
  },

  computed: {
    ...mapGetters(['complianceHistory']),
  },

  mounted() {
    this.getHistory();
  },

  methods: {
    ...mapActions(['getComplianceHistory']),
    sortedKeyChanged({ sortedKey: key }) {
      if (this.sortedKey === key) {
        this.sortedDirection = this.sortedDirection === 'asc' ? 'desc' : 'asc';
      }
      this.getHistory();
    },
    getHistory() {
      let key = this.sortedKey;
      let direction = this.sortedDirection;
      this.getComplianceHistory({
        userId: this.$route.params.userId,
        sortKey: key,
        sortDirection: direction,
      });
    },
  },
};
</script>

<style src="./fraudAndCompliance.scss" lang="scss" scoped></style>
