<template>
  <div
    v-infinite-scroll="getMoreStatements"
    infinite-scroll-disabled="$loading"
    infinite-scroll-distance="20"
    infinite-scroll-throttle-delay="600"
    class="results__wrapper"
  >
    <modals-container />
    <p v-if="statements.count && !$loading" class="count-p">Statements count: {{ statements.count }}</p>

    <div v-if="$loading" class="statements__results">
      <Spinner class="statements__spinner" />
    </div>
    <div v-else class="statements__results">
      <span v-if="!statements.items.length" class="statements__noresults">No statements</span>
      <div v-else>
        <div class="statements__label">
          <span
            v-for="key in filters"
            :id="key"
            :key="key"
            class="statements__default"
            :class="[
              key.toLowerCase(),
              ]"
          >{{ key }}</span>
        </div>
        <div v-for="(stm, index) in statements.items" :key="index"
             class="statements__item"
        >
          <span class="product">
              <p class="opacity">
                From {{ stm.startPeriod | formatTime }} to
                {{ stm.endPeriod | formatTime }}
              </p>
              {{ growProducts[stm.product] || stm.product}}
              <p class="opacity">{{ stm.id }}</p>
          </span>
          <span>{{ stm.status }}
              <p class="opacity">Due: {{ stm.dueDate | formatTime }}</p>
              <p v-if="!!stm.blockDate" class="opacity">
                Block: {{ stm.blockDate | formatTime }}
              </p>
          </span>
          <span>Repaid {{ stm.repaidAmount | formatMoneyNotDivide }} of
              {{ stm.amount | formatMoneyNotDivide }}
              <p class="opacity">Fee: {{ stm.lateFee | formatMoneyNotDivide }}</p>
              <p class="opacity">Extra Fee: {{ stm.extraLateFee | formatMoneyNotDivide }}</p>
          </span>
          <span class="currency">{{stm.currency}}</span>
          <span>
            <p>Count: {{stm.countRepayments}}</p>
            <p class="opacity">Amount: {{stm.amountRepayments | formatMoneyNotDivide}}</p>
          </span>
          <span>
            <span class="user_link" @click="showUserProfile(stm.userId)">
              {{stm.firstName }} {{stm.lastName}}
            </span>
          </span>
          <span class="action">
            <Button
              v-if="hasGrantGrowStatements"
              title="Edit"
              color="primary"
              size="tight"
              @click="editInfo(stm)"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
import Spinner from '@/components/Spinner/Spinner';
import ModalEditGrowStatement from '@/components/ModalEditGrowStatement/ModalEditGrowStatement';
import Button from '@/components/Button/Button';
import {GRANTS} from '@/services/const';
const { mapGetters, mapActions } = createNamespacedHelpers('grow');
const grow = createNamespacedHelpers('grow');

export default {
  name: 'SearchResultsStatements',
  components: {
    Spinner,Button
  },

  data() {
    return {
      filters: ['Product', 'Status', 'Repaid', 'Currency', 'Repayments', 'User'],
    };
  },

  computed: {
    ...mapGetters(['statements']),
    ...grow.mapGetters(['growProducts'])
  },

  methods: {
    ...mapActions(['getMoreStatements','editStatementInfo']),
    showUserProfile(userId) {
      let routeData = this.$router.resolve({ name: 'users-profile', params: { userId } });
      window.open(routeData.href, '_blank');
    },

    editInfo(statement) {
      this.$modal.show(
        ModalEditGrowStatement,
        {
          statement: statement,
          click: this.handleEditStatement,
        },
        {
          name: 'editGrowStatementModal',
          width: '640',
          height: 'auto',
        },
      );
    },

    async handleEditStatement(info) {
      document.getElementById('modal_edit__btn').disabled = true;
      try {
        await this.editStatementInfo({
          id: info.id,
          info: info,
        });
        this.$modal.hide('editGrowStatementModal');
      } catch (error) {
        // console.error(error)
      }
      document.getElementById('modal_edit__btn').disabled = false;
    },

    hasGrantGrowStatements(){
      return this.$hasGrant(GRANTS.CAN_WORK_WITH_GROW_STATEMENTS)
    },
  }
}
</script>
<style src="./searchResultsStatements.scss" lang="scss" scoped></style>
