<template>
  <div id="nav" class="sidenav">
    <img src="~@/assets/img/logo.svg" class="logo" />

    <div class="sidenav__wrapper">
      <SidebarLink link="users" />
      
      <SidebarLink link="paysenders" />
      <SidebarLink link="payments" />
      <SidebarLink link="topups" />
      <SidebarLink link="balances" />
      <SidebarLink v-if="hasGrantGrowWhitelists()" link="grow"/>
    </div>

    <div class="sidenav__user" @click="$store.dispatch('logout')">
      <p>Logout</p>
      <!-- <img
        src="~@/assets/img/shevronDown.svg"
        class="user__down"
      > -->
    </div>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink/SidebarLink';
import {GRANTS} from '@/services/const';

export default {
  name: 'Sidebar',
  components: { SidebarLink },

  methods: {
    hasGrantGrowWhitelists(){
      return this.$hasGrant(GRANTS.CAN_WORK_WITH_GROW_WHITELISTS)
    },
  }
};
</script>

<style src="./sidebar.scss" lang="scss" scoped></style>
